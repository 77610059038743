<div class="d-flex flex-row flex-wrap align-items-center col-auto m-1">
  <div class="col-auto">
    <label class="col-form-label">{{ label }}:</label>
  </div>

  <div class="col-auto mx-1">
    <input
      type="date"
      [(ngModel)]="values.from"
      [max]="values.to"
      (change)="onChange()"
      class="form-control"
    />
  </div>

  <div class="col-auto mx-1">
    <input
      type="date"
      [(ngModel)]="values.to"
      [min]="values.from"
      (change)="onChange()"
      class="form-control"
    />
  </div>
</div>
