<app-import-nav />
<h5 class="mt-3">Import Claims</h5>

<div class="alert alert-danger" *ngIf="hasError">
  There was a problem uploading the file.
  <ul class="mt-2">
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
</div>

<app-icc-import-upload
  [collectThirdPartyAdminister]="true"
  (uploadComplete)="onUploadComplete($event)"
  (uploadStart)="onUploadStart()"
/>

<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 ps-2">
    <li class="py-2">
      Possible fields
      <ul class="ps-2">
        <li><code>claim_id</code> <span>(required)</span></li>
        <li><code>claim_status</code></li>
        <li><code>pharmacy_id</code> <span>(required)</span></li>
        <li><code>pharmacy_name</code></li>
        <li><code>fill_number</code></li>
        <li><code>rx_number</code></li>
        <li><code>written_date</code> <span>(required, date, format MM/DD/YYYY)</span></li>
        <li><code>service_date</code> <span>(required, date, format MM/DD/YYYY)</span></li>
        <li><code>patient_mrn</code></li>
        <li><code>patient_first</code> <span>(required)</span></li>
        <li><code>patient_last</code> <span>(required)</span></li>
        <li><code>patient_dob</code> <span>(required, date, format MM/DD/YYYY)</span></li>
        <li><code>originating_facility</code></li>
        <li><code>prescriber_npi</code> <span>(required, 10 digit)</span></li>
        <li><code>prescriber_first</code></li>
        <li><code>prescriber_last</code></li>
        <li><code>ndc</code> <span>(required, 11 digit)</span></li>
        <li><code>drug_description</code></li>
        <li><code>days_supply</code> <span>(integer)</span></li>
        <li><code>quantity</code> <span>(required, decimal)</span></li>
        <li><code>package_size</code></li>
        <li><code>gpo_price</code> <span>(currency)</span></li>
        <li><code>wac_price</code> <span>(currency)</span></li>
        <li><code>wac_cost</code> <span>(currency)</span></li>
        <li><code>340b_price</code> <span>(currency)</span></li>
        <li><code>340b_cost</code> <span>(currency)</span></li>
        <li><code>third_party_payment</code> <span>(currency)</span></li>
        <li><code>copay</code> <span>(currency)</span></li>
        <li><code>dispense_fee</code> <span>(currency)</span></li>
        <li><code>primary_payer</code></li>
        <li><code>bin</code> <span>(6 digit)</span></li>
        <li><code>pcn</code></li>
        <li><code>group_code</code></li>
        <li><code>secondary_bin</code> <span>(6 digit)</span></li>
        <li><code>secondary_pcn</code></li>
        <li><code>secondary_group_code</code></li>
        <li><code>medicaid</code> <span>(boolean, Y or N)</span></li>
      </ul>
    </li>
    <li class="py-2">
      Example file
      <div>
        <small><strong>NOTE:</strong> Extra columns will be ignored and column headers are case insensitive.</small>
      </div>
      <div class="bg-light p-2 border w-100">
        <small>
          <strong>
            <code class="csv-header">
              claim_id,claim_status,pharmacy_id,pharmacy_name,fill_number,rx_number,written_date,service_date,patient_mrn,patient_first,patient_last,patient_dob,originating_facility,prescriber_npi,prescriber_first,prescriber_last,ndc,drug_description,days_supply,quantity,package_size,gpo_price,wac_price,wac_cost,340b_price,340b_cost,third_party_payment,copay,dispense_fee,primary_payer,bin,pcn,group_code,secondary_bin,secondary_pcn,secondary_group_code,medicaid
            </code>
          </strong>
          <br />
          <code>
            35123,unpaid,9312,South Pharmacy,2,r543,03/10/2022,03/15/2022,4523415123,John,Smith,11/15/1980,Main Office,1234567890,Jane,Schmidt,51645-703-01,Acetaminophen,100,200.2,2,10.0,11.0,1.11,12.0,2.22,13.1,14,.5,Self,00A302,533A3,2345,A55123,33DD13,AA51234,N
          </code>
        </small>
      </div>
    </li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
