import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { InboundFax } from 'app/core/models/inbound-fax.model';
import { AppToastService } from 'app/core/services/app-toast.service';
import { LlmFeedbackService } from 'app/core/services/llm-feedback.service';

@Component({
  selector: 'app-inbound-fax-possible-patients',
  templateUrl: './inbound-fax-possible-patients.component.html',
  styleUrls: ['./inbound-fax-possible-patients.component.scss']
})
export class InboundFaxPossiblePatientsComponent implements OnChanges, OnInit {

  @Input() inboundFax: InboundFax;

  formCollapsed = true;
  showPatients = false;
  feedback = "";

  constructor(
    private llmFeedbackService: LlmFeedbackService,
    private toastService: AppToastService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  onSaveClick(): void {
    this.llmFeedbackService.submitCommentFeedback(this.inboundFax.latestLlmResultId, this.feedback).subscribe({
      complete: () => {
        this.toastService.show('Feedback submitted. Thanks!', { cssClass: 'bg-success-subtle' });
        this.resetForm();
      },
      error: () => this.toastService.show('An error occurred.', { cssClass: 'bg-danger-subtle' })
    });
  }

  private init(): void {
    if (this.inboundFax && this.inboundFax.latestLlmResultId !== null) {
      this.showPatients = true;
    }
    this.resetForm();
  }

  private resetForm(): void {
    this.formCollapsed = true;
    this.feedback = "";
  }

}
