<div class="section mt-2">
  <div class="card">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-10">
          <h4>Notes</h4>
        </div>
      </div>

      <div class="section mt-2">
        <app-notes-for-model [model]="office" [modelType]="'Office'" />
      </div>
    </div>
  </div>
</div>

<div class="section mt-2">
  <div class="card">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-10">
          <h4>Providers</h4>
        </div>

        <div class="col-2 actions">
          <button
            class="btn btn-primary"
            [disabled]="showAddProviders"
            (click)="onAddNewProvidersClick()">
            Add Providers
          </button>
        </div>
      </div>

      <app-office-provider-new-form
        *ngIf="showAddProviders"
        [office]="office"
        [providerOffices]="providerOffices"
        (providerAdded)="onProviderFormChanged()"
        (completed)="onProviderFormCompleted()" />

      <app-office-provider-list
        [providerOffices]="providerOffices"
        [loading]="providerOfficesLoading" />
    </div>
  </div>
</div>
