<div class="content">
  <div class="row mx-0">
    <div class="col px-0 text-start">
      <h3>ICC Captures</h3>
    </div>
  </div>

  <div class="mt-2" *ngIf="!loadingResults; else: spinner">
    <ng-container *ngIf="captures && captures.length > 0; else: noIccCaptures">
      <div>
        <table class="table table-bordered table-hover">
          <thead class="table-dark">
            <tr>
              <th>CLAIM ID</th>
              <th>STATUS</th>
              <th>CLIENT</th>
              <th>TPA</th>
              <th>RX-PATIENT</th>
              <th>RX-PRESCRIBER</th>
              <th>RX-DRUG</th>
              <th>ELIGIBILITY CHECKS</th>
              <th>ELIGIBILITY FINDINGS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let capture of captures">
              <td class="id-column">
                <a routerLink="{{capture.id}}">
                  <span class="text-nowrap">{{ capture.claim.claimIdentifier }}</span>
                </a>
              </td>
              <td>
                {{ capture.status }}
              </td>
              <td>
                {{ capture.client.name }}
              </td>
              <td>
                {{ capture.claim.thirdPartyAdminister?.name }}
              </td>
              <td>
                <div>{{ capture.claim.patientFirst }} {{ capture.claim.patientLast }}</div>
                <div class="small text-nowrap"><strong>MRN:</strong> {{ capture.claim.patientMrn }}</div>
                <div class="small text-nowrap"><strong>DOB:</strong> {{ capture.claim.patientDob | date: 'MM/dd/yyyy' }}</div>
              </td>
              <td>
                <div>{{ capture.claim.prescriberFirst }} {{ capture.claim.prescriberLast }}</div>
                <div class="small">
                  <strong>NPI:</strong>
                  {{ capture.claim.prescriberNpi }}
                </div>
              </td>
              <td>
                <div>{{ capture.claim.drugDescription }}</div>
                <div class="small">
                  <strong>NDC:</strong>
                  {{ capture.claim.ndc }}
                </div>
              </td>
              <td>
                <div *ngIf="capture.eligibilityStatus === 'eligible'">Pass</div>
                <div *ngIf="capture.eligibilityStatus === 'ineligible'">Fail</div>
              </td>
              <td>
                <div>{{ capture.eligibilityFindings?.eligibilityStatus }}</div>
                <div>{{ capture.eligibilityFindings?.eligibilityStatusReason }}</div>
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="hasMoreResults" class="text-center mb-4">
          <a href="#next" (click)="onNextClick($event)" class="load-next-consult-notes">
            View Next {{ limit }} ICC Captures
          </a>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #spinner>
    <div class="mt-2 ms-1"><mat-spinner diameter="30" /></div>
  </ng-template>
  <ng-template #noIccCaptures> <p class="mt-2 ms-1">No ICC captures found. Try modifying the filters.</p></ng-template>
</div>
