<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">End User Access Agreement</h4>
</div>
<div class="modal-body">
  <p>Please read the end user access agreement carefully and accept to access R1 340B Recovery:</p>
  <p><a href="https://www.r1rcm.com/terms-of-use/" target="_blank">Click here</a> to read the end user access agreement</p>
</div>
<div class="modal-footer">
  <div class="text-start">
    <p>Click on the button below to accept the terms and the conditions</p>
    <button type="button" class="btn btn-primary" (click)="onAcceptClick()">Accept</button>
  </div>
</div>
