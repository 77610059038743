<ng-container>
  <div class="row">
    <div class="col">
      <nav class="nav nav-pills mt-1">
        <a
          class="nav-item nav-link"
          href="#"
          (click)="onNavClick($event, 'validateConsultNote')"
          [class.active]="currentSection === 'validateConsultNote'"
        >
          <div class="d-flex nav-link-content">
            <mat-icon class="material-icons-round">task</mat-icon> <span>Validate Consult Note</span>
          </div>
        </a>
        <a
          class="nav-item nav-link"
          href="#"
          (click)="onNavClick($event, 'reviewPatientEncounters')"
          [class.active]="currentSection === 'reviewPatientEncounters'"
        >
          <div class="d-flex nav-link-content">
            <mat-icon>event</mat-icon> <span class="ps-1">Review Patient Encounters</span>
          </div>
        </a>
        <a
          class="nav-item nav-link"
          href="#"
          (click)="onNavClick($event, 'reviewProviderRMVs')"
          [class.active]="currentSection === 'reviewProviderRMVs'"
        >
          <div class="d-flex nav-link-content">
            <mat-icon>groups</mat-icon> <span class="ps-1">Review Provider RMVs</span>
          </div>
        </a>
        <a
          class="nav-item nav-link"
          href="#"
          (click)="onNavClick($event, 'submitConsultNote')"
          [class.active]="currentSection === 'submitConsultNote'"
        >
          <div class="d-flex nav-link-content">
            <mat-icon>upload_file</mat-icon> <span class="ps-1">Submit Consult Note</span>
          </div>
        </a>
      </nav>
    </div>
  </div>
</ng-container>
