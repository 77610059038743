<div *ngIf="nameMappingSummary !== undefined; else: spinner">
  <div class="row">
    <div class="col-6">
      <table class="table table-bordered">
        <thead class="table-dark">
          <tr>
            <th>ALL NPMA COUNT</th>
            <th>HV NPMA COUNT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ nameMappingSummary.allCount }}</td>
            <td>{{ nameMappingSummary.highValueCount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-6">
      Tips
      <br/>
      <span class="material-icons-round">lightbulb</span>
      Say the names out loud. Do they sound the same even if the spelling is slightly different?
      <br/>
      <span class="material-icons-round">lightbulb</span>
      Could this be a typo? Is a letter position switched or missing?
      <br/>
      <span class="material-icons-round">lightbulb</span>
      Could this name be a nickname for the other name? e.g. Robert and Bob
    </div>
  </div>
</div>
<h3>Patient Match Approvals</h3>
<div *ngIf="!loading; else: spinner">
  <div class="container-fluid w-100 p-2 border rounded bg-light">
    <form>
      <div class="row">
        <div class="col-3">
          <label>Date Range:</label>
          <div class="input-group">
            <input
              type="date"
              id="createdDateFrom"
              name="createdDateFrom"
              [(ngModel)]="filters.createdDateFrom"
              [max]="filters.createdDateTo"
              autocomplete="off"
              class="form-control"
            />
            <input
              type="date"
              id="createdDateTo"
              name="createdDateTo"
              [(ngModel)]="filters.createdDateTo"
              [min]="filters.createdDateFrom"
              autocomplete="off"
              class="ms-1 form-control"
            />
          </div>
        </div>
        <div class="col-lg-2 col-xl-1">
          <div class="input-group high-value">
            <label>High Value:</label>
            <mat-slide-toggle
              name="highValue"
              [color]="'primary'"
              [(ngModel)]="filters.hasHighValue"
              [disableRipple]="true"
            />
          </div>
        </div>
        <div class="col-lg-2 col-xl-1">
          <div class="input-group npma">
            <label>NPMA:</label>
            <mat-slide-toggle
              name="npma"
              [color]="'primary'"
              [(ngModel)]="filters.hasNpmaCaptures"
              [disableRipple]="true"
            />
          </div>
        </div>
        <div class="col-1">
          <label>Match Type:</label>
          <div class="input-group">
            <ng-select
              name="matchType"
              bindValue="value"
              bindLabel="display"
              [multiple]="false"
              [clearable]="false"
              [items]="MatchOptions"
              [(ngModel)]="filters.matchType"
              class="w-100"
              />
          </div>
        </div>
        <div class="col-2">
          <label>Sort By:</label>
          <div class="input-group">
            <ng-select
              name="cursorType"
              bindValue="value"
              bindLabel="display"
              [multiple]="false"
              [items]="CursorOptions"
              [clearable]="false"
              [searchable]="false"
              [(ngModel)]="cursorType"
              class="w-100"
              />
          </div>
        </div>
        <div class="text-end col-lg-3 col-xl-4 search-buttons">
          <button type="search" (click)="onSearchClick()" class="btn btn-primary">Search</button>
          <button type="reset" class="btn btn-info" (click)="onResetClick($event)">Reset</button>
        </div>
      </div>
    </form>
  </div>

  <section *ngIf="nameMappings.length > 0; else: noRequests">
    <app-pagination-header
      [label]="'patient match approvals'"
      [paging]="paging"
      (pageSizeChange)="onPageSizeChange($event)"
      [allowLargeSize]="true"
    />
    <form [formGroup]="Approvals" (ngSubmit)="onSubmit()">
      <table class="table table-bordered table-hover">
        <thead class="table-dark">
          <tr>
            <th>CREATED</th>
            <th>CE NAME</th>
            <th>RX NAME</th>
            <th>ACTION</th>
            <th>TYPE</th>
            <th>HIGH VALUE</th>
            <th>ESTIMATED VALUE</th>
            <th>CONFIDENCE RATIO</th>
            <th>CAPTURE</th>
          </tr>
        </thead>
        <tbody formArrayName="Rows">
          <tr
            *ngFor="let obj of formArr.controls; let i = index; let l = last"
            [formGroupName]="i"
          >
            <td>{{ obj.value.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
            <td>{{ obj.value.name }}</td>
            <td>{{ obj.value.alternate }}</td>
            <td>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="approvedYes{{obj.value.id}}"
                  formControlName="approve"
                  [value]="true"
                />
                <label class="form-check-label" for="approvedYes{{obj.value.id}}">Approve</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="approvedNo{{obj.value.id}}"
                  formControlName="approve"
                  [value]="false"
                />
                <label class="form-check-label" for="approvedNo{{obj.value.id}}">Reject</label>
              </div>
            </td>
            <td>{{ obj.value.nameType }}</td>
            <td>
              <div *ngIf="obj.value.highValue">
                <span class="high-value-indicator">High Value</span>
              </div>
            </td>
            <td>{{ obj.value.estimatedValue | currency: 'USD':'symbol':'1.0-0' }}</td>
            <td>{{ obj.value.levenshteinRatio }}</td>
            <td>
              <a routerLink="{{ baseCaptureUrl + obj.value.captureId}}" target="_blank">View Capture</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <button type="submit" class="btn btn-primary">Submit</button>
        <span class="my-2 alert alert-warning">
          <fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning" />
          &nbsp;&nbsp;Clicking submit will process all matches on this page.
        </span>
      </div>
    </form>
    <br />
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noRequests>
  <section><p>There are no patient match approvals.</p></section>
</ng-template>
<ng-template #spinner> <mat-spinner /> </ng-template>
