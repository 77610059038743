<div class="d-flex flex-row flex-wrap align-items-center col-auto m-1">
  <div class="col-auto">
    <label class="col-form-label mx-1">{{ label }} From:</label>
  </div>

  <div class="col-auto">
    <app-month-picker
      [year]="values.fromYear"
      (yearChange)="onValueChange('fromYear', $event)"
      [month]="values.fromMonth"
      (monthChange)="onValueChange('fromMonth', $event)"
      [minYear]="minYear"
      [minMonth]="minMonth"
      [maxYear]="values.toYear || maxYear"
      [maxMonth]="values.toMonth || maxMonth" />
  </div>

  <div class="col-auto">
    <label class="col-form-label mx-1">To:</label>
  </div>

  <div class="col-auto">
    <app-month-picker
      [year]="values.toYear"
      (yearChange)="onValueChange('toYear', $event)"
      [month]="values.toMonth"
      (monthChange)="onValueChange('toMonth', $event)"
      [minYear]="values.fromYear || minYear"
      [minMonth]="values.fromMonth || minMonth"
      [maxYear]="maxYear"
      [maxMonth]="maxMonth" />
  </div>
</div>
