import { Component, Input, OnChanges } from '@angular/core';
import { remove } from 'lodash-es';
import { Capture } from 'app/core/models/capture.model';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { Prescription } from 'app/core/models/prescription.model';
import {
  isClientDateReferralMatchVerification,
  isVerificationFrozen,
  showCaptureTransitionedToast
} from 'app/core/lib/verification-utils';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { PatientPrescriberService } from 'app/core/services/patient-prescriber.service';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { CaptureValidationService, ValidationKeys } from 'app/core/services/capture-validation.service';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import { ReferralMatchVerificationEvent, ReferralMatchVerificationEventType } from 'app/core/models/referral-match-verification-event.model';
import { CaptureService } from 'app/core/services/capture.service';
import { AppToastService } from 'app/core/services/app-toast.service';

@Component({
  selector: 'app-referral-match-form',
  templateUrl: './referral-match-form.component.html',
  styleUrls: ['./referral-match-form.component.scss'],
})
export class ReferralMatchFormComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() patientPrescriptions: Prescription[];

  notesFrozen = false;
  needsEvidence = false;
  validationKeys = [
    ValidationKeys.referralMatchVerification,
    ValidationKeys.clientReferralDateRequired,
    ValidationKeys.referralDateBeforeRxWrittenDate,
  ];
  verificationChangeInProgress = false;
  showClientReferralDatesSection = false;

  constructor(
    private authService: AuthenticationService,
    private patientPrescriberService: PatientPrescriberService,
    private captureValidationService: CaptureValidationService,
    private captureService: CaptureService,
    private toastService: AppToastService,
  ) {}

  ngOnChanges() {
    this.notesFrozen = this.areNotesFrozen();
    this.needsEvidence = this.capture.status === CaptureStatus.needsEvidence;
    this.showClientReferralDatesSection = this.shouldShowClientReferralDatesSection();
  }

  onNotesChange(notes: string) {
    this.captureValidationService.clearReferralMatchErrors();
    this.patientPrescriberService
      .update(this.patientPrescriber.id, notes)
      .subscribe((patientPrescriber: PatientPrescriber) => {
        const modifiedPatientPrescriber: PatientPrescriber = Object.assign({}, this.patientPrescriber);
        modifiedPatientPrescriber.referralMatchVerificationNotes = patientPrescriber.referralMatchVerificationNotes;
        this.patientPrescriberService.notifyPatientPrescriberChanged(modifiedPatientPrescriber);
      });
  }

  onVerificationChange(event: ReferralMatchVerificationEvent) {
    switch (event.type) {
      case ReferralMatchVerificationEventType.changeInitiated:
        this.verificationChangeInProgress = true;
        break;
      case ReferralMatchVerificationEventType.changed:
        this.captureValidationService.clearReferralMatchErrors();
        this.updatePatientPrescriberReferralVerification(event.verification, event.tempId);
        this.verificationChangeInProgress = false;
        this.advanceCapturesRequiringRmv();
        break;
      case ReferralMatchVerificationEventType.removed:
        this.captureValidationService.clearReferralMatchErrors();
        this.removePatientPrescriberReferralVerification(event.verification);
        this.verificationChangeInProgress = false;
        break;
    }
  }

  private areNotesFrozen(): boolean {
    return isVerificationFrozen(this.authService, this.capture, null);
  }

  private updatePatientPrescriberReferralVerification(rmv: ReferralMatchVerification, tempId?: string) {
    const modifiedPatientPrescriber: PatientPrescriber = Object.assign({}, this.patientPrescriber);

    const index = modifiedPatientPrescriber.referralMatchVerifications.findIndex(
      ppRmv => ppRmv.id === rmv.id || ppRmv.id === tempId
    );

    if (index > -1) {
      modifiedPatientPrescriber.referralMatchVerifications[index] = rmv;
    } else {
      modifiedPatientPrescriber.referralMatchVerifications.push(rmv);
    }

    this.patientPrescriberService.notifyPatientPrescriberChanged(modifiedPatientPrescriber);
  }

  private removePatientPrescriberReferralVerification(rmv: ReferralMatchVerification) {
    const modifiedPatientPrescriber: PatientPrescriber = Object.assign({}, this.patientPrescriber);
    remove(modifiedPatientPrescriber.referralMatchVerifications, ppRmv => ppRmv.id === rmv.id);
    this.patientPrescriberService.notifyPatientPrescriberChanged(modifiedPatientPrescriber);
  }

  private shouldShowClientReferralDatesSection() {
    return this.patientPrescriber.referralMatchVerifications.some(isClientDateReferralMatchVerification);
  }

  private shouldAttemptAdvancingCaptures() {
    return this.capture.status !== CaptureStatus.needsReferralMatchApproval &&
      this.captureValidationService.validateNeedsReferralMatchApproval(this.capture, this.patientPrescriber);
  }

  private advanceCapturesRequiringRmv() {
    if (this.shouldAttemptAdvancingCaptures()) {
      this.patientPrescriberService.
        advanceCapturesRequiringReferral(this.patientPrescriber.id).
        subscribe(result => {
          if (result.transitionedCaptureIds.includes(this.capture.id)) {
            this.reloadCaptureAfterTransition();
          }
        })
    }
  }

  private reloadCaptureAfterTransition() {
    this.captureService.getCapture(this.capture.id).subscribe(capture => {
      showCaptureTransitionedToast(this.toastService, capture.status);
      this.captureService.notifyCaptureChanged(capture);
    });
  }
}
