<form>
  <div
    class="form-check d-flex justify-content-center align-items-center w-100 border rounded ms-1"
    [ngClass]="{ associated: isAssociated, error: errorMessage }"
  >
    <input
      id="patientAttachmentVerified"
      name="patientAttachmentVerified"
      class="form-check-input ms-1 mt-0 me-2"
      type="checkbox"
      [disabled]="disabled"
      (change)="onPatientAttachmentAssociatedChanged($event)"
      [(ngModel)]="isAssociated"
    />
    <label class="form-check-label" for="patientAttachmentVerified">
      Associate this consult note with this patient and prescriber.
    </label>
    <span *ngIf="errorMessage" class="error-text ps-2"> ({{ errorMessage }})</span>
  </div>
</form>
