import { ReferralMatchVerification } from './referral-match-verification.model';
import { ClientReferralVerification } from './client-referral-verification.model';
import { AbstractReferralMatchOption } from './abstract-referral-match-option.model';

export class ClientReferralMatchOption extends AbstractReferralMatchOption {
  constructor(
    public verification: ReferralMatchVerification,
    public clientReferralVerification: ClientReferralVerification,
    public selected: boolean,
    public disabled: boolean
  ) {
    super(verification, selected, disabled);
  }
}

