<form>
  <div
    class="form-check d-flex justify-content-center align-items-center w-100 border rounded ms-1"
    [ngClass]="{ 'marked-as-invalid': selectedAttachment.markedAsInvalid, error: errorMessage }">
    <input
      id="patientAttachmentMarkedAsInvalid"
      name="patientAttachmentMarkedAsInvalid"
      class="form-check-input ms-1 mt-0 me-2"
      type="checkbox"
      [disabled]="!canMarkAsInvalid"
      (change)="onMarkedAsInvalidChanged($event)"
      [(ngModel)]="selectedAttachment.markedAsInvalid" />

    <label class="form-check-label" for="patientAttachmentMarkedAsInvalid">
      Mark consult note as invalid.
    </label>

    <span *ngIf="errorMessage" class="error-text ps-2"> ({{ errorMessage }})</span>
  </div>
</form>
