<strong>OFFICE(S)</strong>
<div *ngIf="!officesRefreshing">
  <div class="row" *ngIf="providerOffices">
    <ul class="list-unstyled scrollable">
      <li
        *ngFor="let providerOffice of providerOffices"
        class="office p-2">
        <app-office
            [office]="providerOffice.office"
            [providerOffice]="providerOffice" />
      </li>
    </ul>
  </div>
  <div *ngIf="providerOffices.length === 0" class="fst-italic">
    No prescriber offices in supplemental data
  </div>
</div>
