<app-import-nav />

<h5 class="mt-3">Import Claim Eligibility Findings</h5>

<div class="alert alert-danger" *ngIf="hasError">
  There was a problem uploading the file.
  <ul class="mt-2">
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
</div>

<app-icc-import-upload
  (uploadComplete)="onUploadComplete($event)"
  (uploadStart)="onUploadStart()"
  [collectClient]="false" />

<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 ps-2">
    <li class="py-2">
      Possible fields
      <ul class="ps-2">
        <li><code>par8o_capture_id</code></li>
        <li><code>findings_eligibility</code> (Eligible/Ineligible)</li>
        <li>
          <div><code>findings_eligibility_status_reason</code> <span>(required)</span></div>
          <div><span>Possible values:</span></div>
          <ul>
            <li><code>Eligible department issue</code></li>
            <li><code>Eligible provider issue</code></li>
            <li><code>Orphan drug issue</code></li>
            <li><code>Ineligible drug issue</code></li>
            <li><code>Discharge Med</code></li>
            <li><code>Meets Criteria</code></li>
            <li><code>Orphan Exception</code></li>
            <li><code>Missing Price</code></li>
            <li><code>Ineligible Department</code></li>
            <li><code>Medicaid</code></li>
            <li><code>Ineligible Physician</code></li>
            <li><code>Excluding NDC</code></li>
            <li><code>Does Not Meet Criteria</code></li>
          </ul>
        </li>
        <li><code>findings_note</code></li>
        <li><code>findings_department</code></li>
        <li><code>findings_encounter_id</code></li>
        <li><code>findings_mrn</code></li>
        <li><code>findings_submit_date</code> <span>(date, format MM/DD/YYYY)</span></li>
      </ul>
    </li>
    <li class="py-2">
      Example file
      <div>
        <small><strong>NOTE:</strong> Extra columns will be ignored and column headers are case insensitive.</small>
      </div>
      <div class="bg-light p-2 border w-100">
        <small>
          <strong>
            <code class="csv-header">
              par8o_capture_id,findings_eligibility,findings_eligibility_status_reason,findings_note,findings_department,findings_encounter_id,findings_mrn,findings_submit_date
            </code>
          </strong>
          <br />
          <code>304942,Ineligible,Eligible provider issue,Provider at wrong office,Pediatrics,JGJ393,93939402,12/30/2022</code>
        </small>
      </div>
    </li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
