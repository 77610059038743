import { prescriberSpecialtiesToReferralSpecialtiesMap } from 'app/core/options/referral-specialties.opts';
import { Prescriber } from 'app/core/models/prescriber.model';

export const determineRecommendedSpeciality = (prescriber: Prescriber) => {
  const allReferralSpecialties =
    prescriber.
      specialties.
      map(specialty => prescriberSpecialtiesToReferralSpecialtiesMap[specialty]).
      filter(s => s);

  const referralSpecialties = [...new Set(allReferralSpecialties)];

  if (referralSpecialties.length === 1) {
    return referralSpecialties[0];
  }
}
