<div *ngIf="resultSet && summary" class="card m-1">
  <div class="card-title m-0 pt-1 px-2">
    <div class="row">
      <div class="col">
        <h6 class="pt-1">Consult Note Summary</h6>
      </div>
      <div class="col-sm-auto text-end">
        <button type="button" class="btn btn-link m-0 p-0" (click)="collapse.toggle()">
          <fa-icon [icon]="['fas', 'exclamation-circle']" size="sm" />
          Report an Issue
        </button>
      </div>
    </div>
  </div>
  <div class="card-body p-1 container-fluid">
    <div *ngIf="resultSet.partial" class="p-2"><i><b>Warning:</b> Not all queries completed successfully. Missing information may still exist in the document.</i></div>
    <div class="row pb-2">
      <div class="col px-5">
        <div>
          <b>Patient Information:</b>
          <i *ngIf="!summary.patientInformation?.length">Information not found</i>
        </div>
        <ul *ngIf="summary.patientInformation?.length">
          <li *ngFor="let patient of summary.patientInformation">
            <div>Name: {{ patient.patientName }}</div>
            <div>DOB: {{ patient.patientDob | date: 'MM/dd/yyyy' }}</div>
          </li>
        </ul>
        <div>
          <b>Specialty of Note:</b>
          <span *ngIf="summary.consultNoteSpecialty">{{ summary.consultNoteSpecialty }}</span>
          <i *ngIf="!summary.consultNoteSpecialty">Information not found</i>
        </div>
        <div>
          <b>Reason for Visit:</b>
          <span *ngIf="summary.reasonForVisit">{{ summary.reasonForVisit }}</span>
          <i *ngIf="!summary.reasonForVisit">Information not found</i>
        </div>
        <div>
          <b>Providers:</b>
          <i *ngIf="!summary.providerInformation?.length">Information not found</i>
        </div>
        <ul *ngIf="summary.providerInformation?.length">
          <li *ngFor="let provider of summary.providerInformation">
            <div>
              {{ provider.providerName }}
              <ng-container *ngIf="provider.providerSpecialty">({{ provider.providerSpecialty }})</ng-container>
              <ng-container *ngIf="provider.inOfficeEncounter"> - In-Office Visit</ng-container>
            </div>
          </li>
        </ul>
      </div>
      <div class="col px-5">
        <div>
          <b>Appointment Date(s):</b>
          <i *ngIf="!summary.encounterDetails?.length">Information not found</i>
        </div>
        <ul *ngIf="summary.encounterDetails?.length">
          <li *ngFor="let appt of summary.encounterDetails">
            <div>{{ appt.appointmentDate | date: 'MM/dd/yyyy' }} ({{ appt.appointmentType }})</div>
          </li>
        </ul>
        <div>
          <b>Drugs:</b>
          <i *ngIf="!summary.drugs?.length">Information not found</i>
        </div>
        <ul *ngIf="summary.drugs?.length">
          <li *ngFor="let drug of summary.drugs">
            <div>{{ drug }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="formCollapsed" class="card-body">
    <form>
      <label for="summary-feedback">Describe any problems you see with the data:</label>
      <textarea id="summary-feedback" class="form-control mb-2" name="feedback" [(ngModel)]="feedback"></textarea>
      <button type="submit" class="btn btn-primary" (click)="onSaveClick()">Save</button>
      <button type="button" class="btn btn-secondary" (click)="collapse.toggle()">Cancel</button>
    </form>
  </div>
</div>
