<div class="mt-2">
  <app-inbound-fax-select-patient
    #selectPatient
    [inboundFax]="inboundFax"
    (selectionChange)="onPatientSelectionChange($event)" />

  <app-select-prescriber
    [selectedPatient]="inboundFax.patient"
    [selectedPrescriber]="inboundFax.prescriber"
    (selectionChange)="onPrescriberSelectionChange($event)" />

  <app-outbound-faxes
    [selectedPatient]="inboundFax.patient"
    [selectedPrescriber]="inboundFax.prescriber"
    [selectedOutboundFax]="inboundFax.outboundFax"
    (selectionChange)="onOutboundFaxChange($event)" />

  <app-inbound-fax-select-outcome
    [inboundFax]="inboundFax"
    (outcomeChange)="onFaxOutcomeChange($event)" />

  <app-inbound-fax-communication-method
    [disabled]="junkNonMedicalDocumentSelected"
    [selectedCommunicationMethod]="selectedCommunicationMethod"
    (communicationMethodChange)="onCommunicationMethodChange($event)" />

  <app-inbound-fax-multiple-patients-warning
    *ngIf="showMultiplePatientsWarning"
    [inboundFax]="inboundFax"
    (multiplePatientsValidChange)="onMultiplePatientFeedbackChange($event)" />

  <div class="alert alert-danger text-danger mt-2" *ngIf="validationErrors.length > 0">
    <ul class="py-2">
      <li *ngFor="let error of validationErrors">{{ error }}</li>
    </ul>
  </div>
  <div class="alert alert-danger text-danger mt-2 p-2" *ngIf="serverError">
    A server error occurred while processing this fax. Please try again or contact support.
  </div>
  <div class="mt-2 actions row">
    <div class="col text-nowrap">
      <button type="button" class="btn btn-primary" (click)="onSaveClick()">Save</button>
      <button type="button" class="btn btn-secondary" (click)="onCancelClick()">Cancel</button>
    </div>
    <div class="col text-end">
      <button type="button" class="btn btn-secondary next-button" (click)="onNextClick()">
        Next
      </button>
    </div>
  </div>
</div>
