// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  maintenance: false,
  mfaRequiredForAllUsers: true,
  localApiServer: false,
  idleTimeoutSeconds: 1200,
  debugUserIdle: false,
  debugUserTiming: false,
  debugRouter: true,
  awsRegion: 'us-west-2',
  sentryDsn: 'https://bddc785e34e148b994674d561cbaa413@sentry.io/1449791',
  captureApi: {
    url: 'https://t40b-r-dev.par8o.com/api/v1',
  },
  helpscoutApi: {
    token: null,
  },
  pendo: {
    enabled: false,
    accountIdentifier: 'Dev Remote'
  },
  featureData: {
    consultNotePages: true,
    usePdfKitViewer: true,
  },
  psPdfKit: {
    licenseKey: ""
  },
};
