import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { Provider } from 'app/core/models/provider.model';
import { ProviderFilters, ProviderQueueSettings } from 'app/core/models/user-settings/provider-search-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { PagedProviders, ProviderService } from 'app/core/services/provider.service';
import { UserTimingService } from 'app/core/services/user-timing.service';
import { NewProviderModalComponent, NewProviderModalOptions } from 'app/capture-admin/components/new-provider-modal/new-provider-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { keyedStatusOptions } from 'app/core/options/provider-status.opts';
import { NavigationService } from 'app/core/services/navigation.service';
import { StateOptions } from 'app/core/options/states.opts';
import { searchableProviderStatusOptions } from 'app/core/options/provider-status.opts';
import { debounce } from 'lodash-es';

@Component({
  selector: 'app-capture-admin-providers',
  templateUrl: './capture-admin-providers.component.html',
  styleUrls: ['./capture-admin-providers.component.scss'],
})
export class CaptureAdminProvidersComponent implements OnInit, OnDestroy {
  filters: ProviderFilters = new ProviderFilters();
  providers: Provider[] = [];
  loading = false;
  paging = LimitOffsetPaging.empty;
  keyedStatusOptions = keyedStatusOptions;
  stateOptions = StateOptions;
  providerStatusOptions = searchableProviderStatusOptions;

  constructor(
    private injector: Injector,
    private userSettingsService: UserSettingsService,
    private providerService: ProviderService,
    private userTimingService: UserTimingService,
    private modalService: NgbModal,
    private navigationService: NavigationService,
  ) { }

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  debounceFilterChange = debounce(
    () => {
      this.onFilterChange();
    },
    1000,
    { maxWait: 2000 }
  );

  ngOnInit() {
    this.startUserTimingService();
    this.loadQueueSettings();
    this.loadProviders();
    this.openNewProviderModalForQueryString();
  }

  ngOnDestroy() {
    this.userTimingService.stop();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadProviders();
    this.saveQueueSettings();
  }

  onPageChange(page: number) {
    this.paging.currentPage = page;
    this.loadProviders();
    this.saveQueueSettings();
  }

  onResetFiltersClick() {
    this.paging.currentPage = 1;
    this.filters = new ProviderFilters();
    this.saveQueueSettings();
    this.loadProviders();
  }

  onAddProviderClick($event: MouseEvent) {
    $event.preventDefault();

    this.openNewProviderModal();
  }

  onFilterChange() {
    this.paging.currentPage = 1;
    this.saveQueueSettings();
    this.loadProviders();
  }

  onTextFilterChange() {
    this.debounceFilterChange();
  }

  private openNewProviderModalForQueryString() {
    const queryParams = this.navigationService.queryParams;

    if (queryParams.new) {
      const options = new NewProviderModalOptions();
      options.npi = queryParams.new;

      this.openNewProviderModal(options);
    }
  }

  private startUserTimingService() {
    this.userTimingService.track({
      page: 'capture_admin_providers',
    });
  }

  private loadQueueSettings() {
    const queueSettings: ProviderQueueSettings = this.userSettingsService.get<ProviderQueueSettings>(
      UserSettings.captureAdminProviderQueueSettings
    );
    if (queueSettings) {
      this.filters = queueSettings.filters;
      this.paging = queueSettings.paging;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new ProviderQueueSettings();
    queueSettings.position = position;
    queueSettings.filters = this.filters;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<ProviderQueueSettings>(UserSettings.captureAdminProviderQueueSettings, queueSettings);
  }

  private loadProviders() {
    const paging = { page: this.paging.currentPage, pageSize: this.paging.pageSize };

    this.loading = true;

    this.providerService.getList(paging, this.filters).subscribe((result: PagedProviders) => {
      this.providers = result.records || [];
      this.paging = result.meta.paging;
      this.loading = false;
    });
  }

  private openNewProviderModal(modalOptions?: NewProviderModalOptions) {
    const options: any = {
      windowClass: 'edit',
    };

    if (modalOptions) {
      options.injector = Injector.create({
        providers: [{ provide: NewProviderModalOptions, useValue: modalOptions }],
        parent: this.injector
      })
    }

    this.modalService.open(NewProviderModalComponent, options);
  }
}
