<ng-container *ngIf="patientReferralMatchVerifications.length > 0">
  <table class="table table-sm table-bordered mb-1">
    <thead class="table-dark">
      <tr>
        <th>
          Referral Date
        </th>
        <th>
          Referral Type
        </th>
        <th>
          Referral Notes
        </th>
        <th>
          Specialty
        </th>
        <th>
          Prescriber
        </th>
        <th>
          Additional Details
        </th>
        <th>
          Task ID
        </th>
        <th>
          Submitted By
        </th>
        <th>
          Submitted At
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let rmv of patientReferralMatchVerifications">
        <tr>
          <td>
            <div class="d-flex">
              <div class="selection me-1">
                <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
              </div>
              <div>
                {{ rmv.clientReferralVerification.referralDate | date: 'MM/dd/yyyy' }}
              </div>
            </div>
          </td>
          <td>
            {{ rmv.clientReferralVerification.clientReferralTaskSubmission.choiceReasons | referralVerificationReasonShortNames | joinList }}
          </td>
          <td>
            {{ rmv.clientReferralVerification.clientReferralTaskSubmission.choiceOtherReasonNote | blankValueDash }}
          </td>
          <td>
            {{ rmv.clientReferralVerification.referralSpecialty | blankValueDash }}
          </td>
          <td>
            <div>{{ rmv.clientReferralVerification.prescriber.fullName }}</div>
            <div>NPI: {{ rmv.clientReferralVerification.prescriber.npi }}</div>
            <div>{{ rmv.clientReferralVerification.prescriber.displaySpecialty }}</div>
          </td>
          <td>
            {{ rmv.clientReferralVerification.clientReferralTaskSubmission.notes | blankValueDash }}
          </td>
          <td>
            {{ rmv.clientReferralVerification.clientReferralTaskSubmission.taskId }}
          </td>
          <td>
            {{ rmv.clientReferralVerification.clientReferralTaskSubmission.submittedBy.fullName }}
          </td>
          <td>
            <div>{{ rmv.clientReferralVerification.clientReferralTaskSubmission.submittedAt | date: 'MM/dd/yyyy' }}</div>
            <div>{{ rmv.clientReferralVerification.clientReferralTaskSubmission.submittedAt | date: 'h:mm a' }}</div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
