<div class="add-offices">
  <span class="caption">Add offices to this provider:</span>

  <form #formDirective="ngForm" [formGroup]="formGroup" (submit)="onSubmit()">

    <ng-container formArrayName="providerOffices">
      <ng-container *ngFor="let providerOfficeForm of providerOfficeFormArray.controls; let i = index">
        <div class="row">

            <div class="col-11">
              <app-provider-office-new-row
                [formArrayIndex]="i"
                [rowForm]="providerOfficeFormByIndex(i)"
                [existingProviderOffices]="providerOffices" />
            </div>
            <div class="col-1">
              <a href="javascript:;"
                *ngIf="providerOfficeFormArray.length > 1"
                class="delete-new-provider-office"
                (click)="onDeleteNewProviderOfficeClick(i)">
                <fa-icon [icon]="['fas', 'trash']" size="1x" />
              </a>
            </div>

        </div>
      </ng-container>
    </ng-container>

    <div class="row">
      <div class="col-12">
        <a href="javascript:;" (click)="onAddNewProviderOfficeClick()">
          <fa-icon [icon]="['fas', 'plus-circle']" />
          Add Another Office
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-11 new-office-actions">
        <button
          class="btn btn-secondary"
          (click)="onCancelClick()">
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-primary">
          Save
        </button>
      </div>
      <div class="col-1">
      </div>
    </div>

  </form>
  <hr />
</div>
