import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Provider } from 'app/core/models/provider.model';
import { ProviderSpecialty } from 'app/core/models/provider-specialty.model';
import { ProviderService } from 'app/core/services/provider.service';
import { ProviderSpecialtyService } from 'app/core/services/provider-specialty.service';
import { mapObjectToFormGroup, applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';
import { filterProviderStatusOptions } from 'app/core/options/provider-status.opts';
import { providerCredentialOptions } from 'app/core/options/provider-credential.opts';
import { providerDoNotSendReasonOptions } from 'app/core/options/provider-do-not-send-reason.opts';

@Component({
  selector: 'app-provider-header-edit',
  templateUrl: './provider-header-edit.component.html',
  styleUrls: ['./provider-header-edit.component.scss'],
})

export class ProviderHeaderEditComponent implements OnInit {
  @Input() provider: Provider;
  @Output() cancel = new EventEmitter();
  @Output() updated = new EventEmitter<Provider>();

  formGroup: FormGroup;
  saving = false;

  providerCredentialOptions = providerCredentialOptions;
  providerDoNotSendReasonOptions = providerDoNotSendReasonOptions;
  providerSpecialties: ProviderSpecialty[] = [];
  providerStatusOptions;

  @ViewChild('form') public form: FormGroupDirective;

  constructor(
    private formBuilder: FormBuilder,
    private providerService: ProviderService,
    private providerSpecialtyService: ProviderSpecialtyService,
  ) { }

  ngOnInit() {
    this.providerSpecialtyService.getList().subscribe(({ providerSpecialties }) => {
      this.providerSpecialties = providerSpecialties;
    });

    this.formGroup = mapObjectToFormGroup(this.formBuilder, this.provider, [
      'first',
      'last',
      'status',
      'specialties',
      'credentials',
      'doNotSendReason',
      'additionalDetailsDns',
    ]);

    this.providerStatusOptions = filterProviderStatusOptions(this.provider.status);
  }

  onSubmit() {
    this.saving = true;

    this.providerService.update(this.provider, this.formGroup.value).subscribe(
      provider => this.handleSaveSuccess(provider),
      error => this.handleSaveFailure(error)
    );
  }

  onCancelClick() {
    this.cancel.emit();
  }

  private handleSaveSuccess(provider: Provider) {
    this.saving = false;
    this.provider = provider;
    this.updated.emit(provider);
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    this.saving = false;

    if (response.status === 400) {
      applyServerErrorsToFormGroup(response, this.formGroup);
    } else {
      console.error(response);
    }
  }
}
