<h3>Client Prescriber Tasks</h3>
<div class="mb-3">
  <app-client-tasks-filter
    [filters]="filters"
    (filtersChange)="filtersChange($event)"
    [loading]="loading"
    [exportUrl]="exportUrl"
    [enableExport]="tasks.length > 0"
    [taskStatuses]="taskStatuses"
    [specialties]="specialties"
    [manufacturers]="manufacturers"
    [sources]="sources"
    [clients]="clients"
    [allowTaskIdSearch]="true"
  />
  <div class="small mt-1 d-none">
    <strong>Configured Minimum</strong> <span>{{ clientTasksMinValue | currency }} </span> |
    <span
      ><strong>{{ clientTasksVisibleCount | number: '' }}</strong> tasks are currently
      <span class="alert alert-warning px-1 ">Visible</span> to clients.</span
    >
  </div>
</div>
<div *ngIf="!loading; else: spinner">
  <div *ngIf="tasks.length > 0; else: noTasks">
    <app-client-prescriber-task-list
      [tasks]="tasks"
      [paging]="paging"
      (pageChange)="onPageChange($event)"
      (pageSizeChange)="onPageSizeChange($event)"
    />
  </div>
</div>
<ng-template #noTasks>
  <section>
    <p>
      There are no tasks. Try modifying the filters or
      <strong
        ><u><a href="#" (click)="onClearAllFilters($event)">clearing them</a></u></strong
      >.
    </p>
  </section>
</ng-template>
<ng-template #spinner> <mat-spinner diameter="30" /> </ng-template>
