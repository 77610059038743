import { Input, Component, OnInit } from '@angular/core';
import { FeaturesService } from '../../../core/services/features.service';

@Component({
  selector: 'app-feature-flag',
  template: ' <ng-content *ngIf="featureEnabled" />',
})
export class FeatureFlagComponent implements OnInit {
  featureData = null;
  featureEnabled = false;

  constructor(private featuresService: FeaturesService) {}

  @Input() feature: string;

  ngOnInit() {
    this.featureData = this.featuresService.get();
    this.featureEnabled = this.featureData[this.feature] === true;
  }
}
