import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { ClientReferralVerification } from 'app/core/models/client-referral-verification.model';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { ClientReferralMatchOption } from 'app/core/models/client-referral-match-option.model';
import { ReferralMatchVerificationsService } from 'app/core/services/referral-match-verifications.service';
import { ClientReferralVerificationsService, PagedClientReferralVerifications } from 'app/core/services/client-referral-verifications.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import {
  isClientReferralMatchVerification,
  isConsultNoteVerificationFrozen,
  resolveClientVerification
} from 'app/core/lib/verification-utils';
import { keyBy } from 'lodash-es';
import { VerificationType } from 'app/core/enums/verification-type.enum';
import { ReferralMatchVerificationEvent } from 'app/core/models/referral-match-verification-event.model';

@Component({
  selector: 'app-edit-referral-match-verifications-client',
  templateUrl: './edit-referral-match-verifications-client.component.html',
  styleUrls: ['./edit-referral-match-verifications-client.component.scss'],
})
export class EditReferralMatchVerificationsClientComponent {
  _patientPrescriber: PatientPrescriber;

  @Input() patientAttachment: PatientAttachment;
  @Output() verificationChange = new EventEmitter<ReferralMatchVerificationEvent>();

  referralMatchOptions: ClientReferralMatchOption[] = [];
  loadingReferralMatchOptions = true;

  constructor(
    private authService: AuthenticationService,
    private referralMatchVerificationsService: ReferralMatchVerificationsService,
    private clientReferralVerificationsService: ClientReferralVerificationsService
  ) { }

  get patientPrescriber(): PatientPrescriber {
    return this._patientPrescriber;
  }

  @Input()
  set patientPrescriber(pp: PatientPrescriber) {
    this._patientPrescriber = pp;

    this.fetchClientReferralVerifications();
  }

  onReferralVerificationChange(option: ClientReferralMatchOption) {
    const updatedVerification = { ...option.verification };
    const updatedOption = { ...option };

    if (option.selected) {
      updatedVerification.peerClientReferralVerification = true;
    }
    updatedOption.verification = updatedVerification;

    this.referralMatchVerificationsService.updateForOption(
      this.patientPrescriber.id,
      updatedOption,
      this.verificationChange
    ).subscribe();
  }

  private fetchClientReferralVerifications() {
    const filters = {
      patientId: this.patientPrescriber.patientId,
      hasClientReferralTaskSubmission: true,
      includeClientReferralTaskSubmission: true,
      includePrescriber: true
    };

    this.clientReferralVerificationsService.getList(filters).
      subscribe(({ clientReferralVerifications }: PagedClientReferralVerifications) => {
        this.setupReferralMatchOptions(clientReferralVerifications);
        this.loadingReferralMatchOptions = false;
      });
  }

  private setupReferralMatchOptions(clientReferralVerifications: ClientReferralVerification[]) {
    const filteredRmvs = this.patientPrescriber.referralMatchVerifications.filter(isClientReferralMatchVerification);
    const rmvsByCrvId = keyBy(filteredRmvs, (rmv: ReferralMatchVerification) => rmv.clientReferralVerificationId);

    this.referralMatchOptions =
      clientReferralVerifications.map(clientReferralVerification => {
        const resolvedVerification =
          resolveClientVerification(clientReferralVerification, rmvsByCrvId);

        const disabled = isConsultNoteVerificationFrozen(
          this.authService,
          this.patientAttachment,
          VerificationType.referralMatch,
          resolvedVerification
        );

        return new ClientReferralMatchOption(
          resolvedVerification,
          clientReferralVerification,
          !!resolvedVerification.id,
          disabled
        )
      });
  }
}
