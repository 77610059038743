<td>
  <div>
    <a routerLink="{{task.id}}" (click)="onViewClick()">{{ task.patient.fullName }}</a>
  </div>
  <div class="small text-nowrap"><strong>MRN:</strong> {{ task.patient.mrn }}</div>
  <app-user-presence category="clientReferralTask" [targetId]="task.id" />
</td>
<td><app-prescriber-name [prescriber]="task.prescriber" [showSpecialtiesDetails]="true" /></td>
<td>{{ task.status | taskStatus }}</td>
<td>{{ task.source | claimSource | blankValueDash }}</td>
<td class="text-end">{{ task.clientLastViewedAt | date: 'MM/dd/yyyy' }}</td>
<td class="text-center">
  <span>{{ task.prescriptionsCount | number }}</span>
</td>
<td class="text-center"><app-task-estimated-value [rank]="task.estimatedValueRank" [value]="task.estimatedValue" /></td>
<td class="text-center"><app-task-estimated-value [rank]="task.patientEstimatedValueRank" [value]="task.patientEstimatedValue" /></td>
<td class="text-end"><app-expiration-date [expirationDate]="task.nextClaimExpirationDate" /></td>
