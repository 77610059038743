<div class="content">
  <h3>Search</h3>
  <app-capture-search-settings
    [disableSearch]="loadingInitialResults"
    (performSearch)="onPerformSearch()"
    (performReset)="onPerformReset()" />
  <ng-container *ngIf="!loadingInitialResults && hasPerformedSearch && hasAnyResults">
    <div class="mt-1">
      <strong>Captures: </strong>
      <span> {{ matchingCapturesCount ? (matchingCapturesCount | number) : null | blankValueDash }}</span>
    </div>
    <div infiniteScroll (scrolled)="onScrolled()" [infiniteScrollDisabled]="loadingMoreResults">
      <app-capture-search-results [captureQueueResults]="captureQueueResults" />
      <div *ngIf="loadingMoreResults" class="more-results-spinner">
        <mat-spinner diameter="20" />
      </div>
    </div>
  </ng-container>

  <div class="mt-2 ms-1" *ngIf="!loadingInitialResults && hasPerformedSearch && !hasAnyResults">
    No captures found. Try modifying the search.
  </div>
  <div *ngIf="loadingInitialResults" class="mt-2 ms-1"><mat-spinner diameter="30" /></div>
</div>
