<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Report Incorrect Information</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <h4>
        Please share as much info on this provider as possible below. We appreciate any additional info, including
        specialties, office names, and fax information. Thank you!
      </h4>

      <h6 class="subheading">Prescriber</h6>

      <div class="row mb-2">
        <div class="col">
          <div><strong>PRESCRIBER</strong></div>
          <div><app-prescriber-name [prescriber]="prescriber" /></div>
          <br />
          <div><strong>SPECIALTY</strong></div>
          <div>{{ prescriber.displaySpecialty }}</div>
        </div>
        <div class="col">
          <app-prescriber-offices-scrollable [prescriber]="prescriber" [acceptableFaxStatusOnly]="true" />
        </div>
      </div>

      <div class="mb-3">
        <textarea class="form-control comments" name="comments" [(ngModel)]="comments" appAutoFocus></textarea>
      </div>

      <div class="alert alert-danger text-danger mt-2 p-2" *ngIf="serverError">
        A server error occurred. Please try again or contact support.
      </div>

      <div class="alert alert-danger text-danger mt-2" *ngIf="validationErrors.length > 0">
        <ul class="py-2">
          <li *ngFor="let error of validationErrors">{{ error }}</li>
        </ul>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit(modal)">Submit</button>
    <button type="button" class="btn btn-secondary" (click)="modal.close()">Cancel</button>
  </div>
</ng-template>

<div *ngIf="providerIncorrectInfoModalEnabled"
     class="prompt-text align-items-center mb-2">

  <mat-icon>sms_failed</mat-icon>
  <span>
    Do you have different info on this provider? Please
    <a href="#" (click)="openModal($event, content)">click here</a>
    to let us know.
  </span>
</div>
