<div class="d-flex prescriber">
  <div>
    <strong>{{ matchingPatientPrescriber.provider.displayName }}</strong>
    <span>(NPI: {{ matchingPatientPrescriber.provider.npi }})</span>
    <span> - {{ matchingPatientPrescriber.provider.displaySpeciality }}</span>
  </div>
  <div class="text-end"><a href="#editRMVs" (click)="onEditRmvsClick($event)">Edit RMVs</a></div>
</div>
<div>
  <ng-container *ngIf="anySelectedReferralMatchVerifications; else: noReferralMatchVerifications">

    <div class="mt-2" *ngIf="standardReferralMatchVerifications.length > 0">
      <h5>Patient Referrals From EHR Feeds</h5>
      <app-validate-referral-match-verifications-prescriber-list
        [patientReferralMatchVerifications]="standardReferralMatchVerifications" />
    </div>

    <div class="mt-2" *ngIf="clientReferralMatchVerifications.length > 0">
      <h5>Patient Referrals From Client Tasks</h5>
      <app-validate-referral-match-verifications-client-list
        [patientReferralMatchVerifications]="clientReferralMatchVerifications" />
    </div>

    <div class="mt-2" *ngIf="ehrReferralMatchVerifications.length > 0">
      <h5>Referral Evidence Found in EHR</h5>
      <app-validate-referral-match-verifications-ehr-list
        [patientReferralMatchVerifications]="ehrReferralMatchVerifications" />
    </div>

    <div class="d-flex mt-2 g-0">
      <div class="col-4" *ngIf="clientDateReferralMatchVerifications.length > 0">
        <div><strong class="text-black-50">CLIENT REFERRAL DATES</strong></div>
        <ul class="list-unstyled mb-0">
          <li *ngFor="let clientReferralMatchVerification of clientDateReferralMatchVerifications">
            {{ clientReferralMatchVerification.clientReferralDate | date: 'MM/dd/yyyy' }}
          </li>
        </ul>
      </div>

      <div class="col-4 ps-3">
        <strong class="text-black-50">ADDITIONAL DETAILS</strong>
        <p>{{ matchingPatientPrescriber.patientPrescriber.referralMatchVerificationNotes | blankValueDash }}</p>
      </div>

      <div class="col-4 ps-3" *ngIf="matchingPatientPrescriber.clientReferralTask">
        <div><strong class="text-black-50">REFERRAL TASK ID</strong></div>
        <a routerLinkActive="active" routerLink="/capture-admin/referral-claims/clients/client-referral-tasks/{{ matchingPatientPrescriber.clientReferralTask.id }}">{{ matchingPatientPrescriber.clientReferralTask.id }}</a>

        <div class="mt-3"><strong class="text-black-50">TASK RESPONSE</strong></div>
        <app-client-referral-task-response
          [taskSubmission]="matchingPatientPrescriber.clientReferralTask.mostRecentSubmission"
          [patient]="patientAttachment.patient"
          [provider]="matchingPatientPrescriber.provider" />
        <p *ngIf="matchingPatientPrescriber.clientReferralTask.notes">
          {{ matchingPatientPrescriber.clientReferralTask.notes }}
        </p>
      </div>
    </div>

  </ng-container>
  <ng-template #noReferralMatchVerifications>
    <div class="my-2 alert alert-danger">No referral match verifications selected.</div>
  </ng-template>
</div>
