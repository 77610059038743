import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ClientPrescriberTasksService } from 'app/core/services/client-prescriber-tasks.service';
import { ClientPrescriberTask } from 'app/core/models/client-prescriber-task.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientPrescriberTaskStatus } from 'app/core/enums/client-prescriber-task-status.enum';
import { AppToastService } from 'app/core/services/app-toast.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { Title } from '@angular/platform-browser';
import { MeService } from 'app/core/services/me.service';
import { NextQueueItem } from 'app/core/models/next-queue-item.model';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPresenceService } from 'app/core/services/user-presence.service';

const shouldReloadTask = (task: ClientPrescriberTask, taskId) => task && taskId !== task.id.toString();
const userPresenceKey = 'clientPrescriberTask';

@Component({
  templateUrl: './client-prescriber-task.component.html',
})
export class ClientPrescriberTaskComponent implements OnInit, OnDestroy {
  task: ClientPrescriberTask;
  refreshing = true;
  formDisabled = true;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private titleService: Title,
    private clientPrescriberTasksService: ClientPrescriberTasksService,
    private toastService: AppToastService,
    private meService: MeService,
    private userPresenceService: UserPresenceService
  ) {
    this.formDisabled = this.authService.isCaptureAdminUser;

    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(): void {
    this.leaveAndUnsubscribe();
  }

  ngOnInit() {
    const taskId = this.route.snapshot.paramMap.get('id');
    this.loadTask(taskId);
  }

  ngOnDestroy(): void {
    this.leaveAndUnsubscribe();
  }

  onTaskTransition(task: ClientPrescriberTask) {
    this.task = task;
    this.handleTransition(task);
  }

  private loadTask(id) {
    this.refreshing = true;
    this.clientPrescriberTasksService.get(id).subscribe(
      task => {
        this.task = task;
        this.refreshing = false;
        this.updateTitle();
        this.subscribeAndJoin(this.taskId);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
  }

  private handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const taskId = params && params['id'];

    if (shouldReloadTask(this.task, taskId)) {
      this.leaveAndUnsubscribe();
      this.loadTask(taskId);
    }
  }

  private handleTransition(currentTask: ClientPrescriberTask) {
    const confirmation = this.createConfirmation(currentTask);

    this.meService.notifyUserTodosChanged();

    this.clientPrescriberTasksService
      .getNextClientTask()
      .subscribe(({ item, wasProcessing }: NextQueueItem<ClientPrescriberTask>) => {
        if (item) {
          // leave the previous task and join the next one
          this.leaveAndUnsubscribe();
          this.subscribeAndJoin(item.id);
          this.task = item;
          this.navigate(
            `/ce-portal/tasks/client-prescriber-tasks/${item.id}`,
            this.appendNextMessage(confirmation),
            () => this.updateTitle()
          );
        } else if (wasProcessing) {
          this.navigate(`/ce-portal/tasks/client-prescriber-tasks`, this.appendDoneMessage(confirmation));
        } else {
          this.navigate(`/ce-portal/tasks/client-prescriber-tasks`, confirmation);
        }
      });
  }

  private navigate(url, confirmation, cb = () => {}) {
    this.router.navigateByUrl(url).then(() => {
      this.toastService.show(confirmation.message, {
        cssClass: `flashfade ${confirmation.cssClass}`,
        timeout: 4000,
      });

      cb();
    });
  }

  private appendNextMessage(confirmation) {
    confirmation.message = `${confirmation.message}<br/>Here's the next task that <strong>Requires Review</strong>.`;
    return confirmation;
  }

  private appendDoneMessage(confirmation) {
    confirmation.message = `${confirmation.message} <br/><strong>Done!</strong>`;
    return confirmation;
  }

  private createConfirmation(task) {
    const confirmation = { message: '', cssClass: '' };
    const claims = task.captureCount > 1 ? 'claims' : 'claim';

    if (task.status === ClientPrescriberTaskStatus.accepted) {
      confirmation.message = `<strong>Nice!</strong> You just approved ${task.submittedCaptureCount} ${claims}!`;
      confirmation.cssClass = 'bg-success-subtle';
    } else if (task.status === ClientPrescriberTaskStatus.rejected) {
      confirmation.message = `${task.submittedCaptureCount} ${claims} rejected.`;
      confirmation.cssClass = 'bg-danger-subtle';
    }

    return confirmation;
  }

  private updateTitle() {
    const title = `R1 340B Recovery - View Task | ${this.task.patient.mrn}`;
    this.titleService.setTitle(title);
  }

  private get taskId(): number {
    return Number(this.route.snapshot.paramMap.get('id'));
  }

  public get navBaseUrl(): string {
    return `/capture-admin/referral-claims/clients/client-prescriber-tasks/${this.taskId}`;
  }

  public get displayUserPresence(): boolean {
    return !this.authService.isCaptureAdminUser;
  }

  private subscribeAndJoin(id) {
    if (!this.displayUserPresence) return;
    this.userPresenceService.subscribeAndJoin(userPresenceKey, id);
  }

  private leaveAndUnsubscribe() {
    if (!this.displayUserPresence) return;
    this.userPresenceService.leaveAndUnsubscribe(userPresenceKey, this.task.id);
  }
}
