<div *ngIf="!loadingProviderCallLogs; else: spinner">
  <div *ngIf="providerCallLogs.length > 0; else: noProviderCallLogs">
    <table class="table table-bordered table-sm">
      <thead class="table-dark">
        <tr>
          <th class="col-1">DATE CALLED</th>
          <th class="col-1">CALLED BY</th>
          <th class="col-2">CALL OUTCOME</th>
          <th class="col-3">OFFICE</th>
          <th class="col-2">PHONE</th>
          <th class="col-3">NOTES</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let providerCallLog of providerCallLogs">
          <td>{{ providerCallLog.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
          <td>{{ providerCallLog.calledBy?.fullName }}</td>
          <td>{{ providerCallLog.callOutcome }}</td>
          <td>
            <app-office [office]="providerCallLog.office" *ngIf="providerCallLog.office" />
            <p *ngIf="!providerCallLog.office" class="red-cell" >Unknown</p>
          </td>
          <td [ngClass]="highlightPhone(providerCallLog.enteredPhone, providerCallLog.office?.phone)">
            <app-phone-number [number]="providerCallLog.enteredPhone || providerCallLog.office?.phone" [noValue]="' '" />
          </td>
          <td [innerHtml]='providerCallLog.notes | safeHtml'></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
<ng-template #noProviderCallLogs>
  <p>There are no outbound calls for provider.</p>
</ng-template>
