<ng-container *ngIf="!loadingReferrals; else: spinner">
  <nav *ngIf="showIneligibleReferrals" class="nav nav-pills mb-2">
    <a class="nav-item nav-link active"
       [class.active]="currentTab === 'eligibleReferrals'"
       (click)="tabClick($event, 'eligibleReferrals')"
       href="#">
      Eligible Referrals ({{ eligibleReferrals.length }})
    </a>
    <a class="nav-item nav-link"
       [class.active]="currentTab === 'ineligibleReferrals'"
       (click)="tabClick($event, 'ineligibleReferrals')"
       href="#">
      Ineligible Referrals ({{ ineligibleReferrals.length }})
    </a>
  </nav>

  <ng-container
    *ngIf="!showIneligibleReferrals || currentTab === 'eligibleReferrals'"
    [ngTemplateOutlet]="referralList"
    [ngTemplateOutletContext]="{ iterable: eligibleReferralsOrOptions }" />

  <ng-container
    *ngIf="currentTab === 'ineligibleReferrals'"
    [ngTemplateOutlet]="referralList"
    [ngTemplateOutletContext]="{ iterable: ineligibleReferrals }" />
</ng-container>

<ng-template #referralList let-iterable="iterable">
  <div [class.compact]="compactClientView">
    <table class="table table-sm"
           *ngIf="iterable.length > 0; else: noPatientReferrals">
      <thead>
      <tr>
        <th>Referral Date</th>
        <th>
          <ng-container *ngIf="compactClientView">
            Referred To Provider
          </ng-container>
          <ng-container *ngIf="!compactClientView">
            Receiving Provider / Practice
          </ng-container>
        </th>
        <th>Specialty</th>
        <th>Receiving Office</th>
        <th>Referring Provider</th>
        <th>Location</th>
        <th>Ref-ID</th>
        <th *ngIf="!compactClientView">Status</th>
        <th *ngIf="!compactClientView">Notes</th>
        <th *ngIf="!compactClientView">Consult</th>
        <th *ngIf="!compactClientView">Consult Desc</th>
        <th *ngIf="!compactClientView">PT Consult Desc</th>
        <th *ngIf="!compactClientView">Patient MRN</th>
        <th *ngIf="!compactClientView">EHR Instance</th>
      </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of iterable; let i = index">
          <tr [class.selected]="option(item)?.selected"
              [class.row-bottom]="compactClientView || !referral(item).referralReason"
              class="referral">

            <td>{{ referral(item).referralDate | date: 'MM/dd/yyyy' }}</td>

            <td>
              <div *ngIf="option(item)"
                   class="form-check"
                   [ngClass]="{ disabled: option(item).disabled }">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [id]="'rmvProvider' + i"
                    [disabled]="option(item).disabled"
                    [(ngModel)]="option(item).verification.provider"
                    (change)="onReferralMatchOptionChange(option(item))"
                  />
                  {{ referral(item).receivingProviderDisplayNameWithNpi }}
                  <div *ngIf="!compactClientView">
                    {{ referral(item).receivingProviderDisplaySpecialty }}
                  </div>
                </label>
              </div>

              <div *ngIf="!option(item)">
                {{ referral(item).receivingProviderDisplayNameWithNpi }}
                <div *ngIf="!compactClientView">
                  {{ referral(item).receivingProviderDisplaySpecialty }}
                </div>
              </div>
            </td>

            <td>
              <div *ngIf="option(item)"
                   class="form-check"
                   [ngClass]="{ disabled: option(item).disabled }">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [id]="'rmvSpecialty' + i"
                    [disabled]="option(item).disabled"
                    [(ngModel)]="option(item).verification.specialty"
                    (change)="onReferralMatchOptionChange(option(item))"
                  />
                  {{ referral(item).referralSpecialty }}
                </label>
              </div>
              <div *ngIf="!option(item)">
                {{ referral(item).referralSpecialty }}
              </div>
            </td>

            <td>
              <div *ngIf="option(item)"
                   class="form-check"
                   [ngClass]="{ disabled: option(item).disabled }">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [id]="'rmvOffice' + i"
                    [disabled]="option(item).disabled"
                    [(ngModel)]="option(item).verification.office"
                    (change)="onReferralMatchOptionChange(option(item))"
                  />
                  <div>{{ referral(item).receivingOfficeName }}</div>
                  <small *ngIf="!compactClientView">
                    <strong>FAX:</strong> <app-phone-number [number]="referral(item).receivingProviderFax" />
                  </small>
                </label>
              </div>
              <div *ngIf="!option(item)">
                <div>{{ referral(item).receivingOfficeName }}</div>
                <small *ngIf="!compactClientView">
                  <strong>FAX:</strong> <app-phone-number [number]="referral(item).receivingProviderFax" />
                </small>
              </div>
            </td>

            <td>{{ referral(item).sendingProviderDisplayNameWithNpi }}</td>
            <td>{{ referral(item).sendingLocation }}</td>
            <td>{{ referral(item).ehrIdentifier }}</td>
            <td *ngIf="!compactClientView">{{ referral(item).referralEhrStatus }}</td>
            <td *ngIf="!compactClientView">{{ referral(item).referralEhrNotes }}</td>
            <td *ngIf="!compactClientView">{{ referral(item).referralConsultInEhr }}</td>
            <td *ngIf="!compactClientView">{{ referral(item).referralConsultEhrDescriptions }}</td>
            <td *ngIf="!compactClientView">{{ referral(item).patientConsultEhrDescriptions }}</td>
            <td *ngIf="!compactClientView">{{ referral(item).sourcePatientMrn }}</td>
            <td *ngIf="!compactClientView">{{ referral(item).ehrInstance }}</td>

          </tr>

          <tr *ngIf="!compactClientView && referral(item).referralReason"
              class="referral-reason row-bottom"
              [class.selected]="option(item)?.selected">
            <td colspan="14"><strong>REFERRAL REASON:</strong> {{ referral(item).referralReason }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #spinner>
  <div class="mt-1 mb-2 ms-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noPatientReferrals>
  <div class="my-2">No patient referrals found.</div>
</ng-template>
