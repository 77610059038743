import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { MatchingPatientPrescriber } from '../../../core/models/matching-patient-prescriber.model';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EditReferralMatchVerificationsModalComponent } from '../edit-referral-match-verifications-modal/edit-referral-match-verifications-modal.component';
import {
  isClientDateReferralMatchVerification,
  isClientReferralMatchVerification,
  isEhrReferralMatchVerification,
  isSelectedStandardReferralMatchVerification,
  sortReferralMatchVerificationsByClientReferralDate,
} from '../../../core/lib/verification-utils';
import { PatientPrescriberService } from '../../../core/services/patient-prescriber.service';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';

@Component({
  selector: 'app-validate-referral-match-verifications-prescriber',
  templateUrl: './validate-referral-match-verifications-prescriber.component.html',
  styleUrls: ['./validate-referral-match-verifications-prescriber.component.scss'],
})
export class ValidateReferralMatchVerificationsPrescriberComponent implements OnInit {
  _matchingPatientPrescriber: MatchingPatientPrescriber;

  @Input() patientAttachment: PatientAttachment;

  editRmvsModal: NgbModalRef = null;
  standardReferralMatchVerifications: ReferralMatchVerification[] = [];
  clientReferralMatchVerifications: ReferralMatchVerification[] = [];
  ehrReferralMatchVerifications: ReferralMatchVerification[] = [];
  clientDateReferralMatchVerifications: ReferralMatchVerification[] = [];
  patientPrescriberChanged = false;
  anySelectedReferralMatchVerifications = false;

  constructor(private modalService: NgbModal, private patientPrescriberService: PatientPrescriberService) { }

  ngOnInit() { }

  get matchingPatientPrescriber(): MatchingPatientPrescriber {
    return this._matchingPatientPrescriber;
  }

  @Input()
  set matchingPatientPrescriber(mpp: MatchingPatientPrescriber) {
    this._matchingPatientPrescriber = mpp;
    this.initializeReferralMatchVerifications();
  }

  onEditRmvsClick($event: MouseEvent) {
    $event.preventDefault();
    this.openEditRmvsModal();
  }

  private initializeReferralMatchVerifications() {
    const patientPrescriber = this.matchingPatientPrescriber.patientPrescriber;
    const rmvs = patientPrescriber.referralMatchVerifications;

    this.clientDateReferralMatchVerifications =
      sortReferralMatchVerificationsByClientReferralDate(rmvs.filter(isClientDateReferralMatchVerification));
    this.standardReferralMatchVerifications = rmvs.filter(isSelectedStandardReferralMatchVerification);
    this.clientReferralMatchVerifications = rmvs.filter(isClientReferralMatchVerification);
    this.ehrReferralMatchVerifications = rmvs.filter(isEhrReferralMatchVerification);

    this.anySelectedReferralMatchVerifications =
      this.clientDateReferralMatchVerifications.length > 0 ||
      this.standardReferralMatchVerifications.length > 0 ||
      this.clientReferralMatchVerifications.length > 0 ||
      this.ehrReferralMatchVerifications.length > 0;
  }

  private openEditRmvsModal() {
    this.editRmvsModal = this.modalService.open(EditReferralMatchVerificationsModalComponent, {
      windowClass: 'edit-rmv-modal',
    });

    const modalInstance = this.editRmvsModal.componentInstance;

    modalInstance.patientAttachment = this.patientAttachment;
    modalInstance.patientPrescriber = this.matchingPatientPrescriber.patientPrescriber;
    modalInstance.patientPrescriberChange.subscribe((patientPrescriber: PatientPrescriber) => {
      this.patientPrescriberChanged = true;
      this.matchingPatientPrescriber.patientPrescriber = patientPrescriber;
      modalInstance.patientPrescriber = patientPrescriber;
      this.initializeReferralMatchVerifications();
    });

    this.editRmvsModal.result.then(
      () => {
        this.advanceCapturesRequiringReferral();
      },
      () => {
        this.advanceCapturesRequiringReferral();
      }
    );
  }

  private advanceCapturesRequiringReferral() {
    if (this.shouldAdvanceNeedsReferralMatchApproval()) {
      this.patientPrescriberService
        .advanceCapturesRequiringReferral(this.matchingPatientPrescriber.patientPrescriber.id)
        .subscribe(() => {
          this.patientPrescriberChanged = false;
        });
    }
  }

  private shouldAdvanceNeedsReferralMatchApproval() {
    return this.patientPrescriberChanged && this.anySelectedReferralMatchVerifications;
  }
}
