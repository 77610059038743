import { Component, Input, OnChanges } from '@angular/core';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientPrescriber } from '../../../core/models/patient-prescriber.model';
import {
  isClientDateReferralMatchVerification,
  sortReferralMatchVerificationsByClientReferralDate
} from 'app/core/lib/verification-utils';

@Component({
  selector: 'app-edit-referral-match-verifications-client-dates',
  templateUrl: './edit-referral-match-verifications-client-dates.component.html',
  styleUrls: ['./edit-referral-match-verifications-client-dates.component.scss'],
})
export class EditReferralMatchVerificationsClientDatesComponent implements OnChanges {
  @Input() patientAttachment: PatientAttachment;
  @Input() patientPrescriber: PatientPrescriber;

  clientReferralMatchVerifications: ReferralMatchVerification[] = [];

  ngOnChanges() {
    this.loadClientReferralMatchVerifications();
  }

  private loadClientReferralMatchVerifications() {
    this.clientReferralMatchVerifications =
      sortReferralMatchVerificationsByClientReferralDate(
        this.patientPrescriber.referralMatchVerifications.filter(isClientDateReferralMatchVerification));
  }
}
