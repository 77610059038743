<div class="section mt-2">
  <div class="card">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-10">
          <h4>Notes</h4>
        </div>
      </div>

      <div class="section mt-2">
        <app-notes-for-model [model]="provider" [modelType]="'Provider'" />
      </div>
    </div>
  </div>
</div>

<div class="section mt-2">
  <div class="card">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-10">
          <h4>Offices</h4>
        </div>

        <div class="col-2 actions">
          <button
            class="btn btn-primary"
            [disabled]="showAddOffices"
            (click)="onAddNewOfficesClick()">
            Add Offices
          </button>
        </div>
      </div>

      <app-provider-office-new-form
        *ngIf="showAddOffices"
        [provider]="provider"
        [providerOffices]="providerOffices"
        (providerAdded)="onOfficeAdded()"
        (completed)="onOfficeFormCompleted()" />

      <app-provider-office-list
        [loading]="providerOfficesLoading"
        [providerOffices]="providerOffices" />
    </div>
  </div>
</div>

<div class="section mt-2">
  <div class="card bg-light" *ngIf="provider.informationCollectionDetails">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-10">
          <h4>Mechanical Turk HIT Details</h4>
        </div>
      </div>

      <div class="m-3">
        <app-provider-information-collection-details
          [provider]="provider" />
      </div>
    </div>
  </div>
</div>
