import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakAtNpi',
})
export class BreakAtNpiPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(new RegExp('\\(NPI'), '<br>(NPI');
    } else {
      return value;
    }
  }
}
