<div class="toast-container position-fixed translate-middle-x start-50 mt-3">
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    class="w-100"
    [class]="toast.cssClass"
    [autohide]="toast.keepOpen ? false : true"
    [delay]="toast.delay || 5000"
    (hidden)="hide(toast)">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl" />
    </ng-template>

    <ng-template #text>
      <button *ngIf="toast.showCloseButton" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>

      <span [innerHTML]="toast.textOrTpl"></span>
    </ng-template>
  </ngb-toast>
</div>
