<strong>OFFICE(S)</strong>
<div *ngIf="!officesRefreshing">
  <div class="row" *ngIf="providerOffices">
    <div *ngFor="let providerOffice of providerOffices"
          class="office col-auto"
          [ngClass]="{ 'pre-scrollable': scrollable, 'border': scrollable }">

      <app-office
        [office]="providerOffice.office"
        [providerOffice]="providerOffice" />
    </div>
  </div>
  <div *ngIf="providerOffices.length === 0" class="fst-italic">
    No prescriber offices in supplemental data
  </div>
</div>
