import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';
import { ReferralMatchVerificationsService } from '../../../core/services/referral-match-verifications.service';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import {
  isSelectedStandardReferralMatchVerification,
  isClientReferralMatchVerification,
  isClientDateReferralMatchVerification,
  isEhrReferralMatchVerification,
} from '../../../core/lib/verification-utils';
import { ClientReferralVerification } from 'app/core/models/client-referral-verification.model';
import { EhrReferralVerification } from 'app/core/models/ehr-referral-verification.model';
import { Referral } from 'app/core/models/referral.model';

@Component({
  selector: 'app-client-consult-note-task-referrals',
  templateUrl: './client-consult-note-task-referrals.component.html',
  styleUrls: ['./client-consult-note-task-referrals.component.scss'],
  providers: [DatePipe],
})
export class ClientConsultNoteTaskReferralsComponent implements OnInit {
  loadingReferralMatchVerifications = true;
  loadingClientReferralVerifications = true;

  referrals: Referral[] = [];
  clientReferralVerifications: ClientReferralVerification[] = [];
  clientDateReferralMatchVerifications: ReferralMatchVerification[] = [];
  ehrReferralVerifications: EhrReferralVerification[] = [];

  anyReferralMatchVerifications = false;
  clientReferralVerificationDates: string;

  @Input() task: ClientConsultNoteTask;

  constructor(
    private datePipe: DatePipe,
    private referralMatchVerificationsService: ReferralMatchVerificationsService,
  ) { }

  ngOnInit() {
    this.loadReferralMatchVerifications();
  }

  private loadReferralMatchVerifications() {
    this.loadingReferralMatchVerifications = true;

    this.referralMatchVerificationsService.getList(this.task.patientPrescriberId).subscribe(rmvs => {
      this.loadingReferralMatchVerifications = false;

      this.referrals =
        rmvs.filter(isSelectedStandardReferralMatchVerification).map(rmv => rmv.referral);

      this.clientReferralVerifications =
        rmvs.filter(isClientReferralMatchVerification).map(rmv => rmv.clientReferralVerification);

      this.clientDateReferralMatchVerifications = rmvs.filter(isClientDateReferralMatchVerification);

      this.ehrReferralVerifications =
        rmvs.filter(isEhrReferralMatchVerification).map(rmv => rmv.ehrReferralVerification);

      this.anyReferralMatchVerifications =
        this.referrals.length > 0 ||
        this.clientReferralVerifications.length > 0 ||
        this.ehrReferralVerifications.length > 0;

      this.clientReferralVerificationDates =
        this.clientDateReferralMatchVerifications.
          map((rmv: ReferralMatchVerification) => this.datePipe.transform(rmv.clientReferralDate, 'MM/dd/yyyy')).
          join(", ");
    });
  }
}
