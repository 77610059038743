<div class="col-auto m-1">
  <ng-select
    bindLabel="humanizedName"
    [placeholder]="label"
    [multiple]="multiple"
    [disabled]="disabled"
    (change)="onChange()"
    [items]="clients"
    [(ngModel)]="selectedClients"
    dropdownPosition="bottom" />
</div>
