<form>
  <h5 class="question">
    Do you have a consult note on file for patient {{ task.patient.fullName }}, DOB
    {{ task.patient.dob | date: 'M/dd/yyyy' }} from prescriber {{ task.prescriber.fullName }} or his/her office?
  </h5>
  <ng-container *ngFor="let choiceOption of choiceOptions">
    <div class="choice border bg-white rounded-0 px-3 py-2 my-1 mx-0" [ngClass]="{ disabled: disabled }">
      <div class="form-check" (click)="onChoiceClick($event, choiceOption.value)">
        <input
          [id]="choiceOption.value"
          [disabled]="disabled"
          name="selection"
          type="radio"
          [value]="choiceOption.value"
          [(ngModel)]="formModel.choice"
        />
        <label [for]="choiceOption.value" class="form-check-label">
          <strong>{{ choiceOption.label }}</strong>
        </label>
        <p class="form-text">
          <small>{{ choiceOption.helpText }}</small>
        </p>
      </div>
      <ng-container *ngIf="choiceOption.value === choices.no">
        <app-client-task-rejection-reasons
          [task]="task"
          [reasonSelections]="formModel.reasonSelections"
          [formDisabled]="formDisabled"
          (rejectionReasonsChange)="onRejectionReasonChange($event, choiceOption.value)"
          (rejectionReasonsClear)="onRejectionReasonsClear()"
        />
      </ng-container>
    </div>
  </ng-container>
</form>
