import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-source',
  templateUrl: './candidate-source.component.html',
  styleUrls: ['./candidate-source.component.scss'],
})
export class CandidateSourceComponent implements OnInit {
  @Input() candidate: { source: string; storeIdentifier: string };

  constructor() {}

  ngOnInit() {}
}
