<nav id="mainNavbar" class="navbar navbar-expand-md navbar-dark" [ngClass]="{ 'viewing-as-client-navbar': viewingAsClient }">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img src="assets/images/r1-logo-white.png"/></a>
    <button
      class="navbar-toggler"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      data-bs-target="#navbarSupportedContent"
      data-bs-toggle="collapse"
      type="button"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="navbar-nav me-auto">
      </div>
      <ng-container *ngIf="isConsultant">
        <div class="consultant-client-picker">
          <form novalidate class="form-inline">
            <div class="filter mb-3">
              <select class="form-select" [(ngModel)]="defaultAllowedClientId" (change)="onClientChange()" name="client">
                <option *ngFor="let client of availableClients" [value]="client.id"> {{ client.humanizedName }} </option>
              </select>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="viewingAsClient">
        <div class="float-end viewing-as-client text-center alert alert-warning px-2 pb-1 d-block">
          <div>
            <small><strong>VIEWING PORTAL AS</strong></small>
          </div>
          <div class="mb-2 text-dark client-name">{{ viewingAsClient.humanizedName }}</div>
          <a href="#backToCapureAdmin" (click)="onViewPortalClick($event)" class="btn btn-primary btn-sm">
            Go Back To R1 Admin</a
          >
        </div>
      </ng-container>
      <ul class="navbar-nav float-end">
        <li class="nav-item" *ngIf="showPreferencesLink">
          <a class="nav-link" routerLink="/ce-portal/preferences" routerLinkActive="active">
            <fa-icon [icon]="['fas', 'cog']" size="lg" />
          </a>
        </li>
        <li *ngIf="showVersionAlert" class="nav-item">
          <a href="#" (click)="onVersionWarningClick($event)" class="nav-link">
            <mat-icon
              class="alert-icon text-warning"
              matTooltip="A new version is available."
            >warning</mat-icon>
          </a>
        </li>
        <li class="nav-item">
          <a *ngIf="isLoggedIn" href="#logout" class="nav-link" (click)="onLogoutClick($event)">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
