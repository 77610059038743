<div class="row">
  <div class="col-4 left">
    <app-validate-prescriber-match
      [capture]="capture"
      [patientAttachment]="selectedAttachment"
    />
    <app-validate-patient-match
      [capture]="capture"
      [patientAttachment]="selectedAttachment"
    />
    <app-validate-specialist-encounter
      [capture]="capture"
      [patientAttachment]="selectedAttachment"
    />
    <app-validate-drug-details [capture]="capture" [patientAttachment]="selectedAttachment" />
  </div>
  <div class="col-8 right">
    <div *ngIf="!selectedAttachment.ehrConsultNoteReference && !usePdfKitViewer" class="row g-0 align-items-end mb-1">
      <div class="col-6">
        Page {{ activePage }} <span *ngIf="activePage === selectedAttachment.medicationsPage">(Medications)</span>
      </div>
    </div>
    <div (click)="onDocumentClick($event)">
      <ng-container *ngIf="!selectedAttachment.ehrConsultNoteReference">
        <app-pdf-file-viewer
          *ngIf="!usePdfKitViewer"
          height="86rem"
          [pdfFile]="selectedAttachment"
          [pdfFileType]="pdfFileType"
          [initialPage]="initialPage"
          [hideRotationControls]="hidePdfRotationControls"
          (pageChange)="onPageChange($event)" />
        <app-pdfkit-file-viewer *ngIf="usePdfKitViewer"
          height="86rem"
          [pdfFile]="selectedAttachment"
          [pdfFileType]="pdfFileType"
          (urlChange)="onUrlChange($event)" />
      </ng-container>

      <div *ngIf="selectedAttachment.ehrConsultNoteReference">
        <app-ehr-consult-note-reference
          [ehrConsultNoteReference]="selectedAttachment.ehrConsultNoteReference"
          (referenceUpdated)="onEhrConsultNoteReferenceUpdated($event)" />
      </div>
    </div>
  </div>
</div>
