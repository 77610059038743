<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Available</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      We've made some improvements to the 340B Recovery application.
      Click Update Now (it's quick!)
    </p>
    <p class="save-warning">Make sure to save any work in progress.</p>
  </div>
  <div class="modal-footer">
    <div class="text-start">
      <button type="button" class="btn btn-primary" (click)="onUpdate()">Update Now</button>
      <button type="button" class="btn btn-secondary" (click)="onCancel()">Later</button>
    </div>
  </div>
</div>
