export const prescriberSpecialtiesToReferralSpecialtiesMap = {
  "Addiction Medicine": "Addiction Medicine",
  "Addiction Psychiatry": "Psychiatry & Mental Health",
  "Allergy & Immunology": "Allergy & Immunology",
  "Anesthesiology": "Anesthesiology",
  "Bariatric Medicine/Weight Management": "Bariatric Medicine",
  "Behavioral Health": "Behavioral Health",
  "Breast Surgery Oncology ": "Oncology",
  "Cardiac Electrophysiology": "Cardiology",
  "Cardiac Surgery": "Cardiology",
  "Cardiology": "Cardiology",
  "Cardiothoracic Surgery": "Cardiology",
  "Colon & Rectal Surgery": "Colon & Rectal Surgery",
  "Dentistry": "Dental",
  "Dermatologic Oncology": "Oncology",
  "Dermatology": "Dermatology",
  "Diagnostic Radiology": "Radiology",
  "Emergency Medicine": "Emergency Medicine",
  "Endocrinology, Diabetes & Metabolism": "Endocrinology, Diabetes & Metabolism",
  "Foot & Ankle Surgery": "Orthopedic surgery",
  "Gastroenterology": "Gastroenterology",
  "Gastroenterology Surgery Oncology": "Oncology",
  "General Surgery": "General Surgery",
  "Genetics": "Genetics",
  "Gynecologic Oncology": "Oncology",
  "Hand Surgery": "Orthopedic surgery",
  "Heart Transplant": "Transplant Surgery",
  "Hematology": "Hematology",
  "Hematology & Oncology": "Hematology & Oncology",
  "Hepatology": "Hepatology",
  "Hospice & Palliative Care": "Palliative Care",
  "Hospitalist": "Hospitalist",
  "Hyperbaric Medicine & Wound Care": "Wound Care",
  "Infectious Disease": "Infectious Disease",
  "Interventional Cardiology": "Cardiology",
  "Interventional Radiology": "Radiology",
  "Kidney Transplant": "Transplant Surgery",
  "Liver Transplant": "Transplant Surgery",
  "Lung Transplant": "Transplant Surgery",
  "Maternal & Fetal Medicine": "Obstetrics & Gynecology",
  "Medical Oncology": "Oncology",
  "Midwife": "Obstetrics & Gynecology",
  "Nephrology": "Nephrology",
  "Neurology": "Neurology",
  "Neuro-Oncology": "Oncology",
  "Neurosurgery": "Neurological Surgery",
  "Obstetrics & Gynecology": "Obstetrics & Gynecology",
  "Oncology": "Oncology",
  "Ophthalmology": "Ophthalmology",
  "Optometry": "Optometry",
  "Oral & Maxillofacial Surgery": "Oral & Maxillofacial Surgery",
  "Orthopedic Surgery": "Orthopedic surgery",
  "Otolaryngology": "Otolaryngology",
  "Pain Medicine": "Pain Medicine",
  "Pediatric Cardiology": "Cardiology",
  "Pediatric Dermatology": "Dermatology",
  "Pediatric Emergency Medicine": "Emergency Medicine",
  "Pediatric Endocrinology ": "Endocrinology, Diabetes & Metabolism",
  "Pediatric Gastroenterology": "Gastroenterology",
  "Pediatric Hematology & Oncology": "Hematology & Oncology",
  "Pediatric Infectious Diseases": "Infectious Disease",
  "Pediatric Nephrology": "Nephrology",
  "Pediatric Neurology": "Neurology",
  "Pediatric Otolaryngology": "Otolaryngology",
  "Pediatric Pulmonology": "Pulmonology",
  "Pediatric Rheumatology": "Rheumatology",
  "Pediatric Urology": "Urology",
  "Pediatric Psychiatry": "Psychiatry & Mental Health",
  "Pharmacist": "Pharmacist",
  "Physical Medicine & Rehabilitation": "Physical Therapy & Rehabilitation",
  "Physical Therapy": "Physical Therapy & Rehabilitation",
  "Plastic & Reconstructive Surgery": "Plastic Surgery",
  "Podiatry": "Podiatry",
  "Psychiatry": "Psychiatry & Mental Health",
  "Psychology": "Psychiatry & Mental Health",
  "Pulmonology & Critical Care Medicine": "Pulmonology",
  "Radiation Oncology": "Oncology",
  "Radiology": "Radiology",
  "Reproductive Endocrinology": "Reproductive Endocrinology & Infertility",
  "Rheumatology": "Rheumatology",
  "Sleep Medicine": "Sleep Medicine",
  "Spine Surgery": "Orthopedic surgery",
  "Sports Medicine": "Sports Medicine",
  "Surgical Oncology": "Oncology",
  "Thoracic Oncology": "Oncology",
  "Thoracic Surgery": "Thoracic & Cardiac Surgery",
  "Transplant Surgery": "Transplant Surgery",
  "Trauma Surgery": "General Surgery",
  "Urogynecology & Pelvic Reconstructive Surgery": "Urogynecology",
  "Urologic Oncology": "Oncology",
  "Urology": "Urology",
  "Vascular & Endovascular Medicine": "Vascular Surgery",
  "Vascular Surgery": "Vascular Surgery"
}

export const referralSpecialties =
  [...new Set(Object.values(prescriberSpecialtiesToReferralSpecialtiesMap))].sort();
