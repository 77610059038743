<ng-container *ngIf="!loading; else: spinner">
  <div class="row mx-0 mb-2">
    <div class="col-10 px-0">
      <h3>{{ client.humanizedName }}</h3>
    </div>
    <div class="col-2 px-0 text-end">
      <app-client-actions
        *ngIf="canManageClients"
        [client]="client"
        (clientChange)="onClientChange($event)" />
    </div>
  </div>

  <div class="page-container">
    <div class="stats my-3 bg-light border px-3">
      <app-client-stats [client]="client" />
    </div>


    <div class="details">

      <div class="my-3">
        <h5>Client Information</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Name</label>
            <div>{{ client.name }}</div>
          </div>

          <div class="col-6">
            <label>Full Name</label>
            <div>{{ client.fullName | blankValueDash }}</div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Status</label>
            <div>{{ client.status | titlecase }}</div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>HRSA IDS</label>
            <div>{{ client.effectiveHrsaIdStrings.join(', ') | blankValueDash }}</div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Client Logo</label>
            <div *ngIf="client.logoUrl"><img src="{{ client.logoUrl }}" style="max-height: 100px;" /></div>
            <div *ngIf="!client.logoUrl"><app-blank-value-dash /></div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Super Client</label>
            <div *ngIf="client.superClient">
              <a routerLink="/capture-admin/client-management/clients/{{client.superClient.id}}">
                {{ client.superClient.humanizedName }}
              </a>
            </div>
            <div *ngIf="!client.superClient"><app-blank-value-dash /></div>
          </div>
        </div>

      </div>

      <div class="my-3">
        <h5>Deal Information</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Date Deal Closed</label>
            <div>{{ client.dateDealClosed | date: 'MM/dd/yyyy' | blankValueDash }}</div>
          </div>
          <div class="col-6">
            <label>Go Live Date</label>
            <div>{{ client.goLiveDate | date: 'MM/dd/yyyy' | blankValueDash }}</div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Annual Contract Value</label>
            <div>{{ client.annualContractValue | currency | blankValueDash }}</div>
          </div>
          <div class="col-6">
            <label>1st Year Estimated Value</label>
            <div>{{ client.firstYearEstimatedValue | currency | blankValueDash }}</div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Adjustment</label>
            <div>{{ client.adjustment | currency }}</div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <h5>Address & Contact</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Address</label>
            <div>
              <div *ngIf="client.address1">{{ client.address1 }}</div>
              <div *ngIf="client.address2">{{ client.address2 }}</div>
              <div>
                <span *ngIf="client.city">
                  {{ client.city }}<span *ngIf="client.city && client.state">, </span> {{ client.state }}
                </span>
                <span *ngIf="client.zip">{{ client.zip }}</span>
              </div>
              <div *ngIf="!client.address1 && !client.address2 && !client.city && !client.state && !client.zip">
                <app-blank-value-dash />
              </div>
            </div>
          </div>
          <div class="col-6">
            <label>Local Fax Number</label>
            <div>
              <app-phone-number [number]="client.faxLocal" />
            </div>
            <label>Toll Free Fax Number</label>
            <div>
              <app-phone-number [number]="client.faxTollfree" />
            </div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <h5>Claim Information</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Applicable Claim Types</label>
            <div>{{ applicableToClaimsTypes().join(', ') | blankValueDash }}</div>
          </div>

          <div class="col-6">
            <label>Referral Claim Identifier Prefixes</label>
            <div>{{ client.effectiveClaimIdentifierPrefixes.join(', ') | blankValueDash }}</div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <h5>Invoicing Information</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Invoicing Terms</label>
            <div>{{ client.invoicingTerms | titlecase | blankValueDash}}</div>
          </div>
          <div class="col-6">
            <label>Invoicing Terms Rate</label>
            <div>{{ client.invoicingTermsRate | blankValueDash }}</div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <h5>ESP</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Submitting to ESP?</label>
            <div>{{ (client.submittingToEsp === null ? null : client.submittingToEsp ? 'Yes' : 'No' ) | blankValueDash }}</div>
          </div>
          <div class="col-6">
            <label>ESP Submit Start Date</label>
            <div>{{ client.espSubmitStartDate | date: 'MM/dd/yyyy' | blankValueDash }}</div>
          </div>
        </div>
        <div class="row g-0" *ngIf="client.processingEspNdcs">
          <div class="col-12 alert alert-warning p-3">
            We are in the process of updating the ESP NDCS for the client.
          </div>
        </div>
        <div class="row g-0" *ngIf="client.espNdcsFileError">
          <div class="col-12 alert alert-danger p-3">
            {{ client.espNdcsFileError }}
          </div>
        </div>
        <ng-container *ngIf="processedValidFile">
          <div class="row g-0">
            <div class="col-12">
              <label>ESP NDCS</label>
              <div>{{ client.espNdcs | joinList | blankValueDash }}</div>
            </div>
          </div>
          <div class="row g-0" *ngIf="hasInvalidEspNdcs">
            <div class="col-12">
              <label>INVALID ESP NDCS</label>
              <div class="text-danger">{{ client.invalidEspNdcs | joinList }}</div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="my-3">
        <h5>Direct Messaging</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Direct Enabled</label>
            <div>{{ client.directEnabled ? 'Yes' : 'No' }}</div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Direct Client Sending Address</label>
            <div>{{ client.directSendingAddress | blankValueDash }}</div>
          </div>
          <div class="col-6">
            <label>Direct Client Receiving Address</label>
            <div>
              {{ client.directReceivingAddress | blankValueDash }}
              <div>
                <app-send-direct-test-message *ngIf="client.directReceivingAddress" [client]="client" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <h5>Third Party Administrators</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Live</label>
            <div>{{ liveThirdPartyAdministers() | blankValueDash }}</div>
          </div>

          <div class="col-6">
            <label>Contracted</label>
            <div>{{ contractedThirdPartyAdministers() | blankValueDash }}</div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <h5>Miscellaneous</h5>

        <div class="row g-0">
          <div class="col-6">
            <label>Data Sharing Enabled</label>
            <div>{{ client.allowResearchDataSharing ? 'Yes' : 'No' }}</div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Allowed EHR Instance Values</label>
            <div>
              <div>{{ effectiveAllowedClientFileEhrInstanceValues | joinList | blankValueDash }}</div>
            </div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Client File Monitoring</label>
            <div>
              {{ client.clientFileMonitoringEmailRecipients && client.clientFileMonitoringEmailRecipients.length > 0 ?
                 client.clientFileMonitoringEmailRecipients.join(', ') :
                 null | blankValueDash }}
            </div>
          </div>
          <div class="col-6">
            <label>File Processing Enabled?</label>
            <div>{{ client.fileProcessingEnabled ? 'Yes' : 'No' }}</div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-6">
            <label>Users Assigned To Manage Client</label>
            <div>{{ assignedUsers() | blankValueDash }}</div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <h5>Entity Types</h5>

        <div class="row g-0">
          <div class="col-6">
            <div>{{ client.entityTypes | joinList | blankValueDash }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-3" *ngIf="canManageImplementation && clientSftpCredentials.length > 0">
      <h5>SFTP Information</h5>

      <div class="row g-0">
        <div class="col-3">
          <label>USERNAME</label>
          <div *ngFor="let credential of clientSftpCredentials">
            <span class="mt-3 me-1">{{ credential.username }}</span>
            <app-copy-to-clipboard [text]="credential.username" />
          </div>
        </div>

        <div class="col-4">
          <label>PASSWORD</label>
          <div class="row mt-0" *ngFor="let credential of clientSftpCredentials">
            <app-sensitive-string
              (valueAccessed)="onPasswordAccessed(credential.id)"
              [value]="credential.decryptedPassword"
              [hiddenTextLength]="credential.passwordLength" />
            <app-copy-to-clipboard
              (copyClicked)="onPasswordAccessed(credential.id)"
              [text]="credential.decryptedPassword" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #spinner> <mat-spinner /> </ng-template>
