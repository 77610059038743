import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export class ImageViewModalOptions {
  imageUrl = '';
  altText = '';
}

@Component({
  selector: 'app-image-view-modal',
  templateUrl: './image-view-modal.component.html',
  styleUrls: ['./image-view-modal.component.scss'],
})
export class ImageViewModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    public options: ImageViewModalOptions,
  ) {}

  onCloseClick() {
    this.activeModal.close();
  }

}
