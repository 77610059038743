<ng-container>
  <h6 class="card-title m-0 px-2 py-1 d-flex">
    <mat-icon>forum</mat-icon>
    <div class="ms-1 pt-1">Communications</div>
  </h6>
  <div class="card">
    <app-provider-call-logs [office]="office" />
  </div>
  <div class="card mt-2">
    <app-inbound-call-log [outboundFaxNumber]="office.fax" />
  </div>
  <div class="card mt-2">
    <div class="row ms-3 m-2">
      <h4>Outbound Faxes</h4>
    </div>
    <app-office-outbound-faxes [office]="office" />
  </div>
</ng-container>
