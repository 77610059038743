<div class="d-flex flex-row flex-wrap align-items-center col-auto m-1">
  <div class="col-auto">
    <label class="col-form-label">
      {{ label }}:
    </label>
  </div>

  <div class="col-auto ms-1">
    <input
      *ngIf="type === textOptionType.text"
      type="text"
      class="form-control form-control-sm text-filter text-filter-text"
      (change)="onChange()"
      [(ngModel)]="value" />

    <input
      *ngIf="type === textOptionType.integer"
      type="text"
      class="form-control form-control-sm text-filter text-filter-integer"
      (change)="onChange()"
      appExcludeInputPattern
      excludedPattern="\D"
      size="5"
      [(ngModel)]="value" />
  </div>
</div>

