<div class="col-auto m-1">
  <ng-select
    [placeholder]="label"
    [multiple]="multiple"
    [disabled]="disabled"
    (change)="onChange()"
    [items]="entityTypes"
    [(ngModel)]="selectedEntityTypes"
    dropdownPosition="bottom" />
</div>
