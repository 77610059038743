<div class="modal-header"><h4 class="modal-title" id="modal-basic-title">Security Checkpoint</h4></div>
<div class="modal-body">
  <p>R1 requires two-factor authentication for access to this application as an additional security step.</p>
  <p>
    Please enter your mobile phone number below. You will be asked to verify your phone number the next time you log in.
    Thank you!
  </p>
  <form id="form" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="mb-3">
      <input
        id="phoneNumber"
        name="phoneNumber"
        type="text"
        class="form-control"
        placeholder="Enter Mobile Phone Number"
        required
        #phoneNumber="ngModel"
        appPhoneNumber
        [(ngModel)]="formModel.phoneNumber"
        [ngClass]="{ 'is-invalid': f.submitted && phoneNumber.invalid }"
      />
      <div *ngIf="f.submitted && phoneNumber.invalid" class="invalid-feedback">
        <div *ngIf="phoneNumber.errors.required">Mobile phone number is required</div>
        <div *ngIf="!phoneNumber.errors.required && phoneNumber.errors.phoneNumberFormat">
          Mobile phone number is invalid
        </div>
      </div>
    </div>
    <div class="alert alert-danger text-danger mt-2 p-2" *ngIf="serverError">
      A server error occurred. Please try again or contact support.
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="text-start"><button type="submit" class="btn btn-primary" form="form">Submit</button></div>
</div>
