<h4>R1 340B Program Overview</h4>

<ng-container *ngIf="!loading; else: spinner">
  <div class="container-fluid border rounded bg-light mt-4 p-3">
    <div class="mb-2 client-name">{{client.fullName}}</div>

    <div class="row">
      <div *ngIf="client.logoUrl" class="col-1 mr-4">
        <a href="javascript:;" (click)="onLogoClick($event)">
          <img alt="client logo"
            [src]="client.logoUrl"
            class="img-fluid"
            style="max-height: 100px;" />
        </a>
      </div>

      <div class="col">
        <div class="row mb-4">
          <div class="col">
            <small>Entity Type</small>
            <div>{{client.entityTypes.join(", ")}}</div>
          </div>

          <div class="col">
            <small>Program Type</small>
            <div>{{programTypes}}</div>
          </div>

          <div class="col">
            <small>Go Live Date</small>
            <div>{{client.goLiveDate}}</div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <small>HRSA IDs</small>
            <div [innerHTML] = "client.hrsaIdStrings.join(', ')"></div>
          </div>

          <div class="col">
            <small>Client ID</small>
            <div>{{client.claimIdentifierPrefix}}</div>
          </div>

          <div class="col">
            <small>Live TPAs</small>
            <div [innerHTML] = "liveTpas.join('<br />')"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let tableType of ['primary', 'other']">
    <ng-container *ngIf="showTable(tableType)">
      <div class="config-table-title">{{ tableTitles[tableType] }}</div>
        <table class="table table-bordered">
          <thead class="table-dark">
            <tr>
              <th>Category</th>
              <th>Name</th>
              <th>Value</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let section of tableSections(tableType)">
              <tr *ngFor="let config of tableDisplayData[tableType][section]; let i = index">
                <td *ngIf="i === 0" [attr.rowspan]="tableDisplayData[tableType][section].length" class="section-cell align-middle bolder-top-border">
                  {{ section }}
                </td>
                <td [ngClass]="{'bolder-top-border': i === 0}">{{ config.portalName }}</td>
                <td class="nowrap" [innerHTML]="config.value" [ngClass]="{'bolder-top-border': i === 0}"></td>
                <td [ngClass]="{'bolder-top-border': i === 0}">{{ config.portalDescription }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
    </ng-container>
  </ng-container>

</ng-container>

<ng-template #spinner><mat-spinner diameter="30" style="margin: auto;" /></ng-template>
