import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssociatePatientDocumentComponent } from './components/associate-patient-document/associate-patient-document.component';
import { CaptureClientPrescriberComponent } from './components/capture-client-prescriber/capture-client-prescriber.component';
import { CaptureConsultNoteVerificationComponent } from './components/capture-consult-note-verification/capture-consult-note-verification.component';
import { CaptureDetailComponent } from './pages/capture-detail/capture-detail.component';
import { CaptureFaxComponent } from './pages/capture-fax/capture-fax.component';
import { CaptureHeaderComponent } from './components/capture-header/capture-header.component';
import { CaptureHistoryComponent } from './pages/capture-history/capture-history.component';
import { CaptureHistoryItemBodyComponent } from './components/capture-history-item-body/capture-history-item-body.component';
import { CaptureHistoryItemTitleComponent } from './components/capture-history-item-title/capture-history-item-title.component';
import { CaptureHistoryItemTypeBaseComponent } from './components/capture-history-item-type-base/capture-history-item-type-base.component';
import { CaptureHistoryItemTypeCreatedComponent } from './components/capture-history-item-type-created/capture-history-item-type-created.component';
import { CaptureHistoryItemTypeSnapshotCreatedComponent } from './components/capture-history-item-type-snapshot-created/capture-history-item-type-snapshot-created.component';
import { CaptureHistoryItemTypeTransitionedComponent } from './components/capture-history-item-type-transitioned/capture-history-item-type-transitioned.component';
import { CaptureHistoryItemTypeUnreversedComponent } from './components/capture-history-item-type-unreversed/capture-history-item-type-unreversed.component';
import { CaptureHistoryItemTypeUpdatedComponent } from './components/capture-history-item-type-updated/capture-history-item-type-updated.component';
import { CaptureHistoryItemTypeConsultNoteSubmittedComponent } from './components/capture-history-item-type-consult-note-submitted/capture-history-item-type-consult-note-submitted.component';
import { CaptureHistoryItemTypeVerifyingPatientAttachmentAssociatedComponent } from './components/capture-history-item-type-verifying-patient-attachment-associated/capture-history-item-type-verifying-patient-attachment-associated.component';
import { CaptureHistoryItemTypeReferralMatchVerificationSubmittedComponent } from './components/capture-history-item-type-referral-match-verification-submitted/capture-history-item-type-referral-match-verification-submitted.component';
import { CaptureHistoryItemTypeDeveloperChoreUpdatedComponent } from './components/capture-history-item-type-developer-chore-updated/capture-history-item-type-developer-chore-updated.component';
import { CaptureHistoryItemTypeClientChoreStartedComponent } from './components/capture-history-item-type-client-chore-started/capture-history-item-type-client-chore-started.component';
import { CaptureHistoryItemTypeClientChoreUpdatedComponent } from './components/capture-history-item-type-client-chore-updated/capture-history-item-type-client-chore-updated.component';
import { CaptureHistoryItemTypeClaimStatusUpdatedComponent } from './components/capture-history-item-type-claim-status-updated/capture-history-item-type-claim-status-updated.component';
import { CaptureHistoryNewComponent } from './pages/capture-history-new/capture-history-new.component';
import { CaptureNavComponent } from './components/capture-nav/capture-nav.component';
import { CaptureNextComponent } from './components/capture-next/capture-next.component';
import { CaptureReprocessComponent } from './components/capture-reprocess/capture-reprocess.component';
import { CaptureSearchComponent } from './components/capture-search/capture-search.component';
import { CaptureSnapshotActivityComponent } from './components/capture-snapshot-activity/capture-snapshot-activity.component';
import { CaptureSnapshotComponent } from './components/capture-snapshot/capture-snapshot.component';
import { CaptureVerificationComponent } from './components/capture-verification/capture-verification.component';
import { CaptureUserAuditComponent } from './components/capture-user-audit/capture-user-audit.component';
import { CeReviewClientConsultNoteTaskComponent } from './components/ce-review-client-consult-note-task/ce-review-client-consult-note-task.component';
import { CeReviewClientPrescriberTaskComponent } from './components/ce-review-client-prescriber-task/ce-review-client-prescriber-task.component';
import { CeReviewClientReferralTaskComponent } from './components/ce-review-client-referral-task/ce-review-client-referral-task.component';
import { CeReviewDetailsComponent } from './components/ce-review-details/ce-review-details.component';
import { CeReviewTaskContentComponent } from './components/ce-review-task-content/ce-review-task-content.component';
import { DrugDetailsComponent } from './components/drug-details/drug-details.component';
import { DrugMatchFormComponent } from './components/drug-match-form/drug-match-form.component';
import { LegacyReferralMatchFormComponent } from './components/legacy-referral-match-form/legacy-referral-match-form.component';
import { MarkPatientDocumentAsInvalidComponent } from './components/mark-patient-document-as-invalid/mark-patient-document-as-invalid.component';
import { NeedsPatientMatchApprovalComponent } from './components/needs-patient-match-approval/needs-patient-match-approval.component';
import { NeedsReferralMatchApprovalComponent } from './components/needs-referral-match-approval/needs-referral-match-approval.component';
import { PatientDetailsComponent } from './components/patient-details/patient-details.component';
import { PatientDocumentComponent } from './components/patient-document/patient-document.component';
import { PatientDocumentsComponent } from './components/patient-documents/patient-documents.component';
import { PatientDocumentSelectorComponent } from './components/patient-document-selector/patient-document-selector.component';
import { PatientMatchFormComponent } from './components/patient-match-form/patient-match-form.component';
import { PrescriberMatchFormComponent } from './components/prescriber-match-form/prescriber-match-form.component';
import { SpecialistEncounterFormComponent } from './components/specialist-encounter-form/specialist-encounter-form.component';
import { SpecialistEncounterVerificationComponent } from './components/specialist-encounter-verification/specialist-encounter-verification.component';
import { SpecialistEncounterVerificationsComponent } from './components/specialist-encounter-verifications/specialist-encounter-verifications.component';
import { ValidateDrugDetailsComponent } from './components/validate-drug-details/validate-drug-details.component';
import { ValidatePatientCareOwnershipComponent } from './components/validate-patient-care-ownership/validate-patient-care-ownership.component';
import { ValidatePatientMatchComponent } from './components/validate-patient-match/validate-patient-match.component';
import { ValidatePrescriberMatchComponent } from './components/validate-prescriber-match/validate-prescriber-match.component';
import { ValidateReferralComponent } from './components/validate-referral/validate-referral.component';
import { ValidateSpecialistEncounterComponent } from './components/validate-specialist-encounter/validate-specialist-encounter.component';
import { ValidatePrescriptionWrittenAtCeComponent } from './components/validate-prescription-written-at-ce/validate-prescription-written-at-ce.component';
import { VerificationHeadingComponent } from './components/verification-heading/verification-heading.component';
import { VerificationOutcomeFormComponent } from './components/verification-outcome-form/verification-outcome-form.component';
import { VerificationOutcomeStatusComponent } from './components/verification-outcome-status/verification-outcome-status.component';
import { VerificationValidationComponent } from './components/verification-validation/verification-validation.component';
import { ClientPrescriptionMatchesComponent } from './components/client-prescription-matches/client-prescription-matches.component';
import { SharedModule } from '../shared/shared.module';
import { ReferralMatchVerificationsClientDatesEditComponent } from './components/referral-match-verifications-client-dates-edit/referral-match-verifications-client-dates-edit.component';
import { ReferralMatchVerificationsStandardComponent } from './components/referral-match-verifications-standard/referral-match-verifications-standard.component';
import { ReferralMatchVerificationsStandardEditComponent } from './components/referral-match-verifications-standard-edit/referral-match-verifications-standard-edit.component';
import { ReferralMatchVerificationsClientComponent } from './components/referral-match-verifications-client/referral-match-verifications-client.component';
import { ReferralMatchFormComponent } from './components/referral-match-form/referral-match-form.component';
import { ManuallyUploadedConsultNotesComponent } from './components/manually-uploaded-consult-notes/manually-uploaded-consult-notes.component';
import { PatientPrescriptionsComponent } from './components/patient-prescriptions/patient-prescriptions.component';
import { CandidatePatientNameComponent } from './components/candidate-patient-name/candidate-patient-name.component';
import { DirectlySentConsultNotesComponent } from './components/directly-sent-consult-notes/directly-sent-consult-notes.component';
import { CandidateSourceComponent } from './components/candidate-source/candidate-source.component';
import { SendFaxComponent } from './components/send-fax/send-fax.component';
import { CandidateDrugComponent } from './components/candidate-drug/candidate-drug.component';
import { ReferralMatchVerificationsClientDatesComponent } from './components/referral-match-verifications-client-dates/referral-match-verifications-client-dates.component';
import { EditEncounterDatesModalComponent } from './components/edit-encounter-dates-modal/edit-encounter-dates-modal.component';
import { CaptureUnverifyComponent } from './components/capture-unverify/capture-unverify.component';
import { NpiCallLogsComponent } from './components/npi-call-logs/npi-call-logs.component';
import { EditReferralMatchVerificationsModalComponent } from './components/edit-referral-match-verifications-modal/edit-referral-match-verifications-modal.component';
import { ReferralMatchVerificationsClientEditComponent } from './components/referral-match-verifications-client-edit/referral-match-verifications-client-edit.component';
import { ReferralMatchVerificationsNotesComponent } from './components/referral-match-verifications-notes/referral-match-verifications-notes.component';
import { VerifiedInvalidReviewedComponent } from './components/verified-invalid-reviewed/verified-invalid-reviewed.component';
import { CaptureHistoryItemTypeClientNoteAddedComponent } from './components/capture-history-item-type-client-note-added/capture-history-item-type-client-note-added.component';
import { CaptureHistoryItemTypeClientNoteRemovedComponent } from './components/capture-history-item-type-client-note-removed/capture-history-item-type-client-note-removed.component';
import { ReferralMatchVerificationsEhrComponent } from './components/referral-match-verifications-ehr/referral-match-verifications-ehr.component';
import { ReferralMatchVerificationsEhrEditComponent } from './components/referral-match-verifications-ehr-edit/referral-match-verifications-ehr-edit.component';
import { EhrConsultNoteReferenceFormComponent } from 'app/captures/components/ehr-consult-note-reference-form/ehr-consult-note-reference-form.component';
import { EhrConsultNoteReferenceNewComponent } from 'app/captures/components/ehr-consult-note-reference-new/ehr-consult-note-reference-new.component';
import { EhrConsultNoteReferenceComponent } from 'app/captures/components/ehr-consult-note-reference/ehr-consult-note-reference.component';

@NgModule({
  declarations: [
    AssociatePatientDocumentComponent,
    CandidateDrugComponent,
    CandidatePatientNameComponent,
    CandidateSourceComponent,
    CaptureClientPrescriberComponent,
    CaptureConsultNoteVerificationComponent,
    CaptureDetailComponent,
    CaptureFaxComponent,
    CaptureHeaderComponent,
    CaptureHistoryComponent,
    CaptureHistoryItemBodyComponent,
    CaptureHistoryItemTitleComponent,
    CaptureHistoryItemTypeBaseComponent,
    CaptureHistoryItemTypeCreatedComponent,
    CaptureHistoryItemTypeSnapshotCreatedComponent,
    CaptureHistoryItemTypeTransitionedComponent,
    CaptureHistoryItemTypeUnreversedComponent,
    CaptureHistoryItemTypeUpdatedComponent,
    CaptureHistoryItemTypeConsultNoteSubmittedComponent,
    CaptureHistoryItemTypeVerifyingPatientAttachmentAssociatedComponent,
    CaptureHistoryItemTypeReferralMatchVerificationSubmittedComponent,
    CaptureHistoryItemTypeDeveloperChoreUpdatedComponent,
    CaptureHistoryItemTypeClientChoreStartedComponent,
    CaptureHistoryItemTypeClientChoreUpdatedComponent,
    CaptureHistoryItemTypeClaimStatusUpdatedComponent,
    CaptureHistoryNewComponent,
    CaptureNavComponent,
    CaptureNextComponent,
    CaptureReprocessComponent,
    CaptureSearchComponent,
    CaptureSnapshotActivityComponent,
    CaptureSnapshotComponent,
    CaptureVerificationComponent,
    CaptureUserAuditComponent,
    CeReviewClientConsultNoteTaskComponent,
    CeReviewClientConsultNoteTaskComponent,
    CeReviewClientPrescriberTaskComponent,
    CeReviewClientReferralTaskComponent,
    CeReviewDetailsComponent,
    CeReviewTaskContentComponent,
    ReferralMatchVerificationsClientDatesComponent,
    DirectlySentConsultNotesComponent,
    DrugDetailsComponent,
    DrugMatchFormComponent,
    LegacyReferralMatchFormComponent,
    ManuallyUploadedConsultNotesComponent,
    MarkPatientDocumentAsInvalidComponent,
    NeedsPatientMatchApprovalComponent,
    NeedsReferralMatchApprovalComponent,
    PatientDetailsComponent,
    PatientDocumentComponent,
    PatientDocumentsComponent,
    PatientDocumentSelectorComponent,
    PatientMatchFormComponent,
    PatientPrescriptionsComponent,
    ReferralMatchVerificationsStandardComponent,
    ReferralMatchVerificationsEhrComponent,
    ReferralMatchVerificationsEhrEditComponent,
    ReferralMatchVerificationsStandardEditComponent,
    ReferralMatchVerificationsClientComponent,
    PrescriberMatchFormComponent,
    ReferralMatchFormComponent,
    SendFaxComponent,
    SpecialistEncounterFormComponent,
    SpecialistEncounterVerificationComponent,
    SpecialistEncounterVerificationsComponent,
    ValidateDrugDetailsComponent,
    ValidatePatientCareOwnershipComponent,
    ValidatePatientMatchComponent,
    ValidatePrescriberMatchComponent,
    ValidateReferralComponent,
    ValidateSpecialistEncounterComponent,
    ValidatePrescriptionWrittenAtCeComponent,
    VerificationHeadingComponent,
    VerificationOutcomeFormComponent,
    VerificationOutcomeStatusComponent,
    VerificationValidationComponent,
    ClientPrescriptionMatchesComponent,
    EditEncounterDatesModalComponent,
    CaptureUnverifyComponent,
    NpiCallLogsComponent,
    EditReferralMatchVerificationsModalComponent,
    ReferralMatchVerificationsClientEditComponent,
    ReferralMatchVerificationsClientDatesEditComponent,
    ReferralMatchVerificationsNotesComponent,
    VerifiedInvalidReviewedComponent,
    CaptureHistoryItemTypeClientNoteAddedComponent,
    CaptureHistoryItemTypeClientNoteRemovedComponent,
    EhrConsultNoteReferenceFormComponent,
    EhrConsultNoteReferenceNewComponent,
    EhrConsultNoteReferenceComponent,
  ],
  exports: [
    AssociatePatientDocumentComponent,
    CandidateDrugComponent,
    CandidatePatientNameComponent,
    CandidateSourceComponent,
    CaptureClientPrescriberComponent,
    CaptureConsultNoteVerificationComponent,
    CaptureDetailComponent,
    CaptureFaxComponent,
    CaptureHeaderComponent,
    CaptureHistoryComponent,
    CaptureHistoryItemBodyComponent,
    CaptureHistoryItemTitleComponent,
    CaptureHistoryItemTypeBaseComponent,
    CaptureHistoryItemTypeCreatedComponent,
    CaptureHistoryItemTypeSnapshotCreatedComponent,
    CaptureHistoryItemTypeTransitionedComponent,
    CaptureHistoryItemTypeUnreversedComponent,
    CaptureHistoryItemTypeUpdatedComponent,
    CaptureHistoryItemTypeConsultNoteSubmittedComponent,
    CaptureHistoryItemTypeVerifyingPatientAttachmentAssociatedComponent,
    CaptureHistoryItemTypeReferralMatchVerificationSubmittedComponent,
    CaptureHistoryNewComponent,
    CaptureNavComponent,
    CaptureNextComponent,
    CaptureReprocessComponent,
    CaptureSearchComponent,
    CaptureSnapshotActivityComponent,
    CaptureSnapshotComponent,
    CaptureVerificationComponent,
    CaptureUserAuditComponent,
    CeReviewClientConsultNoteTaskComponent,
    CeReviewClientConsultNoteTaskComponent,
    CeReviewClientPrescriberTaskComponent,
    CeReviewClientReferralTaskComponent,
    CeReviewDetailsComponent,
    CeReviewTaskContentComponent,
    ReferralMatchVerificationsClientDatesComponent,
    DirectlySentConsultNotesComponent,
    DrugDetailsComponent,
    DrugMatchFormComponent,
    LegacyReferralMatchFormComponent,
    ManuallyUploadedConsultNotesComponent,
    MarkPatientDocumentAsInvalidComponent,
    NeedsPatientMatchApprovalComponent,
    NeedsReferralMatchApprovalComponent,
    PatientDetailsComponent,
    PatientDocumentComponent,
    PatientDocumentsComponent,
    PatientDocumentSelectorComponent,
    PatientMatchFormComponent,
    PatientPrescriptionsComponent,
    ReferralMatchVerificationsStandardComponent,
    ReferralMatchVerificationsEhrComponent,
    ReferralMatchVerificationsEhrEditComponent,
    ReferralMatchVerificationsStandardEditComponent,
    PrescriberMatchFormComponent,
    ReferralMatchFormComponent,
    SendFaxComponent,
    SpecialistEncounterFormComponent,
    SpecialistEncounterVerificationComponent,
    SpecialistEncounterVerificationsComponent,
    ValidateDrugDetailsComponent,
    ValidatePatientCareOwnershipComponent,
    ValidatePatientMatchComponent,
    ValidatePrescriberMatchComponent,
    ValidateReferralComponent,
    ValidateSpecialistEncounterComponent,
    ValidatePrescriptionWrittenAtCeComponent,
    VerificationHeadingComponent,
    VerificationOutcomeFormComponent,
    VerificationOutcomeStatusComponent,
    VerificationValidationComponent,
    ClientPrescriptionMatchesComponent,
    NpiCallLogsComponent,
    EditReferralMatchVerificationsModalComponent,
    ReferralMatchVerificationsClientEditComponent,
    ReferralMatchVerificationsClientDatesEditComponent,
    ReferralMatchVerificationsNotesComponent,
    VerifiedInvalidReviewedComponent,
    CaptureHistoryItemTypeClientNoteAddedComponent,
    CaptureHistoryItemTypeClientNoteRemovedComponent,
    EhrConsultNoteReferenceFormComponent,
    EhrConsultNoteReferenceNewComponent,
    EhrConsultNoteReferenceComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class CapturesModule { }
