<ng-container *ngIf="!loading; else: spinner">
  <div [class.compact]="compactClientView">
    <table class="table table-sm"
           *ngIf="verificationsOrOptions.length > 0; else: noReferrals">
      <thead>
        <tr>
          <th>
            Referral Date
          </th>
          <th>
            Referral Type
          </th>
          <th>
            Referral Notes
          </th>
          <th>
            Specialty
          </th>
          <th>
            Prescriber
          </th>
          <th *ngIf="!compactClientView">
            Additional Details
          </th>
          <th *ngIf="!compactClientView">
            Task ID
          </th>
          <th *ngIf="!compactClientView">
            Submitted By
          </th>
          <th *ngIf="!compactClientView">
            Submitted At
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of verificationsOrOptions; let i = index"
            [class.selected]="option(item)?.selected"
            class="referral">
          <td>
            <div *ngIf="option(item)"
                 class="form-check"
                 [ngClass]="{ disabled: option(item).disabled }">
              <input
                type="checkbox"
                class="form-check-input"
                [id]="'clientTaskRmv' + i"
                [(ngModel)]="option(item).selected"
                [disabled]="option(item).disabled"
                (change)="onReferralVerificationChange(option(item))">
              <label class="form-check-label" [for]="'clientTaskRmv' + i">
                {{ verification(item).referralDate | date: 'MM/dd/yyyy' }}
              </label>
            </div>

            <div *ngIf="!option(item)">
              {{ verification(item).referralDate | date: 'MM/dd/yyyy' }}
            </div>
          </td>
          <td>
            {{ verification(item).clientReferralTaskSubmission.choiceReasons | referralVerificationReasonShortNames | joinList }}
          </td>
          <td>
            {{ verification(item).clientReferralTaskSubmission.choiceOtherReasonNote | blankValueDash }}
          </td>
          <td>
            {{ verification(item).referralSpecialty | blankValueDash }}
          </td>
          <td>
            <div>{{ verification(item).prescriber.fullName }}</div>
            <div>NPI: {{ verification(item).prescriber.npi }}</div>
            <div>{{ verification(item).prescriber.displaySpecialty }}</div>
          </td>
          <td *ngIf="!compactClientView">
            {{ verification(item).clientReferralTaskSubmission.notes | blankValueDash }}
          </td>
          <td *ngIf="!compactClientView">
            {{ verification(item).clientReferralTaskSubmission.taskId }}
          </td>
          <td *ngIf="!compactClientView">
            {{ verification(item).clientReferralTaskSubmission.submittedBy.fullName }}
          </td>
          <td *ngIf="!compactClientView">
            <div>{{ verification(item).clientReferralTaskSubmission.submittedAt | date: 'MM/dd/yyyy' }}</div>
            <div>{{ verification(item).clientReferralTaskSubmission.submittedAt | date: 'h:mm a' }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="mt-1 mb-2 ms-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noReferrals>
  <div class="my-2">Patient has no applicable referral tasks.</div>
</ng-template>
