import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { keyedStatusOptions, filterProviderStatusOptions } from 'app/core/options/provider-office-status.opts';
import { mapObjectToFormGroup, applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';
import { ProviderOfficeService } from 'app/core/services/provider-office.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-provider-office-fax-status',
  templateUrl: './provider-office-fax-status.component.html',
  styleUrls: ['./provider-office-fax-status.component.scss'],
})
export class ProviderOfficeFaxStatusComponent implements OnInit {
  @Input() providerOffice: ProviderOffice;
  @Input() clearReviewStatus = false;

  @Output() providerOfficeChanged = new EventEmitter<ProviderOffice>();

  formGroup: FormGroup;
  editing = false;

  keyedStatusOptions = keyedStatusOptions;
  providerOfficeStatusOptions;

  constructor(
    private formBuilder: FormBuilder,
    private providerOfficeService: ProviderOfficeService,
  ) {}

  ngOnInit() {
    this.initFormGroup();
  }

  onEdit($event: MouseEvent) {
    $event.preventDefault();
    this.editing = true;
  }

  onCancelEdit($event: MouseEvent) {
    $event.preventDefault();
    this.editing = false;
    // resets the drop down to actual value on
    // providerOffice
    this.initFormGroup();
  }

  onSubmit() {
    const attributes: any = { status: this.formGroup.value.status };

    if (this.clearReviewStatus) {
      attributes.reviewStatus = null;
    }

    this.providerOfficeService.update(this.providerOffice, attributes).subscribe(
      providerOffice => this.handleSaveSuccess(providerOffice),
      error => this.handleSaveFailure(error)
    )
  }

  private handleSaveSuccess(providerOffice: ProviderOffice) {
    this.providerOffice = providerOffice;
    this.editing = false;
    this.initFormGroup();

    this.providerOfficeChanged.emit(providerOffice);
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    if (response.status === 400) {
      applyServerErrorsToFormGroup(response, this.formGroup);
    } else {
      console.error(response);
    }
  }

  initFormGroup() {
    this.formGroup = mapObjectToFormGroup(this.formBuilder, this.providerOffice, [
      'status'
    ]);

    this.providerOfficeStatusOptions = filterProviderStatusOptions(this.providerOffice.status);
  }
}
