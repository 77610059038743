<div id="pdfFileViewer" class="wrapper" [ngStyle]="{ height: height }">
  <div class="toolbar bg-dark d-flex align-items-center">
    <div class="col-auto icon-buttons flex-grow-1">
      <button (click)="onZoomClick(0.1)" title="Zoom In" class="btn btn-sm text-light" type="button">
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button (click)="onZoomClick(-0.1)" title="Zoom Out" class="btn btn-sm text-light" type="button">
        <mat-icon>zoom_out</mat-icon>
      </button>
      <ng-container *ngIf="!hideRotationControls">
        <button (click)="onRotateClick(-90)" title="Rotate Left" class="btn btn-sm text-light" type="button">
          <mat-icon>rotate_left</mat-icon>
        </button>
        <button (click)="onRotateClick(90)" title="Rotate Right" class="btn btn-sm text-light" type="button">
          <mat-icon>rotate_right</mat-icon>
        </button>
      </ng-container>
    </div>
    <div class="col-auto" *ngIf="dirty || rotating">
      <button type="button" class="btn btn-sm btn-primary" [disabled]="rotating" (click)="onSaveClick()">
        <span [hidden]="rotating">Save</span>
        <span [hidden]="!rotating">
          <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" /> Saving
        </span>
      </button>
      <button type="button" class="btn btn-sm btn-secondary" (click)="onCancelClick()">Cancel</button>
    </div>
    <div class="col-auto me-1"><ng-content /></div>
  </div>
  <div class="pdf-content">
    <pdf-viewer
      [src]="pdfFile.url"
      [page]="initialPage"
      [rotation]="rotation"
      [fit-to-page]="fitToPage"
      (after-load-complete)="onPdfLoadComplete($event)"
      (pageChange)="onPdfPageChange($event)"
      [zoom]="zoom"
      [show-all]="showAll"
      [external-link-target]="'blank'"
      [render-text]="renderText"
      [autoresize]="autoResize"
      [stick-to-page]="stickToPage"
      [original-size]="originalSize"
    />
  </div>
</div>
