import { Component, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { UploadService } from '../../../core/services/upload.service';
import { Upload } from '../../../core/models/upload.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  file: File = null;

  @Input() disabled = false;
  @Input() uploadButtonDisabled = false;
  @Input() uploadButtonLabel = 'Upload';
  @Input() fileType = '.pdf';
  @Input() hideLabel = false;
  @Input() directUploadPath = null;

  @ViewChild('fileInput', { static: true })
  fileInput: ElementRef;

  @Output() fileSelected = new EventEmitter<File>();
  @Output() uploadStart = new EventEmitter();
  @Output() uploadComplete = new EventEmitter<Upload>();
  @Output() uploadError = new EventEmitter<Error>();
  @Output() uploadCancel = new EventEmitter();

  constructor(private uploadService: UploadService) {}

  onFileChange(event): void {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      this.file = fileInput.files[0];
      this.fileSelected.emit(this.file);
    }
  }

  onCancelClick() {
    this.uploadCancel.emit();
    this.clearFile();
  }

  onUploadSubmit() {
    this.uploadStart.emit();
    this.uploadService.upload(this.file, this.directUploadPath).subscribe(
      upload => {
        this.uploadComplete.emit(upload);
        this.clearFile();
      },
      (error: HttpErrorResponse) => {
        this.uploadError.emit(error);
      }
    );
  }

  clearFile() {
    this.file = null;

    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }
}
