<nav class="nav nav-pills">
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/patients/{{patient.id}}"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    Patient Details
  </a>
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/patients/{{patient.id}}/captures"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    Captures
  </a>
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/patients/{{patient.id}}/referrals"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    Referrals
  </a>
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/patients/{{patient.id}}/communications"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    Communications
  </a>
</nav>
