import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'jobStatus',
})
export class JobStatusPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      value = value.replace(new RegExp('_', 'g'), ' ');
      value = new TitleCasePipe().transform(value);
      return value;
    } else {
      return value;
    }
  }
}
