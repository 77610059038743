<div class="row">
  <div class="col-6">
    <table class="table table-sm">
      <thead>
        <th>ID</th>
        <th>Prescriber</th>
        <th>Start</th>
        <th>End</th>
        <th class="text-end">Rx-Written Date</th>
      </thead>
      <tbody>
        <td>{{ autoVerifyPrescriber.id }}</td>
        <td>{{ autoVerifyPrescriber.prescriberDisplayNameWithNpi }}</td>
        <td>{{ autoVerifyPrescriber.startDate | date: 'MM/dd/yyyy' }}</td>
        <td>{{ autoVerifyPrescriber.endDate | date: 'MM/dd/yyyy' }}</td>
        <td class="text-end">{{ capture.candidate.writtenDate | date: 'MM/dd/yyyy' }}</td>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="autoVerifyPrescriber" class="row">
  <div class="col-6">
    <p class="alert alert-success" role="alert">Prescriber was on client prescriber list when the prescription was written</p>
  </div>
</div>
