<h5>Referrals</h5>

<ng-container *ngIf="!loadingReferralMatchVerifications; else: spinner">
  <div *ngIf="anyReferralMatchVerifications; else: noPatientReferrals">
    <p>The referrals have been provided as evidence for these prescriptions</p>
  </div>

  <div *ngIf="referrals.length > 0">
    <strong>Patient Referrals From EHR Feeds</strong>
    <app-referrals-standard-listing
      [eligibleReferrals]="referrals"
      [compactClientView]="true" />
  </div>

  <div *ngIf="ehrReferralVerifications.length > 0">
    <strong>Referral Evidence Found in EHR</strong>
    <app-referrals-ehr-listing
      [ehrReferralVerifications]="ehrReferralVerifications"
      [compactClientView]="true" />
  </div>

  <div *ngIf="clientReferralVerifications.length > 0">
    <strong>Patient Referrals From Client Tasks</strong>
    <app-referrals-client-listing
      [clientReferralVerifications]="clientReferralVerifications"
      [compactClientView]="true" />
  </div>

  <ng-container *ngIf="clientDateReferralMatchVerifications.length > 0">
    <hr />
    <p>Client has indicated they have sufficient referral documentation.</p>
    <strong>DOCUMENTATION DATE(S)</strong>
    <div *ngIf="clientReferralVerificationDates; else: noClientReferralVerifications">
      {{ clientReferralVerificationDates }}
    </div>
  </ng-container>
</ng-container>

<ng-template #noPatientReferrals> <p>There are no referrals.</p> </ng-template>
<ng-template #noClientReferralVerifications> <p>No dates provided.</p> </ng-template>
<ng-template #spinner> <mat-spinner [diameter]="30" /> </ng-template>
