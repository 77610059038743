<h3>Processing Queues</h3>
<div *ngIf="sqsQueueSummary !== undefined; else: spinner">
  <table class="table table-bordered">
    <thead class="table-dark">
      <tr>
        <th>FULL FILE QUEUE COUNT</th>
        <th>FILE PART QUEUE COUNT</th>
        <th>REFERRAL QUEUE COUNT</th>
        <th>ENCOUNTER QUEUE COUNT</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{sqsQueueSummary.fullFileCount}}</td>
        <td>{{sqsQueueSummary.filePartsCount}}</td>
        <td>{{sqsQueueSummary.referralCount}}</td>
        <td>{{sqsQueueSummary.encounterCount}}</td>
      </tr>
    </tbody>
  </table>
</div>
<h3>File Monitoring</h3>
<div class="mb-3">
  <form novalidate (keydown.enter)="onFormEnter($event)">
    <div class="row g-0 flex-row align-items-end">
      <div class="col-4">
        <div class="my-0 py-0 me-2">
          <label class="my-0"><strong>Client</strong></label>
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="humanizedName"
            name="clientIds"
            placeholder="Filter by Client"
            [multiple]="true"
            [items]="clients"
            (remove)="onClientChange()"
            (change)="onClientChange()"
            [(ngModel)]="formModel.selectedClientIds"
          />
        </div>
      </div>
      <div class="col-2">
        <div class="my-0 py-0 me-1">
          <label class="my-0"><strong>Filename</strong></label>
          <div class="input-group filename">
            <input
              [(ngModel)]="formModel.filename"
              name="filename"
              class="form-control pe-0"
              [ngClass]="{ 'has-value': hasFilename }"
              (keydown)="onFilenameKeyDown($event)"
              (keyup)="onFilenameKeyUp()"
              placeholder="my_referrals.csv"
            />
            <div class="input-group-append" *ngIf="hasFilename">
              <button class="btn btn-outline-secondary py-0" type="button" (click)="onClearFilenameClick()">
                <span class="btn-label">×</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        <button class="btn btn-info ms-1 mt-0 clear-button" (click)="onClearAllClick($event)">Clear All</button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="!loading; else: spinner">
  <div *ngIf="clientFiles.length > 0; else: noFiles">
    <app-pagination-header [paging]="paging" [label]="'clientFiles'" (pageSizeChange)="onPageSizeChange($event)" />
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th class="text-nowrap">ID</th>
          <th>CREATED</th>
          <th>CLIENT</th>
          <th class="text-nowrap">FILENAME</th>
          <th class="text-nowrap">TYPE</th>
          <th class="text-nowrap">ROWS</th>
          <th class="text-nowrap">ERRS</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let clientFile of clientFiles">
          <tr>
            <td>{{clientFile.id}}</td>
            <td>{{clientFile.createdAt | date: 'MM/dd/yyyy h:mm a' }}</td>
            <td>
              {{clientFile.client.name}}
              <div *ngIf="!clientFile.client.fileProcessingEnabled" class="processing-disabled text-danger">processing disabled</div>
              <div *ngIf="clientFile.client.claimIdentifierPrefix?.length === 0" class="processing-disabled text-danger">claim indentifier prefix not set</div>
            </td>
            <td>{{clientFile.fileName}}</td>
            <td>{{clientFile.kind}}</td>
            <td class="text-center">{{clientFile.totalRows}}</td>
            <td *ngIf="clientFile.errorCount > 0" class="text-center">
              <a href="#" (click)="onDownloadErrorsClick($event, clientFile)">
                {{clientFile.errorCount}}
              </a>
            </td>
            <td *ngIf="clientFile.errorCount === 0" class="text-center">
              {{clientFile.errorCount}}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </div>
</div>
<ng-template #noFiles>
  <section>
    <p>
      There are no client files. Try modifying the filters or
      <strong
      ><u><a href="#" (click)="onClearAllClick($event)">clearing them</a></u></strong
                                                                               >.
    </p>
  </section>
</ng-template>
<ng-template #spinner> <mat-spinner diameter="30" /> </ng-template>

