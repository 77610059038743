<div
  class="dates"
  *ngIf="specialistEncounterVerifications && specialistEncounterVerifications.length > 0; else: noEncounterDates"
>
  <ng-container *ngFor="let verification of specialistEncounterVerifications; let i = index">
    <app-specialist-encounter-verification
      [capture]="capture"
      [patientAttachment]="patientAttachment"
      [verification]="verification"
      [position]="i"
      (changeVerification)="onChange($event)"
      (removeVerification)="onRemove($event)"
    />
  </ng-container>
</div>
<div class="my-2">
  <button [disabled]="addDisabled" class="btn btn-sm btn-primary" (click)="onAddClick($event)">
    <fa-icon class="text-white" [icon]="['fas', 'plus-circle']" /> Add Encounter Date
  </button>
  <a *ngIf="canEditDates" href="#" class="my-2 float-end" (click)="onEditClick($event)">
    Edit
  </a>
</div>
<ng-template #noEncounterDates> <div class="my-2">No specialist encounter dates found.</div> </ng-template>
