import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excludeBlanks',
})
export class ExcludeBlanksPipe implements PipeTransform {
  transform(input: string[]): Array<string> {
    return input.filter(s => !!s);
  }
}
