<div class="modal-content">
  <form [formGroup]="formGroup" (submit)="onSubmit($event)">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add New Provider</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Enter NPI to create a new provider:
      </div>

      <input
        type="text"
        class="form-control mb-2"
        formControlName="npi" />

      <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">
        Create Provider
      </button>

      <div *ngIf="errorMessage">
        <hr />

        <div class="alert alert-warning mt-2 mb-0">
          <fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning me-2" />
          {{ errorMessage }}
        </div>
      </div>

      <div *ngIf="existingProvider" class="existing-provider">
        <hr />

        <div class="alert alert-warning">
          <fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning me-2" />
          A provider already exists for this NPI
        </div>

        <div>
          <a href="javascript:;" (click)="onExistingProviderClick($event)">
            {{ existingProvider.displayName }}
          </a>
          <div>NPI: {{ existingProvider.npi }}</div>
          <div>NPI Review Status: {{ keyedStatusOptions[existingProvider.status].display }}</div>
        </div>
      </div>
    </div>
  </form>
</div>
