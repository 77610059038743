<div class="col-10 px-0">
  <h3>{{ client?.name }} Configuration Change Log</h3>
</div>
<ng-container *ngIf="!loading else: spinner">
  <div class="col-10">
    <app-pagination-header [paging]="paging" [label]="'audits'" (pageSizeChange)="onPageSizeChange($event)" />
  </div>

  <div class="audit-list-wrapper col-12">
    <table class="audit-list table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>Changed At</th>
          <th>Action</th>
          <th>Option</th>
          <th>New Value</th>
          <th>Previous Value</th>
          <th>User</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let parsedAudit of parsedAudits">
          <tr>
            <td>{{ parsedAudit.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
            <td>{{ parsedAudit.action }}</td>
            <td>{{ parsedAudit.optionName }}</td>
            <td>
              <div *ngIf="parsedAudit.changes.value[1]"
                [innerHTML]="parsedAudit.changes.value[1]">
              </div>
              <div *ngIf="parsedAudit.changes.startAt[1]">
                Start at {{ parsedAudit.changes.startAt[1] | date: 'M/d/yyyy h:mm a'}}
              </div>
              <div *ngIf="parsedAudit.changes.endAt[1]">
                End at {{ parsedAudit.changes.endAt[1] | date: 'M/d/yyyy h:mm a'}}
              </div>
            </td>
            <td>
              <div *ngIf="parsedAudit.changes.value[0]"
                [innerHTML]="parsedAudit.changes.value[0]">
              </div>
              <div *ngIf="parsedAudit.changes.startAt[0]">
                Start at {{ parsedAudit.changes.startAt[0] | date: 'M/d/yyyy h:mm a'}}
              </div>
              <div *ngIf="parsedAudit.changes.endAt[0]">
                End at {{ parsedAudit.changes.endAt[0] | date: 'M/d/yyyy h:mm a'}}
              </div>
            </td>
            <td>{{ parsedAudit.userEmail }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </div>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>
