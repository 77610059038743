<form #form="ngForm" (submit)="onSubmit($event, form)">
  <div class="modal-header px-3 py-1">
    <h5 id="modal-basic-title" class="modal-title text-white">Rename Import</h5>
  </div>
  <div class="modal-body">
    <p style="max-width: 100%" class="wrap-with-hyphens">
      Renaming import <strong>{{ iccImport.name }}</strong>.
    </p>

    <p>
      Name:

      <input type="text"
             [ngClass]="invalidCss(form, 'name')"
             class="form-control"
             name="name"
             maxlength="255"
             appAutoSelectAll
             [(ngModel)]="formModel.name" />

      <app-form-control-server-errors [form]="form" controlName="name" />
    </p>

    <p>
      Import names must be unique for client and import type.
    </p>
  </div>
  <div class="modal-footer">
    <div class="text-start"><button type="submit" class="btn btn-primary" form="form" (click)="onSubmit($event, form)">Save</button></div>
    <div class="text-end"><button type="button" class="btn btn-secondary" (click)="onCancelClick()">Cancel</button></div>
  </div>
</form>
