<h3>Add New Office</h3>

<form [formGroup]="formGroup" (submit)="onSubmit()" #form="ngForm">
  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <label>Office Name (Required)</label>
        <input
          class="form-control"
          formControlName="name"
          appFormControlValidityClass>
        <app-form-control-server-errors
          controlName="name" />
      </div>
    </div>
    <div class="col-4">
      <div class="mb-3">
        <label>Office Alternate Name</label>
        <input
          class="form-control"
          formControlName="aka"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="aka" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <label>Address Line 1</label>
        <input
          class="form-control"
          formControlName="address1"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="address1" />
      </div>
    </div>
    <div class="col-4">
      <div class="mb-3">
        <label>Address Line 2</label>
        <input
          class="form-control"
          formControlName="address2"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="address2" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <label>City</label>
        <input
          class="form-control"
          formControlName="city"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="city" />
      </div>
    </div>
    <div class="col-2">
      <div class="mb-3">
        <label>State</label>
        <ng-select
          bindValue="value"
          bindLabel="display"
          formControlName="state"
          [items]="stateOptions"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="state" />
      </div>
    </div>
    <div class="col-2">
      <div class="mb-3">
        <label>Zip Code</label>
        <input
          class="form-control"
          formControlName="zip"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="zip" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="mb-3">
        <label>Phone Number</label>
        <input
          class="form-control"
          formControlName="phone"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="phone" />
      </div>
    </div>
    <div class="col-1">
      <div class="mb-3">
        <label>Ext.</label>
        <input
          class="form-control"
          formControlName="phoneExtension"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="phoneExtension" />
      </div>
    </div>
    <div class="col-4">
      <div class="mb-3">
        <label>Fax Number (Required)</label>
        <input
          class="form-control"
          formControlName="fax"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="fax" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <label>Website</label>
        <input
          class="form-control"
          formControlName="website"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="website" />
      </div>
    </div>
    <div class="col-4">
      <div class="mb-3">
        <label>Communication Method</label>
        <ng-select
          formControlName="communicationMethod"
          [items]="communicationMethods"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="communicationMethod" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <label>Office Status (Required)</label>
        <ng-select
          bindValue="value"
          bindLabel="display"
          formControlName="status"
          [items]="officeStatusOptions"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="status" />
      </div>
    </div>
    <div class="col-4">
      <div class="mb-3">
        <label>Employed By</label>
        <ng-select
          bindValue="id"
          bindLabel="humanizedName"
          formControlName="employedByClientIds"
          [multiple]="true"
          [items]="clients"
          appFormControlValidityClass />
        <app-form-control-server-errors
          controlName="employedByClientIds" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      <div class="mb-3">
        <label>Note</label>
        <textarea
          class="form-control"
          formControlName="note"
          rows="5"
          appFormControlValidityClass>
        </textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8 text-end actions">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="form.submitted && formGroup.invalid || saving">
        Save
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onCancelClick()"
        [disabled]="saving">
        Cancel
      </button>
    </div>
  </div>

</form>
