<div *ngIf="patientAttachment.ocrResult?.content" class="card m-1">
  <div class="card-title m-0 pt-1 px-2">
    <div class="row">
      <div class="col">
        <h6 class="pt-1">Consult Note Text (OCR)</h6>
      </div>
      <div class="col-sm-auto text-end">
        <h6 class="pt-1">{{ hintText }}</h6>
      </div>
    </div>
  </div>
  <div class="card-body p-1">
    <pre>{{ patientAttachment.ocrResult.content }}</pre>
  </div>
</div>
