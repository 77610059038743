<app-import-nav />
<h5 class="mt-3">Import Claims</h5>

<div class="g-0" [hidden]="uploading">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="hasError">
        There was a problem uploading the file.
        <ul class="mt-2">
          <li *ngFor="let error of errors">{{ error }}</li>
        </ul>
      </div>
    </div>
    <div class="col-2">
      <div>
        <label class="my-0"><strong>Client</strong></label>
        <div class="input-group">
          <select class="form-select" [(ngModel)]="formModel.clientId" name="clientId">
            <option value="" [selected]="true"> -- Choose Client -- </option>
            <option *ngFor="let client of clients" [value]="client.id"> {{ client.humanizedName }} </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div>
        <label class="my-0"><strong>TPA</strong></label>
        <div class="input-group">
          <select class="form-select"
                  [(ngModel)]="formModel.thirdPartyAdministerName"
                  (change)="onTpaSelected()"
                  name="thirdPartyAdministerName">
            <option value="" [selected]="true"> -- Choose TPA -- </option>
            <option *ngFor="let thirdPartyAdminister of thirdPartyAdministerNames">
              {{ thirdPartyAdminister }}
            </option>
          </select>
        </div>
      </div>
    </div>
        <div class="col-4">
      <div>
        <app-file-upload
          [fileType]="'.csv'"
          [uploadButtonDisabled]="uploadButtonDisabled"
          (fileSelected)="onFileSelected($event)"
          (uploadStart)="onUploadStart()"
          (uploadCancel)="onUploadCancel()"
          (uploadError)="onUploadError()"
          (uploadComplete)="onUploadComplete($event)" />
      </div>
    </div>
  </div>
</div>
<div class="row g-0" [hidden]="!uploading">
  <div class="col-12">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
    Uploading File...
  </div>
</div>

<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 ps-2">
    <li *ngIf="importFields" class="py-2">
      Possible fields
      <ul class="ps-2">
        <li *ngFor="let importField of importFields">
          <code>{{ importField.fieldName }}</code> <span>{{ importField.description }}</span>
        </li>
      </ul>
    </li>
    <li *ngIf="!importFields" class="py-2">
      Select a TPA to see the fields
    </li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
