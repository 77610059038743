<div class="row">
  <div class="col-12">
    <h3>Office Fax Status Review Required</h3>

    <form [formGroup]="formGroup" novalidate>
      <div class="container-fluid p-2 border bg-light mb-2">
        <div class="row">
          <div class="col-2 pe-1">
            <div class="mb-3" formGroupName="filters">
              <label>
                <strong>Review Status:</strong>
              </label>
              <ng-select
                  class="w-100"
                  name="reviewStatus"
                  bindValue="value"
                  bindLabel="display"
                  [multiple]="true"
                  [items]="filterOptions"
                  [clearable]="true"
                  [searchable]="false"
                  placeholder="Filter by Status"
                  formControlName="reviewStatus" />
            </div>
          </div>

          <div class="col-2 pe-1">
            <div class="mb-3">
              <label>
                <strong>Sort By:</strong>
              </label>
              <ng-select
                  class="w-100"
                  name="sortBy"
                  bindValue="value"
                  bindLabel="display"
                  [multiple]="false"
                  [items]="sortByOptions"
                  [clearable]="false"
                  [searchable]="false"
                  formControlName="sort" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-2" *ngIf="!loading; else: spinner">
  <app-pagination-header
    [paging]="paging"
    [label]="'reviews'"
    (pageSizeChange)="onPageSizeChange($event)"
    [includeResetFiltersButton]="true"
    (resetFiltersClick)="onResetFiltersClick()" />

  <div class="mt-2" *ngIf="statusReviews.length > 0; else: noReviews">

    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>Office Information</th>
          <th>Current Office Status</th>
          <th>Office Status Review Type</th>
          <th>Review Created On</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let statusReview of statusReviews">
          <td>
            <app-office [office]="statusReview" />
          </td>
          <td>
            <app-office-fax-status
              [office]="statusReview"
              (officeChanged)="onOfficeChanged($event)"
              [clearReviewStatus]="true" />
          </td>
          <td>
            {{ keyedOfficeStatusOptions[statusReview.reviewStatus]?.display | blankValueDash }}
            <div *ngIf="statusReview.reviewInboundCommunication?.type === 'InboundFax'">
              <a href="/capture-admin/communications/inbound-faxes/{{ statusReview.reviewInboundCommunication.id }}"
                 target="_blank">
                Inbound Fax: {{ statusReview.reviewInboundCommunication.id }}
              </a>
            </div>
            <div *ngIf="statusReview.reviewInboundCommunication?.type === 'InboundCall'">
              <a href="/capture-admin/communications/inbound-calls/{{ statusReview.reviewInboundCommunication.id }}"
                 target="_blank">
                Inbound Call: {{ statusReview.reviewInboundCommunication.id }}
              </a>
            </div>
          </td>
          <td>{{ statusReview.reviewStatusAddedAt | date: 'M/d/yyyy' | blankValueDash }}</td>
          <td class="row-actions">
            <a href="javascript:;"
               class="clear-review"
               (click)="onClearReviewClick(statusReview)">
             Clear Review
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange()" />
    </div>
  </div>
</div>

<ng-template #spinner>
  <div *ngIf="loading" class="ms-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noReviews>
  <p class="ms-1">No offices requiring review.</p>
</ng-template>
