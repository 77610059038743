<div>
  <section>
    <div class="row mx-0 mb-2">
      <div class="col-10 px-0">
        <h3>
          Clients
          <span *ngIf="!loading">
            - {{ clients.length | number: '' }}
          </span>
        </h3>
      </div>
      <div class="col-2 px-0 text-end">
        <a *ngIf="canManageClients"
           class="nav-link"
           routerLink="/capture-admin/client-management/clients/new" routerLinkActive="active" class="btn btn-primary">
          Add New Client
        </a>
      </div>
    </div>

    <div *ngIf="!loading; else: spinner">
      <app-client-list [clients]="clients" [canManageClients]="canManageClients" />
    </div>
  </section>
</div>
<ng-template #spinner> <mat-spinner diameter="30" /> </ng-template>
