<app-client-tasks-filter
  [filters]="filters"
  (filtersChange)="filtersChange($event)"
  [loading]="loading"
  [exportUrl]="exportUrl"
  [enableExport]="tasks.length > 0"
  [taskStatuses]="taskStatuses"
  [specialties]="specialties"
  [manufacturers]="manufacturers"
  [sources]="sources"
  [useEstimatedValueRanks]="true"
  />
<div class="mt-2" *ngIf="!loading; else: spinner">
  <section *ngIf="tasks.length > 0; else: noTasks">
    <app-pagination-header [paging]="paging" [label]="'tasks'" (pageSizeChange)="onPageSizeChange($event)" />
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th app-sortable-column="patientFullName" [sort]="sort" (sortChange)="onSortChange($event)">PATIENT</th>
          <th app-sortable-column="prescriberFullName" [sort]="sort" (sortChange)="onSortChange($event)">PRESCRIBER</th>
          <th app-sortable-column="status" [sort]="sort" (sortChange)="onSortChange($event)" class="text-nowrap">
            STATUS
          </th>
          <th app-sortable-column="source" [sort]="sort" (sortChange)="onSortChange($event)" class="text-nowrap">
            SOURCE
          </th>
          <th app-sortable-column="lastViewedAt" [sort]="sort" (sortChange)="onSortChange($event)" class="text-end">
            LAST VIEWED
          </th>
          <th
            app-sortable-column="percentComplete"
            [sort]="sort"
            (sortChange)="onSortChange($event)"
            class="text-end"
          >
            PRESCRIPTIONS VERIFIED
          </th>
          <th app-sortable-column="estimatedValue" [sort]="sort" (sortChange)="onSortChange($event)">
            ESTIMATED VALUE
          </th>
          <th app-sortable-column="patientEstimatedValue" [sort]="sort" (sortChange)="onSortChange($event)">
            PATIENT ESTIMATED VALUE
          </th>
          <th
            class="text-end"
            app-sortable-column="nextClaimExpirationDate"
            [sort]="sort"
            (sortChange)="onSortChange($event)"
          >
            EXPIRES ON
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let task of tasks; let i = index">
          <tr
            app-consult-note-task-list-row
            class="consult-note-task-list-row"
            [task]="task"
            [position]="i"
            (viewTask)="onViewTask(i)"
          ></tr>
        </ng-container>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noTasks>
  <section><p>There are no tasks. Try modifying the filters.</p></section>
</ng-template>
<ng-template #disableExport>
  <button type="button" class="btn btn-primary" [disabled]="true">Export</button>
</ng-template>
<ng-template #spinner> <mat-spinner class="mt-2" [diameter]="30" /> </ng-template>
