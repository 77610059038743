<div class="col-auto m-1">
  <label class="toggle">
    {{ label }}:

    <mat-slide-toggle
      [color]="'primary'"
      [(ngModel)]="value"
      (change)="onChange()"
      [disabled]="disabled"
      [disableRipple]="true" />
  </label>
</div>
