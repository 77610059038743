<div class="row mx-0 mb-2">
  <div class="col-6 px-0 text-start">
    <app-patient-nav [patient]="patient" />
  </div>
  <div class="col-6 text-end">
    <app-patient-deactivate-button [patient]="patient" (patientDeactivated)="onPatientDeactivated($event)"/>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <h3 class="mb-4">{{ patient.fullName }} ({{ patient.dob | date: 'MM/dd/yyyy' }}) <span *ngIf="!active">- INACTIVE</span></h3>

    <div class="row">
      <div class="col">
        <strong>PATIENT ID:</strong>
        <div>{{ patient.id }}</div>
      </div>

      <div class="col">
        <strong>MAIN PATIENT ID:</strong>
        <div *ngIf="patient.mainPatient">
          <a routerLink="/capture-admin/patients/{{ patient.mainPatient.id }}">
            {{ patient.mainPatient.id }}
          </a>
        </div>
        <div *ngIf="!patient.mainPatient">
          <app-blank-value-dash />
        </div>
      </div>

      <div class="col">
        <strong>PATIENT STATUS:</strong>
        <div *ngIf="active">Active</div>
        <div *ngIf="!active">
          Inactive ({{ patient.inactiveDate | date: 'MM/dd/yyyy' }})
        </div>
      </div>

      <div class="col">
        <strong>CLIENT:</strong>
        <app-client-name [client]="patient.client" />
      </div>

      <div class="col">
        <strong>MRN:</strong>
        <div>{{ patient.mrn }}</div>
      </div>
    </div>
  </div>
</div>
