<div class="container-fluid p-2">
  <form novalidate>
    <div class="row">
      <div class="col-3">
        <label>
          <strong>Status:</strong>
        </label>
        <div class="mb-3">
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="statuses"
            placeholder="Filter by Status"
            [multiple]="true"
            [items]="taskStatuses"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedStatuses"
            [disabled]="loading"
          />
        </div>
      </div>

      <div class="col-3">
        <label>
          <strong>Sources:</strong>
        </label>
        <div class="mb-3">
          <ng-select
            class="w-100"
            name="sources"
            placeholder="Filter by Source"
            [multiple]="true"
            [items]="sources"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedSources"
            [disabled]="loading"
          />
        </div>
      </div>

      <div class="col-3" *ngIf="useEstimatedValueRanks; else: estimatedValueMin">
        <label><strong>Estimated Values:</strong></label>
        <div class="mb-3">
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="estimatedValues"
            placeholder="Filter by Estimated Value"
            [multiple]="true"
            [items]="estimatedValueRank"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedEstimatedValues"
            [disabled]="loading"
          />
        </div>
      </div>

      <div class="col-3">
        <label><strong>Specialties:</strong></label>
        <div class="mb-3">
          <ng-select
            class="w-100"
            name="specialties"
            placeholder="Filter by Specialties"
            [multiple]="true"
            [items]="specialties"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedSpecialties"
            [disabled]="loading"
          />
        </div>
      </div>

      <div class="col-3">
        <label><strong>Prescriber First Name:</strong></label>
        <div class="mb-3">
          <input
            type="text"
            class="w-100 border rounded px-2"
            name="prescriberFirstName"
            [(ngModel)]="filters.prescriberFirstName"
            (input)="onTextFieldChange()"
            [disabled]="loading">
        </div>
      </div>
      <div class="col-3">
        <label><strong>Prescriber Last Name:</strong></label>
        <div class="mb-3">
          <input
            type="text"
            class="w-100 border rounded px-2"
            name="prescriberLastName"
            [(ngModel)]="filters.prescriberLastName"
            (input)="onTextFieldChange()"
            [disabled]="loading">
        </div>
      </div>

      <div class="col-3">
        <label><strong>Prescriber NPI:</strong></label>
        <div class="mb-3">
          <input
            type="text"
            class="w-100 border rounded px-2"
            name="prescriberNpi"
            [(ngModel)]="filters.prescriberNpi"
            (input)="onTextFieldChange()"
            [disabled]="loading">
        </div>
      </div>

      <div class="col-3">
        <label><strong>Manufacturer:</strong></label>
        <div class="mb-3">
          <ng-select
            class="w-100"
            name="manufacturer"
            placeholder="Filter by Manufacturer"
            [multiple]="true"
            [items]="manufacturers"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedManufacturers"
            [disabled]="loading" />
        </div>
      </div>

      <div class="col-3">
        <label><strong>Patient First Name:</strong></label>
        <div class="mb-3">
          <input
            type="text"
            class="w-100 border rounded px-2"
            name="patientFirstName"
            [(ngModel)]="filters.patientFirstName"
            (input)="onTextFieldChange()"
            [disabled]="loading">
        </div>
      </div>
      <div class="col-3">
        <label><strong>Patient Last Name:</strong></label>
        <div class="mb-3">
          <input
            type="text"
            class="w-100 border rounded px-2"
            name="patientLastName"
            [(ngModel)]="filters.patientLastName"
            (input)="onTextFieldChange()"
            [disabled]="loading">
        </div>
      </div>

      <div class="col-3" *ngIf="encounterPatientInteractionLocations">
        <label><strong>Locations:</strong></label>
        <div class="mb-3">
          <ng-select
            class="w-100"
            name="clients"
            placeholder="Filter by Location"
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [items]="encounterPatientInteractionLocations"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedEncounterPatientInteractionLocationIds"
            [disabled]="loading" />
        </div>
      </div>

      <div class="col-3" *ngIf="clients">
        <label><strong>Clients:</strong></label>
        <div class="mb-3">
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="humanizedName"
            name="clients"
            placeholder="Filter by Clients"
            [multiple]="true"
            [items]="clients"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.selectedClientIds"
            [disabled]="loading" />
        </div>
      </div>

      <div class="col-3" *ngIf="allowTaskIdSearch">
        <label><strong>Task ID:</strong></label>
        <div class="mb-3">
          <input
            type="text"
            class="w-100 border rounded px-2"
            name="taskId"
            [(ngModel)]="filters.taskId"
            (input)="onTextFieldChange()"
            [disabled]="loading">
        </div>
      </div>

      <div class="col-3">
        <label><strong>Days To Expiration:</strong></label>
        <div class="mb-3">
          <input
            type="number"
            class="w-25 border rounded px-2"
            name="expiringWithinDays"
            appExcludeInputPattern
            excludedPattern="\D"
            maxlength="3"
            [(ngModel)]="filters.expiringWithinDays"
            (input)="onTextFieldChange()"
            [disabled]="loading">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-end">
        <div class="mb-3 mt-2">
          <button type="button" class="btn btn-info mt-0 clear-button" (click)="onClearFiltersClick()">Clear Filters</button>
          <ng-container *ngIf="exportUrl">
            <a class="btn btn-primary"
               routerLinkActive="active"
               routerLink="{{ exportUrl }}"
               *ngIf="enableExport; else: disableExport">
              Export
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #disableExport>
  <button type="button" class="btn btn-primary" [disabled]="true">Export</button>
</ng-template>
<ng-template #estimatedValueMin>
  <div class="col-3">
    <label><strong>Estimated Value Min.</strong></label>
    <div class="input-group estimated-value">
      <div class="input-group-prepend"><span class="input-group-text">$</span></div>
      <input
        [(ngModel)]="filters.estimatedValue"
        name="estimatedValue"
        [ngClass]="{ 'has-value': hasEstimatedValue }"
        (keydown)="onEstimatedValueKeyDown($event)"
        (keyup)="onEstimatedValueKeyUp()"
        class="form-control pe-0"
        placeholder="e.g. 1000"
        [disabled]="loading"
      />
      <div class="input-group-append" *ngIf="hasEstimatedValue">
        <button class="btn btn-outline-secondary py-0" type="button" (click)="onClearEstimatedValueClick()">
          <span class="btn-label">×</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
