<table class="table table-bordered table-hover">
  <thead class="table-dark">
    <tr>
      <th>ID</th>
      <th>Chore</th>
      <th>Client</th>
      <th>Eligible Reviewers</th>
      <th>Status</th>
      <th class="text-end">Count</th>
      <th class="text-end">Warning</th>
      <th class="text-end">Failed</th>
      <th class="text-end">Completed</th>
      <th>Life Cycle</th>
      <th class="text-center">&nbsp;</th>
    </tr>
  </thead>
  <tbody *ngIf="clientChores.length > 0; else: noClientChores">
    <tr *ngFor="let clientChore of clientChores; let i = index">

      <td>
        {{ clientChore.id }}
      </td>

      <td>
        <div>{{ clientChore.displayName }}</div>
        <app-simple-expandable-area maxHeight="3em">
          <div *ngFor="let param of clientChore.params | keyvalue">
            <ul *ngIf="param.key === 'normalizedStatusesAndReasons'">
              <li *ngFor="let statusAndReasons of param.value | captureStatusesAndReasons">
              {{ statusAndReasons }}
              </li>
            </ul>
          </div>
        </app-simple-expandable-area>
      </td>

      <td>
        {{ clientChore.clientName | blankValueDash }}
      </td>

      <td>
        <ng-container *ngIf="clientChore.reviewableBy.length; else blankDash">
          <ng-container *ngFor="let user of clientChore.reviewableBy; let last = last">
            {{user.fullName}}
            <br *ngIf="!last">
          </ng-container>
        </ng-container>
      </td>

      <td>
        <app-client-chore-status
          [clientChore]="clientChore" />

        <app-client-chore-review
          *ngIf="clientChore.ended"
          [reviewed]="clientChore.reviewed"
          (reviewedChanged)="onReviewChange($event, clientChore)" />
      </td>

      <td class="text-end">
        {{ clientChore.rows || 0 | number }}
      </td>

      <td class="text-end">
        {{ clientChore.counts?.warned || 0 | number }}
      </td>

      <td class="text-end">
        {{ clientChore.counts?.failed || 0 | number }}
      </td>

      <td class="text-end">
        {{ clientChore.counts?.completed || 0 | number }}
      </td>

      <td class="text-nowrap">
        <app-client-chore-lifecycle
          [clientChore]="clientChore" />
      </td>

      <td>
        <a *ngIf="clientChore.fileUrl"
           class="nav-link"
           href="#"
           (click)="onDownloadClick($event, clientChore.fileUrl)">
          INPUT
        </a>

        <a *ngIf="clientChore.reportUrl"
           class="nav-link"
           href="#"
           (click)="onDownloadClick($event, clientChore.reportUrl)">
          REPORT
        </a>

        <a *ngIf="canManageChores && clientChore.status === 'processing' && clientChore.userFailable"
           class="nav-link"
           href="#"
           (click)="onFailClick($event, clientChore.id)">
          FAIL
        </a>

        <a *ngIf="canManageChores && clientChore.inactive"
           class="nav-link"
           href="#"
           (click)="onRestartClick($event, clientChore.id)">
          RESTART
        </a>
      </td>

    </tr>
  </tbody>
</table>
<ng-template #noClientChores>
  <tbody>
    <tr>
      <td colspan="99">There are no client chores.</td>
    </tr>
  </tbody>
</ng-template>
<ng-template #blankDash>--</ng-template>
