<div class="g-0" [hidden]="uploading">
  <div class="row">
    <div class="col-2" *ngIf="collectClient">
      <div>
        <label class="my-0"><strong>Client</strong></label>
        <div class="input-group">
          <select class="form-select" [(ngModel)]="formModel.clientId" name="clientId">
            <option value="" [selected]="true"> -- Choose Client -- </option>
            <option *ngFor="let client of clients" [value]="client.id"> {{ client.humanizedName }} </option>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="collectThirdPartyAdminister" class="col-2">
      <div>
        <label class="my-0"><strong>TPA</strong></label>
        <div class="input-group">
          <select class="form-select" [(ngModel)]="formModel.thirdPartyAdministerId" name="thirdPartyAdministerId">
            <option value="" [selected]="true"> -- Choose TPA -- </option>
            <option *ngFor="let thirdPartyAdminister of thirdPartyAdministers" [value]="thirdPartyAdminister.id">
              {{ thirdPartyAdminister.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div>
        <label class="my-0"><strong>File Name</strong> (optional)</label>
        <div class="input-group">
          <input type="text" class="form-control" [(ngModel)]="formModel.name" />
        </div>
      </div>
    </div>
    <div class="col-4">
      <div>
        <app-file-upload
          [fileType]="'.csv'"
          [uploadButtonDisabled]="uploadButtonDisabled"
          (fileSelected)="onFileSelected($event)"
          (uploadStart)="onUploadStart()"
          (uploadCancel)="onUploadCancel()"
          (uploadError)="onUploadError()"
          (uploadComplete)="onUploadComplete($event)" />
      </div>
    </div>
  </div>
</div>
<div class="row g-0" [hidden]="!uploading">
  <div class="col-12">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
    Uploading File...
  </div>
</div>
