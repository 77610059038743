<div *ngIf="showAassignments" class="container-fluid p-2 mt-3 border rounded bg-light">
  <h4>Assignments</h4>
  <form novalidate class="row g-0">
    <div class="mb-3 col-4 pe-1">
      <app-consult-note-assign-user-select
        [originalSelectedUser]="originalSelectedUser"
        (selectionChanged)="handleAssignedUserChanged($event)"
      />
    </div>
    <div class="mb-3 col-4">
      <button
        class="btn btn-primary"
        [disabled]="!canAssign"
        (click)="handleAssignClick($event)"
        >
        Assign
      </button>
      <button
        class="btn btn-secondary"
        [disabled]="!canClearSelection"
        (click)="handleClearSelectionClick($event)"
        >Clear Selection</button>
    </div>
    <div class="col-4 text-end">
      {{ selectedConsultNoteText }} <span *ngIf="displayTotalCount">| {{ totalCountText }}</span>
    </div>
  </form>
</div>
