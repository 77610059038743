<div class="container-fluid p-1 px-3 border rounded bg-light">
  <form>
    <div class="row small-gutters search-fields">
      <div class="col-md-1">
        <div class="w-100">
          <label>Claim ID: </label>
          <input
            id="claimId"
            name="claimId"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.claimIdentifier"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>Capture ID: </label>
          <input
            id="captureId"
            name="captureId"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.captureId"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>Rx Ref. Number: </label>
          <input
            id="rxReferenceNumber"
            name="rxReferenceNumber"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.rxReferenceNumber"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>NDC: </label>
          <input
            id="ndc"
            name="ndc"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.ndc"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>Prescriber NPI: </label>
          <input
            id="prescriberNpi"
            name="prescriberNpi"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.prescriberNpi"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>Prescriber Last:</label>
          <input
            id="prescriberLastName"
            name="prescriberLastName"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.prescriberLastName"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>Prescriber First:</label>
          <input
            id="prescriberFirstName"
            name="prescriberFirstName"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.prescriberFirstName"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>Patient Last:</label>
          <input
            id="patientLastName"
            name="patientLastName"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.patientLastName"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>Patient First:</label>
          <input
            id="patientFirstName"
            name="patientFirstName"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.patientFirstName"
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="w-100">
          <label>Patient DOB:</label>
          <input
            id="patientDob"
            name="patientDob"
            class="form-control form-control-sm"
            type="date"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.patientDob"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="w-100">
          <label>Patient Prescriber UUID: </label>
          <input
            id="patientPrescriberUuid"
            name="patientPrescriberUuid"
            class="form-control form-control-sm"
            type="text"
            (ngModelChange)="onFilterChange()"
            [(ngModel)]="filters.patientPrescriberUuid"
          />
        </div>
      </div>
    </div>
    <hr/>
    <div class="row small-gutters">
      <div class="col-md-2">
        <ng-select
          class="w-100"
          bindValue="value"
          bindLabel="display"
          name="statuses"
          placeholder="Status"
          [multiple]="true"
          [items]="statusFilterOptions"
          (remove)="onStatusFilterChange()"
          (change)="onStatusFilterChange()"
          [(ngModel)]="filters.statuses"
        />
      </div>
      <div class="col-md-2">
        <ng-select
          class="w-100"
          bindValue="value"
          bindLabel="display"
          name="reasons"
          placeholder="Reasons"
          [multiple]="true"
          [items]="statusReasonFilterOptions"
          (remove)="onStatusReasonFilterChange()"
          (change)="onStatusReasonFilterChange()"
          [(ngModel)]="filters.reasons"
          [disabled]="statusReasonFilterOptions.length === 0"
        />
      </div>
      <div class="col-md-2">
        <ng-select
          class="w-100"
          bindValue="id"
          bindLabel="humanizedName"
          name="clientIds"
          placeholder="Client"
          [multiple]="true"
          [items]="clientFilterOptions"
          (remove)="onFilterChange()"
          (change)="onFilterChange()"
          [(ngModel)]="filters.clientIds"
        />
      </div>
      <div class="col-md-2">
        <ng-select
          class="w-100"
          bindValue="id"
          bindLabel="username"
          name="assignedUserIds"
          placeholder="Assigned Client Lead"
          [multiple]="true"
          [items]="assignedUsersFilterOptions"
          (remove)="onFilterChange()"
          (change)="onFilterChange()"
          [(ngModel)]="filters.assignedUserIds"
        />
      </div>
      <div class="col-md-2">
        <ng-select
          class="w-100"
          bindValue="value"
          bindLabel="display"
          name="clientStates"
          placeholder="Client State"
          [multiple]="true"
          [items]="stateOptions"
          (remove)="onFilterChange()"
          (change)="onFilterChange()"
          [(ngModel)]="filters.clientStates"
        />
      </div>
      <div class="col-md-2">
        <ng-select
          class="w-100"
          bindValue="value"
          bindLabel="display"
          name="sources"
          placeholder="Source"
          [multiple]="true"
          [items]="claimSourceOptions"
          (remove)="onFilterChange()"
          (change)="onFilterChange()"
          [(ngModel)]="filters.sources"
        />
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-md-8">
        <div class="d-flex flex-row flex-wrap align-items-center">
          <div class="col-auto px-1">
            <strong>High Value:</strong>
            <mat-slide-toggle
              name="highValue"
              [color]="'primary'"
              [(ngModel)]="filters.highValue"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
          <div class="col-auto px-1">
            <strong>Specialty:</strong>
            <mat-slide-toggle
              name="specialtyStore"
              [color]="'primary'"
              [(ngModel)]="filters.specialtyStore"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
          <div class="col-auto px-1">
            <strong>Active:</strong>
            <mat-slide-toggle
              name="active"
              [color]="'primary'"
              [(ngModel)]="filters.activeClientsOnly"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
          <div class="col-auto px-1">
            <div class="manual-fax-sent">
              <label class="manual-fax-sent-label me-3"><strong>Manual Fax Sent:</strong></label>
              <div class="manual-fax-sent-options">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="manualFaxSent"
                    id="manualFaxSentYes"
                    (change)="onFilterChange()"
                    [value]="true"
                    [(ngModel)]="filters.manualFaxSent"
                  />
                  <label class="form-check-label" for="manualFaxSentYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="manualFaxSent"
                    id="manualFaxSentNo"
                    (change)="onFilterChange()"
                    [value]="false"
                    [(ngModel)]="filters.manualFaxSent"
                  />
                  <label class="form-check-label" for="manualFaxSentNo">No</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="manualFaxSent"
                    id="manualFaxSentAll"
                    (change)="onFilterChange()"
                    [value]="null"
                    [(ngModel)]="filters.manualFaxSent"
                  />
                  <label class="form-check-label" for="manualFaxSentAll">All</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto px-1">
            <label><strong>Days To Expiration:</strong></label>
          </div>
          <div class="col-1 px-1">
            <input
              type="text"
              class="form-control form-control-sm"
              name="expiringWithinDays"
              appExcludeInputPattern
              excludedPattern="\D"
              maxlength="3"
              [(ngModel)]="filters.expiringWithinDays"
              (keyup)="onFilterChange()"/>
          </div>
        </div>
      </div>
      <div class="col-md-4 sort-buttons">
        <div class="row g-0 justify-content-end">
          <div class="col-md-1 sort-by-label">
            <strong>
              Sort:
            </strong>
          </div>
          <div class="col-md-6 mx-1 sort-by">
            <ng-select
              class-="w-100"
              name="cursorType"
              bindValue="value"
              bindLabel="display"
              [multiple]="false"
              [items]="cursorTypeOptions"
              [clearable]="false"
              [searchable]="false"
              (change)="onCursorTypeChange()"
              [(ngModel)]="cursorType"
            />
          </div>
          <div class="btn-group col-auto">
            <button type="submit" [disabled]="disableSearch" class="btn btn-primary" (click)="onSearchClick($event)">Search</button>
            <button type="reset" class="btn btn-info" (click)="onResetClick($event)">Reset</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
