<div *ngIf="office" class="office">
  <div *ngIf="office.name" class="fst-italic">
    <a href="/capture-admin/offices/{{ office.id }}" target="_blank" *ngIf="canViewOffice; else: officeName">
      {{ office.name }}
    </a>
    <app-copy-to-clipboard *ngIf="allowCopyOfficeName" [text]="office.name" />
  </div>
  <div *ngIf="office.aka" class="fst-italic">{{ office.aka }}</div>
  <div *ngIf="office.address1">{{ office.address1 }}</div>
  <div *ngIf="office.address2">{{ office.address2 }}</div>
  <div>
    {{ office.city }}, {{ office.state }} {{ office.zip }}
  </div>
  <div *ngIf="displayPhoneAndFax">
    <strong *ngIf="office.phone">P:</strong>
    <app-phone-number [number]="office.phone" [dialpad]="true" />
    <strong *ngIf="office.fax">F:</strong>
    <app-office-fax [office]="office" />

    <span
      *ngIf="providerOffice && providerOffice.status"
        title="{{ keyedProviderOfficeStatusOptions[providerOffice.status]?.display }}">
      ({{ providerOffice.status | providerOfficeFaxStatus }})
    </span>
  </div>
</div>
<ng-template #officeName>
  {{ office.name }}
</ng-template>