<ngb-alert type="info" *ngIf="successfullyUpdated" (close)="successfullyUpdated = false" [dismissible]="false">
  <strong>Success.</strong> Your preferences have been successfully updated.
</ngb-alert>
<ngb-alert *ngIf="errorOccurred" type="danger" [dismissible]="false">
  <strong>Error!</strong> Something went wrong, please try again.
</ngb-alert>

<h2>User Preferences</h2>
<h5>Email Preferences</h5>
<div class="row col-md-12" *ngIf="!refreshing">
  <form (ngSubmit)="onSubmit()" #userPreferencesForm="ngForm">
    <div class="mb-3">
      <p>Choose the email(s) you would like to receive. To opt out of an email, remove the selection and click UPDATE.</p>
      <ng-container *ngIf="user.useUpdatedEmailWorkflowForNewAndExpiringTaskAlerts">
        <div class="form-check my-2 ms-4">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="enableExpiringTaskReviewEmail"
            name="enableExpiringTaskReviewEmail"
            [(ngModel)]="user.enableExpiringTaskReviewEmail"
          />
          <label class="form-check-label" for="enableExpiringTaskReviewEmail"> Expiring Tasks Review Email </label>
        </div>
        <div class="form-check my-2 ms-4">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="enableNewTasksReviewEmail"
            name="enableNewTasksReviewEmail"
            [(ngModel)]="user.enableNewTasksReviewEmail"
          />
          <label class="form-check-label" for="enableNewTasksReviewEmail"> New Tasks Review Email </label>
        </div>
      </ng-container>
      <ng-container *ngIf="!user.useUpdatedEmailWorkflowForNewAndExpiringTaskAlerts">
        <div class="form-check my-2 ms-4">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="enableCeReviewRequestsEmail"
            name="enableCeReviewRequestsEmail"
            [(ngModel)]="user.enableCeReviewRequestsEmail"
          />
          <label class="form-check-label" for="enableCeReviewRequestsEmail"> Task Review Email </label>
        </div>
      </ng-container>
      <button type="submit" class="btn btn-primary" [disabled]="!userPreferencesForm.form.dirty">
        Update Preferences
      </button>
    </div>
  </form>
</div>
