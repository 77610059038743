import { Component, Input, OnInit } from '@angular/core';
import { OutboundFax } from 'app/core/models/outbound-fax.model';
import { isAlertableTemplate, keyedOutboundFaxTemplateOptions } from 'app/core/options/outbound-fax-template-opts';

@Component({
  selector: 'app-outbound-fax-template',
  templateUrl: './outbound-fax-template.component.html',
  styleUrls: ['./outbound-fax-template.component.scss'],
})
export class OutboundFaxTemplateComponent implements OnInit {
  @Input() outboundFax: OutboundFax;

  templateClass: string;
  templateName: string;

  ngOnInit() {
    this.templateClass = this.calculateTemplateClass();
    this.templateName = this.calculateTemplateName();
  }

  private calculateTemplateClass() {
    return this.shouldAlert() ? 'alert alert-danger' : 'bg-light';
  }

  private calculateTemplateName() {
    return keyedOutboundFaxTemplateOptions[this.outboundFax.template]?.display;
  }

  private shouldAlert() {
    return this.hasNoInboundCommunications() &&
           this.hasNoValidEvidence() &&
           this.isAnAlertableTemplate();
  }

  private hasNoInboundCommunications() {
    return this.outboundFax.inboundCalls.length === 0 &&
           this.outboundFax.inboundFaxes.length === 0;
  }

  private hasNoValidEvidence() {
    return !this.outboundFax.hasValidEvidence;
  }

  private isAnAlertableTemplate() {
    return isAlertableTemplate(this.outboundFax.template);
  }
}
