<form (ngSubmit)="onSubmit()" novalidate>
  <input
    [(ngModel)]="patientSearchQuery.lastName"
    id="lastName"
    name="lastName"
    [disabled]="formDisabled"
    class="form-control mb-1"
    placeholder="Last Name"
    type="text"
    #patientLastName
  />
  <input
    [(ngModel)]="patientSearchQuery.firstName"
    id="first"
    name="firstName"
    [disabled]="formDisabled"
    class="form-control mb-1"
    placeholder="First Name"
    type="text"
  />
  <div class="mb-1">
    <label class="small fw-bold">DOB:</label>
    <input
      type="date"
      [(ngModel)]="patientSearchQuery.dob"
      [min]="dobMinDate"
      name="dob"
      [disabled]="formDisabled"
      class="form-control"
    />
    <button
      *ngIf="patientSearchQuery.dob"
      (click)="onDateClearClick()"
      class="btn clear-button"
      type="button"
    >
      <span>×</span>
    </button>
  </div>
  <div class="row align-items-center">
    <div class="col">
      <fieldset [disabled]="formDisabled">
        <div class="form-check form-check-inline">
          <input
            id="femaleRadioButton"
            [(ngModel)]="patientSearchQuery.gender"
            class="form-check-input"
            type="radio"
            name="gender"
            value="F"
          />
          <label class="form-check-label" for="femaleRadioButton">Female</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            [(ngModel)]="patientSearchQuery.gender"
            id="maleRadioButton"
            class="form-check-input"
            type="radio"
            name="gender"
            value="M"
          />
          <label class="form-check-label" for="maleRadioButton">Male</label>
        </div>
      </fieldset>
    </div>
    <div class="col text-end">
      <button type="submit" [disabled]="!canSearch" class="btn btn-primary">Search</button>
      <button type="reset" [disabled]="!canReset" (click)="onReset()" class="btn btn-secondary">Reset</button>
    </div>
  </div>
</form>
