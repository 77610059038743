<form [formGroup]="formGroup" novalidate>
  <div class="card bg-light p-3">
    <div class="row">
      <div class="col-2">
        <h6 class="mb-3" *ngIf="!editing">Add Evidence</h6>
        <h6 class="mb-3" *ngIf="editing">Edit Evidence</h6>
      </div>
      <div class="col-10 actions">
        <button
          class="btn btn-sm btn-primary"
          (click)="onSaveClick()"
          [disabled]="!formIsValid()">
          Save Evidence
        </button>
        <button class="btn btn-sm btn-secondary" (click)="onCancelClick()">
          Cancel
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <div formGroupName="reasons">
          <div>
            <label>
              <input
                type="checkbox"
                formControlName="prescriberReferral" />
              Reference to the prescriber
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                formControlName="specialityReferral" />
              Reference to the prescriber's specialty
            </label>

            <div *ngIf="shouldHaveChoiceSpecialityReferralSpeciality()" class="ms-2 mb-3">
              <div>Choose <strong>closest</strong> referral specialty (required)</div>
              <ng-select
                [items]="referralSpecialties"
                [clearable]="false"
                formControlName="referralSpecialty" />
            </div>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                formControlName="prescriberOfficeReferral" />
              Reference to the prescriber's office
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                formControlName="other" />
              Other
            </label>

            <div *ngIf="shouldHaveChoiceOtherReasonNote()" class="ms-2 mb-3">
              <div>If other, please specify (required)</div>
              <textarea
                class="form-control"
                formControlName="otherReason"></textarea>
            </div>
          </div>
        </div>

      </div>

      <div class="col-9">
        <div class="row mb-2">
          <div class="col-2 field-label">
            <label>
              Referral Date
            </label>
          </div>
          <div class="col-2">
            <input type="date" class="form-control" formControlName="referralDate">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-2 field-label">
            <label>
              Note To Client
            </label>
          </div>
          <div class="col-10">
            <textarea class="form-control mb-2 notes" formControlName="notes"></textarea>
          </div>
        </div>

      </div>
    </div>

    <div class="alert alert-danger" *ngIf="serverError">
      A server error occurred while processing this match. Please try again or contact support.

      <div class="mt-2">
        {{ serverError }}
      </div>
    </div>

  </div>
</form>
