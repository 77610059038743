<form novalidate (keydown.enter)="onFormEnter($event)">
  <div class="row g-0 flex-row align-items-end">
    <div class="col-2">
      <div class="my-0 py-0 me-2">
        <label for="clientIds" class="my-0"><strong>Client</strong></label>
        <ng-select
          bindValue="id"
          bindLabel="humanizedName"
          name="clientIds"
          placeholder="Filter by Client"
          [multiple]="false"
          [items]="clients"
          [(ngModel)]="filters.clientId"
        />
      </div>
    </div>
    <div class="col-1">
      <div class="my-0 py-0 me-2">
        <label for="patientId" class="my-0"><strong>Patient ID</strong></label>
        <input
          id="patientId"
          class="form-control"
          name="patientId"
          placeholder="Filter By ID"
          [(ngModel)]="filters.patientId"
        >
      </div>
    </div>
    <div class="col-2">
      <div class="my-0 py-0 me-2">
        <label for="patientFirstName" class="my-0"><strong>Patient First</strong></label>
        <input
          id="patientFirstName"
          class="form-control"
          name="patientFirstName"
          placeholder="Filter By First Name"
          [(ngModel)]="filters.patientFirstName"
        >
      </div>
    </div>
    <div class="col-2">
      <div class="my-0 py-0 me-2">
        <label for="patientLastName" class="my-0"><strong>Patient Last</strong></label>
        <input
          id="patientLastName"
          class="form-control"
          name="patientLastName"
          placeholder="Filter By Last Name"
          [(ngModel)]="filters.patientLastName"
        >
      </div>
    </div>
    <div class="col-1">
      <div class="my-0 py-0 me-2">
        <label for="patientDob" class="my-0"><strong>Patient DOB</strong></label>
        <input
          id="patientDob"
          class="form-control"
          type="date"
          name="patientDob"
          [(ngModel)]="filters.patientDob"
        >
      </div>
    </div>
    <div class="col-1">
      <div class="my-0 py-0 me-2">
        <label for="patientMrn" class="my-0"><strong>Patient MRN</strong></label>
        <input
          id="patientMrn"
          class="form-control"
          name="patientMrn"
          placeholder="Filter By MRN"
          [(ngModel)]="filters.patientMrn"
        >
      </div>
    </div>
    <div class="col-2">
      <button type="submit" class="btn btn-primary" (click)="onFormEnter($event)">Search</button>
      <button class="btn btn-info clear-button" (click)="onClearAllClick($event)">Clear All</button>
    </div>
  </div>
</form>
