<div class="card">
  <div class="card-body">
    <h4 class="mb-2">Patient</h4>
    <app-patient-search-form
      #patientSearchForm
      [formDisabled]="searchFormDisabled"
      [suggestedSearch]="suggestedSearch"
      (patientSearch)="onPatientSearch($event)"
      (resetSearch)="onReset()"
    />
    <ng-container *ngIf="!searchFormDisabled">
      <p class="mt-2 p-2 bg-light" *ngIf="!hasSearched; else: patientSearchResults">Search for a patient above.</p>
    </ng-container>
    <div *ngIf="selectedPatient" class="d-flex patient-selection mt-3">
      <app-patient-name [patient]="selectedPatient" [canViewPatient]="true" />
      <div class="small ms-3">{{ selectedPatient.gender | gender }}</div>
      <div class="text-danger ms-3 patient-clear" (click)="onPatientClear()">
        <fa-icon [icon]="['fas', 'times']" />
      </div>
    </div>
  </div>
</div>
<ng-template #patientSearchResults>
  <div *ngIf="refreshing"><mat-spinner diameter="30" /></div>
  <div class="mt-2" *ngIf="!refreshing && patients.length > 0; else: noPatients">
    <h5>Choose Patient</h5>
    <div infiniteScroll (scrolled)="onPatientScrolled()" [infiniteScrollDisabled]="loadingMoreResults" [scrollWindow]="false" class="choose-patients">
      <app-patient-list [patients]="patients" (patientSelect)="onPatientSelect($event)" />
      <div *ngIf="loadingMoreResults" class="more-results-spinner">
        <mat-spinner diameter="20" />
      </div>
    </div>
  </div>
</ng-template>
<ng-template #noPatients> <p *ngIf="!refreshing" class="mt-2 p-2 bg-light">No patients found.</p> </ng-template>
