import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Provider } from 'app/core/models/provider.model';
import { ProviderService } from 'app/core/services/provider.service';
import { ProviderOffice } from 'app/core/models/provider-office.model';

@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.scss'],
})
export class ProviderDetailComponent implements OnInit {
  @Input() provider: Provider;
  @Output() providerChange = new EventEmitter();

  providerOffices: ProviderOffice[];
  providerOfficesLoading = true;

  showAddOffices = false;

  constructor(private providerService: ProviderService) { }

  ngOnInit() {
    this.loadProviderOffices();
  }

  onAddNewOfficesClick() {
    this.showAddOffices = true;
  }

  onOfficeAdded() {
    this.loadProviderOffices();
    this.providerChange.emit();
  }

  onOfficeFormCompleted() {
    this.showAddOffices = false;
  }

  private loadProviderOffices() {
    this.providerService.getProviderOffices(this.provider.id).subscribe((providerOffices: ProviderOffice[]) => {
      this.providerOffices = providerOffices;
      this.providerOfficesLoading = false;
    });
  }
}
