<app-outbound-fax-search-form (search)="onSearch($event)" />
<div class="mt-4" *ngIf="!refreshing && hasQueried && hasResults">
  <div>
    <strong>Please select an <em>outbound</em> fax</strong>
  </div>
  <table class="table table-bordered table-sm small">
    <thead class="table-dark">
      <tr>
        <th>DATE FAXED</th>
        <th>CLIENT</th>
        <th>PATIENT</th>
        <th>PRESCRIBER</th>
        <th>OFFICE</th>
        <th>FAX NUMBER</th>
        <th>OUTBOUND STATUS</th>
        <th>INBOUND STATUS</th>
        <th>PROVIDER FAX STATUS</th>
        <th>FAX TYPE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let outboundFax of outboundFaxes">
        <td>
          <div class="form-check">
            <input
              [id]="'outboundFax' + outboundFax.id"
              name="selectedOutboundFaxId"
              class="form-check-input"
              type="radio"
              (change)="onOutboundFaxChange(outboundFax)"
              [value]="outboundFax.id"
              [disabled]="outboundFax.hasValidEvidence"
            />
            <label
              class="form-check-label pt-1"
              [ngClass]="{ disabled: outboundFax.hasValidEvidence }"
              [for]="'outboundFax' + outboundFax.id"
            >
              {{ outboundFax.createdAt | date: 'MM/dd/yyyy' }}
            </label>
          </div>
        </td>
        <td>{{ outboundFax.client.humanizedName }}</td>
        <td>
          <app-patient-name [patient]="outboundFax.patient" />
        </td>
        <td><app-prescriber-name [prescriber]="outboundFax.prescriber" /></td>
        <td>{{ outboundFax.prescriberOfficesNames && outboundFax.prescriberOfficesNames.join(', ') }}</td>
        <td><app-phone-number [number]="outboundFax.fax" /></td>
        <td class="text-center">
          <div [ngSwitch]="outboundFax.status">
            <span *ngSwitchCase="outboundFaxStatuses.scheduled">
              <div>Scheduled to send after {{ outboundFax.scheduledTime | date: 'M/dd h:mm a' }}</div>
              <div *ngIf="outboundFax.attemptCount > 0">
                {{ outboundFax.attemptCount }} failed send attempt{{ outboundFax.attemptCount > 1 ? 's' : '' }}
              </div>
            </span>
            <span *ngSwitchCase="outboundFaxStatuses.queued"> Sending... </span>
            <span *ngSwitchDefault> {{ outboundFax.status | titlecase }} </span>
          </div>
          <app-outbound-fax-template [outboundFax]="outboundFax" />
        </td>
        <td>
          <ng-container *ngIf="outboundFax.inboundFaxes.length > 0">
            <div [ngSwitch]="mostRecentInboundFax(outboundFax).status">
              <strong *ngSwitchCase="inboundFaxStatuses.validEvidence" class="text-success">
                Valid Fax Received
              </strong>
              <strong *ngSwitchCase="inboundFaxStatuses.notValidEvidence"> Invalid Fax Received </strong>
              <strong *ngSwitchCase="inboundFaxStatuses.needsManualIntervention"> Manual Intervention Required </strong>
            </div>
            <div class="mt-1">
              <a
                target="_blank"
                class="inbound-fax-link small"
                routerLink="/capture-admin/communications/inbound-faxes/{{mostRecentInboundFax(outboundFax).id}}"
              >
                <strong>VIEW INBOUND FAX</strong>
              </a>
            </div>
          </ng-container>
        </td>
        <td>{{ keyedProviderOfficeStatusOptions[outboundFax.providerOfficeStatus]?.display }}</td>
        <td>{{ outboundFax.faxType }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="mt-3" *ngIf="!refreshing && hasQueried && !hasResults">No Outbound Faxes Found</div>
<div *ngIf="refreshing" class="mt-3"><mat-spinner diameter="30" /></div>
