<div class="card mb-2" *ngIf="showPatients">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h5 class="mb-0">Patient Summary</h5>
      </div>
      <div class="col-sm-auto text-end">
        <button type="button" class="btn btn-link m-0 p-0" (click)="collapse.toggle()">
          <fa-icon [icon]="['fas', 'exclamation-circle']" size="sm" />
          Report an Issue
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ul class="list-unstyled mb-0">
      <li *ngFor="let patient of inboundFax.possiblePatientInfo">
        {{ patient.name }} ({{ patient.dob }})
      </li>
      <li *ngIf="inboundFax.llmResultError">
        We have encountered errors while extracting information. Please check the document manually.
      </li>
      <li *ngIf="!inboundFax.llmResultError && !inboundFax.possiblePatientInfo?.length">
        No patient details found
      </li>
    </ul>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="formCollapsed" class="card-body">
    <form>
      <label for="patients-feedback">Describe any problems you see with the data:</label>
      <textarea id="patients-feedback" class="form-control mb-2" name="feedback" [(ngModel)]="feedback"></textarea>
      <button type="submit" class="btn btn-primary" (click)="onSaveClick()">Save</button>
      <button type="button" class="btn btn-secondary" (click)="collapse.toggle()">Cancel</button>
    </form>
  </div>
</div>

