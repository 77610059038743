<ng-container *ngIf="!loading; else spinner">
  <div class="row mx-0">
    <div class="col-4 px-0 text-start">
      <h3>{{ client.humanizedName }}</h3>
    </div>
    <div *ngIf="displayedConfigs.length > 0" class="col-8 px-0 text-end">
      <a routerLink="/capture-admin/client-management/clients/{{client.id}}/options-change-log">
        <span>Change Log</span>
      </a>
    </div>
  </div>

  <form
    #configForm="ngForm"
    class="config-form"
    (ngSubmit)="onFormSubmit(configForm)"
    [ngClass]="{ submitted: configForm.submitted }"
  >
    <h5 class="mb-0">Add Configuration</h5>
    <ng-select
      #ngSelectControl
      bindLabel="title"
      placeholder="Select a config"
      name="selected-available-option"
      class="option-selector"
      [items]="selectableDefinitions"
      [disabled]="selectableDefinitions.length === 0"
      [(ngModel)]="selectedDefinition"
      (change)="onAddNewConfig(ngSelectControl)"
    />
    <h5 class="mt-3 mb-0">Configuration Values</h5>
    <div class="configuration-values px-1 mb-1 border rounded" *ngIf="displayedConfigs.length > 0">
      <ng-container *ngFor="let config of displayedConfigs; trackBy: trackByConfig">
        <div class="row my-2 ps-1">
          <div class="col-4 config-title">
            <app-client-configuration-title
              [deactivated]="config.deactivated"
              [editorType]="config.editorType"
              [definition]="config.definition"
              [configOption]="config.configOption"
            />
          </div>
          <div class="col-7 config-editor">
            <ng-container [ngSwitch]="config.editorType">
              <app-client-configuration-dated-value
                *ngSwitchCase="editorTypes.dated"
                [definition]="config.definition"
                [deactivated]="config.deactivated"
                [identifier]="config.identifier"
                [configOption]="config.configOption"
                [errors]="config.errors"
                (optionChange)="onDatedChange(config, $event)"
              />
              <app-client-configuration-suboptions
                *ngSwitchCase="editorTypes.suboptions"
                [definition]="config.definition"
                [suboptionChoices]="config.suboptionChoices"
                (choicesChange)="onSuboptionsChange(config, $event)"
              />
              <div *ngSwitchDefault class="basic-value-container">
                <app-client-configuration-basic-value
                  *ngSwitchDefault
                  [definition]="config.definition"
                  [identifier]="config.identifier"
                  [errors]="config.errors"
                  [value]="config.configOption.value"
                  (valueChange)="onBasicChange(config, $event)"
                />
              </div>
            </ng-container>
          </div>
          <div class="col-1 audit">
            <div *ngIf="config.configOption.id !== undefined;">
              <a routerLink="/capture-admin/change-log/ClientConfigurationOption/{{ config.configOption.id }}">
                <span>Change Log</span>
              </a>
            </div>
          </div>
        </div>
        <hr />
      </ng-container>
    </div>
    <div *ngIf="displayedConfigs.length === 0">
      <div>
        No configuration values assigned.
      </div>
    </div>
    <div *ngIf="displayedConfigs.length !== 0" class="text-end actions">
      <button type="submit" class="btn btn-primary" [disabled]="saving || configForm.invalid">Save</button>
      <button type="button" class="btn btn-secondary" [disabled]="saving" (click)="onCancelClick()">Cancel</button>
    </div>
  </form>
</ng-container>
<ng-template #spinner>
  <mat-spinner />
</ng-template>
