import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, ControlContainer, FormArrayName } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map } from 'rxjs/operators';
import { OfficeFilters } from 'app/core/models/user-settings/office-search-settings.model';
import { OfficeService, PagedOffices } from 'app/core/services/office.service';
import { ProviderOfficeStatus } from 'app/core/enums/provider-office-status.enum';
import { Office } from 'app/core/models/office.model';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { filterProviderStatusOptions } from 'app/core/options/provider-office-status.opts';

@Component({
  selector: 'app-provider-office-new-row',
  templateUrl: './provider-office-new-row.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
    { provide: ControlContainer, useExisting: FormArrayName }
  ],
})
export class ProviderOfficeNewRowComponent implements OnInit {
  @Input() formArrayIndex: number;
  @Input() rowForm: FormGroup;
  @Input() existingProviderOffices: ProviderOffice[];

  providerOfficeStatusOptions;

  offices$: Observable<Office[]>;
  officesSearchInput$ = new Subject<string>();
  officesSearchMinFaxLength = 3;
  officesLoading = false;

  selectedProviderAlreadyAssociated = false;

  constructor(
    private officeService: OfficeService,
  ) { }

  ngOnInit() {
    this.providerOfficeStatusOptions = filterProviderStatusOptions(null);
    this.setupFormSubscriber();
    this.setupProvidersSource();
  }

  private setupFormSubscriber() {
    this.rowForm.controls.officeId.valueChanges.subscribe(value => {
      const statusControl = this.rowForm.controls.status;
      const noteControl = this.rowForm.controls.note;

      if (value) {
        statusControl.setValue(ProviderOfficeStatus.testing);
        statusControl.enable();
        noteControl.enable();
        this.selectedProviderAlreadyAssociated = this.isAlreadyAssociated(value);
      } else {
        statusControl.setValue(null);
        statusControl.disable();
        noteControl.disable();
        this.selectedProviderAlreadyAssociated = false;
      }
    });
  }

  private setupProvidersSource() {
    this.offices$ =
      this.officesSearchInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.officesLoading = true),
        debounceTime(500),
        switchMap(term =>
          this.officeSourceForTerm(term).
            pipe(
              catchError(() => of(new PagedOffices())),
              tap(() => this.officesLoading = false),
              map(result => result.records)
            ))
      );
  }

  private officeSourceForTerm(term: string): Observable<PagedOffices> {
    if (term) {
      const normalizedTerm = term.replace(/[^0-9.]/g, '');

      if (normalizedTerm.length >= this.officesSearchMinFaxLength) {
        const paging = { page: 1, pageSize: 20 };
        const filters = new OfficeFilters();
        filters.fax = normalizedTerm;

        return this.officeService.getList(paging, filters);
      }
    }

    return of(new PagedOffices());
  }

  private isAlreadyAssociated(value) {
    return this.existingProviderOffices.some(po => po.office.id === value);
  }
}
