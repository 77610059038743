<app-file-check-nav />
<h5 class="mt-3">File Check</h5>
<form novalidate (keydown.enter)="onFormEnter($event)">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="hasError">
        There was a problem uploading the file.
        <ul class="mt-2">
          <li *ngFor="let error of errors">{{ error }}</li>
        </ul>
      </div>
    </div>
    <div class="col-auto">
      <div class="mb-3">
        <div>
          <label class="my-0"><strong>File Type</strong></label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="fileType"
            id="referral"
            value="referral"
            [(ngModel)]="formModel.fileType"
          />
          <label class="form-check-label" for="referral">Referral</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="encounter"
            id="encounter"
            value="encounter"
            [(ngModel)]="formModel.fileType"
          />
          <label class="form-check-label" for="encounter">Encounter</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="clientPrescription"
            id="clientPrescription"
            value="client_prescription"
            [(ngModel)]="formModel.fileType"
          />
          <label class="form-check-label" for="clientPrescription">Client Prescription</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="mb-3">
        <label class="my-0"><strong>Client</strong></label>
        <div class="input-group">
          <select class="form-select" [(ngModel)]="formModel.clientId" name="clientId">
            <option [value]="null" [selected]="true"> -- Choose Client -- </option>
            <option *ngFor="let client of clients" [value]="client.id"> {{ client.humanizedName }} </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <app-file-upload
        [fileType]="'.csv'"
        [uploadButtonLabel]="'Check File'"
        [uploadButtonDisabled]="uploadButtonDisabled"
        (fileSelected)="onFileSelected($event)"
        (uploadStart)="onUploadStart()"
        (uploadCancel)="onUploadCancel()"
        (uploadError)="onUploadError()"
        (uploadComplete)="onUploadComplete($event)"
      />
    </div>
  </div>
</form>
<div *ngIf="filePreview" class="mt-2">
  <h5>Preview</h5>
  <div></div>
  <div></div>
  <table class="table table-responsive">
    <thead>
      <tr>
        <th *ngFor="let column of filePreview.columns">{{ column }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of filePreview.rows">
        <td *ngFor="let column of filePreview.columns">{{ row[column] }}</td>
      </tr>
    </tbody>
  </table>
</div>
