<app-import-nav />

<h5 class="mt-3">Import Capture Invoices and Credits</h5>
<div class="row g-0" [hidden]="uploading">
  <div class="col-12">
    <div class="alert alert-danger" *ngIf="hasError">
      There was a problem uploading the file.
      <ul class="mt-2">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
    <div>
      <app-file-upload
        [fileType]="'.csv'"
        (uploadStart)="onUploadStart()"
        (uploadCancel)="onUploadCancel()"
        (uploadError)="onUploadError()"
        (uploadComplete)="onUploadComplete($event)"
      />
    </div>
  </div>
</div>
<div class="row g-0" [hidden]="!uploading">
  <div class="col-12">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
    Uploading File...
  </div>
</div>
<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 ps-2">
    <li class="py-2">
      Required fields
      <ul class="ps-2">
        <li><code>Updated Case Number</code></li>
        <li><code>Type</code> <span>(Dispensing/Reversal)</span></li>
        <li><code>par8o Invoice or Credit Month</code> <span>(date, format MM/DD/YYYY)</span></li>
        <li><code>Unique Record Id</code> <span>(unique ID for record for given case number and date, used for split billing)</span></li>
        <li><code>Billing Month/Year</code> <span>(month and year, format MM/YYYY)</span></li>
        <li><code>Estimated 340B Cost</code> <span>(decimal, e.g. 12.99, or -12.99, with no dollar sign)</span></li>
        <li><code>Increased Access Dollars</code> <span>(decimal)</span></li>
        <li><code>Net before par8o</code> <span>(decimal)</span></li>
        <li><code>par8o fees</code> <span>(decimal)</span></li>
        <li><code>Net after par8o</code> <span>(decimal)</span></li>
      </ul>
    </li>
    <li class="py-2">
      Example file
      <div>
        <small><strong>NOTE:</strong> Extra columns will be ignored and column headers are case insensitive.</small>
      </div>
      <div class="bg-light p-2 border w-100">
        <small>
          <strong>
            <code class="csv-header">
              Updated Case Number,Type,par8o Invoice or Credit Month,Unique Record Id,Estimated 340B Cost,Increased Access Dollars,Net before
              par8o,par8o fees,Net after par8o
            </code>
          </strong>
          <br />
          <code>9188-733870549,Dispensing,1,03/01/2020,500,5,100,10,90</code><br />
          <code>9188-733870549,Dispensing,2,03/01/2020,500,5,100,10,90</code><br />
          <code>9188-679990425,Dispensing,1,03/01/2020,1000,20,1000,10,1000</code><br />
          <code>9188-692367161,Reversal,1,03/01/2020,1000,-20.55,1000,10,1000</code><br />
        </small>
      </div>
    </li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
