<h6 class="card-title m-0 px-2 d-flex w-100">
  <div class="d-flex justify-content-between w-100 pt-1">
    <mat-icon>{{ icon }}</mat-icon>
    <span class="pt-1">{{ title }}</span>
    <span *ngIf="hintText" class="ms-auto text-end pt-1">{{ hintText }}</span>
  </div>
  <div class="toggler">
    <a href="#toggleCollapsed" class="toggle" (click)="onToggleCollapseClick($event)">
      <mat-icon *ngIf="collapsed">chevron_right</mat-icon>
      <mat-icon *ngIf="!collapsed">expand_more</mat-icon>
    </a>
  </div>
</h6>
