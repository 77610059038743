<div class="my-2">
  <span>
    <strong>Encounter Date {{ position + 1 }}</strong>
  </span>
  <div class="d-flex flex-row flex-wrap align-items-center specialist-encounter-verification">
    <div class="input-group w-auto encounter-date">
      <input
        name="encounterDate"
        class="form-control encounter-date-input"
        type="date"
        autocomplete="off"
        [(ngModel)]="encounterDate"
        [disabled]="disabled"
        (blur)="onEncounterDateBlur()"
        placeholder="MM/DD/YYYY"
      />
    </div>
    <div *ngIf="canRemove" class="actions ms-2">
      <a href="#/remove" class="text-danger ms-1 text-small" (click)="onRemoveClick($event)">Remove</a>
    </div>
    <div *ngIf="showEncounterDateCheck">
      <fa-icon [icon]="['fas', 'check']" size="1x" class="check ms-3" />
    </div>
    <div *ngIf="showEncounterDateWarning">
      <fa-icon [icon]="['fas', 'exclamation-triangle']" size="1x" class="warning ms-3" />
    </div>
  </div>
  <div *ngIf="showEncounterDateWarning" class="alert alert-warning py-0 px-1 m-0 d-inline-block">
    <small>Specialist encounter is outside <strong>{{ capture.client.specialistEncounterTimeframeInMonths }} month</strong> timeframe.</small>
  </div>
</div>
