<div class="card mb-2">
  <div class="card-body">
    <form [formGroup]="formGroup" (submit)="onSubmit()" #form="ngForm">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label>Provider First Name (Required)</label>
            <input
              class="form-control"
              formControlName="first"
              appFormControlValidityClass>
            <app-form-control-server-errors
              [form]="form"
              controlName="first" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label>Provider Last Name (Required)</label>
            <input
              class="form-control"
              formControlName="last"
              appFormControlValidityClass />
            <app-form-control-server-errors
              [form]="form"
              controlName="last" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label>Provider Credential</label>
            <ng-select
              bindValue="value"
              bindLabel="display"
              formControlName="credentials"
              [items]="providerCredentialOptions"
              appFormControlValidityClass />
            <app-form-control-server-errors
              [form]="form"
              controlName="credentials" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label>NPI (Required)</label>
            <input
              class="form-control"
              value="{{ provider.npi }}"
              disabled
              maxlength="10">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label>Provider Specialties</label>
            <ng-select
              bindValue="name"
              bindLabel="name"
              formControlName="specialties"
              [items]="providerSpecialties"
              [multiple]="true"
              appFormControlValidityClass />
            <app-form-control-server-errors
              [form]="form"
              controlName="specialties" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label>Provider Status (Required)</label>
            <ng-select
              bindValue="value"
              bindLabel="display"
              formControlName="status"
              [items]="providerStatusOptions"
              appFormControlValidityClass />
            <app-form-control-server-errors
              [form]="form"
              controlName="status" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label>Do Not Send Reason</label>
            <ng-select
              bindValue="value"
              bindLabel="display"
              formControlName="doNotSendReason"
              [items]="providerDoNotSendReasonOptions"
              appFormControlValidityClass />
            <app-form-control-server-errors
              [form]="form"
              controlName="doNotSendReason" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label>Do Not Send Reason Details</label>
            <input
              class="form-control"
              formControlName="additionalDetailsDns"
              appFormControlValidityClass />
            <app-form-control-server-errors
              [form]="form"
              controlName="additionalDetailsDns" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-end actions">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="form.submitted && formGroup.invalid || saving">
            Save
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            (click)="onCancelClick()"
            [disabled]="saving">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
