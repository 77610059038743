<div class="modal-header px-3 py-1">
  <h5 id="modal-basic-title" class="modal-title text-white">Edit Association Notes</h5>
</div>
<div class="modal-body">
  <div class="mb-2">
    <p>Edit the association notes for this provider office relationship</p>
    <div class="p-2 mb-3 border bg-light box">
      <div class="row">
        <div class="col">
          <strong>Provider</strong>
          <div>
            <a target="_blank" href="/capture-admin/providers/{{ provider.npi }}" *ngIf="provider.npi">
              {{ provider.displayName | blankValueDash }}
            </a>
          </div>
          <div>
            <span>NPI:</span> {{ provider.npi }}
          </div>
          <div>
            <span>NPI Review Status:</span> {{ keyedProviderStatusOptions[provider.status].display }}
          </div>

        </div>
        <div class="col">
          <strong>Office</strong>
          <app-office [office]="providerOffice.office" />
          <div>
            <span>Office Status:</span>
            {{ keyedOfficeStatusOptions[office.status].display | blankValueDash }}</div>
        </div>
        <div class="col">
          <strong>Provider Fax Status</strong>
          <div>{{ keyedProviderOfficeStatusOptions[providerOffice.status].display | blankValueDash }}</div>
        </div>
      </div>
    </div>
    <app-notes-for-model
      [model]="providerOffice"
      [modelType]="'ProviderOffice'"
      [minimizeControls]="true"
      (noteAdded)="onNoteAdded($event)"
    />
  </div>
  <div class="text-end"><button type="button" class="btn btn-secondary" (click)="onCloseClick()">Close</button></div>
</div>
