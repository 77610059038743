<div class="row">
  <div class="col-10">
    <h3>Offices</h3>

    <form novalidate>
      <div class="row flex-row">
        <div class="col">
          <div class="mb-3">
            <label for="name"><strong>Name</strong></label>
            <input
              id="name"
              class="form-control"
              name="name"
              placeholder="Filter By Name"
              [(ngModel)]="filters.name"
              (input)="onTextFilterChange()">
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="phone"><strong>Phone</strong></label>
            <input
              id="phone"
              class="form-control"
              name="phone"
              placeholder="Filter By Phone"
              [(ngModel)]="filters.phone"
              (input)="onTextFilterChange()">
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="fax"><strong>Fax</strong></label>
            <input
              id="fax"
              class="form-control"
              name="fax"
              placeholder="Filter By Fax"
              [(ngModel)]="filters.fax"
              (input)="onTextFilterChange()">
          </div>
        </div>
      </div>
      <div class="row flex-row mt-2">
        <div class="col">
          <div class="mb-3">
            <label for="city"><strong>City</strong></label>
            <input
              id="city"
              class="form-control"
              name="city"
              placeholder="Filter By City"
              [(ngModel)]="filters.city"
              (input)="onTextFilterChange()">
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="state"><strong>State</strong></label>
            <ng-select
              bindValue="value"
              bindLabel="display"
              [(ngModel)]="filters.state"
              [items]="stateOptions"
              placeholder="Filter By State"
              name="state"
              (change)="onFilterChange()" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="status"><strong>Status</strong></label>
            <ng-select
              bindValue="value"
              bindLabel="display"
              [(ngModel)]="filters.status"
              [items]="officeStatusOptions"
              placeholder="Filter By Status"
              name="status"
              [multiple]="true"
              (remove)="onFilterChange()"
              (change)="onFilterChange()" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-2 page-actions">
    <button type="submit" class="btn btn-primary" (click)="onAddOfficeClick($event)">Add Office</button>
  </div>
</div>

<div class="mt-2" *ngIf="!loading; else: spinner">
  <app-pagination-header
    [paging]="paging"
    [label]="'offices'"
    (pageSizeChange)="onPageSizeChange($event)"
    [includeResetFiltersButton]="true"
    (resetFiltersClick)="onResetFiltersClick()" />

  <div class="mt-2" *ngIf="offices.length > 0; else: noOffices">
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>ID</th>
          <th>NAME</th>
          <th>AKA</th>
          <th>PHONE</th>
          <th>FAX</th>
          <th>OFFICE STATUS</th>
          <th>ADDRESS</th>
          <th>CITY</th>
          <th>STATE</th>
          <th>PROVIDER COUNT</th>
          <th>LAST UPDATED</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let office of offices">
          <td>
            <a
              routerLink="/capture-admin/offices/{{office.id}}"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              {{ office.id }}
            </a>
          </td>
          <td>
            {{ office.name | blankValueDash }}
          </td>
          <td>
            {{ office.aka | blankValueDash }}
          </td>
          <td>
            <span class="text-nowrap">
              <app-phone-number [number]="office.phone" [allowCopyToClipboard]="true" />
            </span>
          </td>
          <td>
            <span class="text-nowrap">
              <app-phone-number [number]="office.fax" [allowCopyToClipboard]="true" />
              <a target="_blank" href="/capture-admin/offices/{{ office.id }}">
                <fa-icon [icon]="['fas', 'external-link-alt']" size="sm" />
              </a>
            </span>
          </td>
          <td>
            {{ keyedStatusOptions[office.status]?.display }}
          </td>
          <td>
            <div *ngIf="office.address1">{{ office.address1 }}</div>
            <div *ngIf="office.address2">{{ office.address2 }}</div>
            <app-blank-value-dash *ngIf="!office.address1 && !office.address2" />
          </td>
          <td>
            {{ office.city | blankValueDash }}
          </td>
          <td>
            {{ office.state | blankValueDash }}
          </td>
          <td>
            {{ office.providerCount }}
          </td>
          <td>
            {{ office.lastUpdatedAt | date: 'MM/dd/yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    </div>
  </div>
</div>

<ng-template #spinner>
  <div *ngIf="loading" class="ms-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noOffices><p class="ms-1">No offices.</p></ng-template>
