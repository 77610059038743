<ng-container *ngIf="prescriber; else: noPrescriber">
  <ng-container *ngIf="hasName; else: noName">
    <div>
      {{ prescriber.fullName }}
      <app-copy-to-clipboard [text]="prescriber.fullName" />
    </div>
    <div class="small">
      <strong>NPI:</strong>
      <span *ngIf="prescriber.providerId && canViewProvider">
        <a
          target="_blank"
          routerLink="/capture-admin/providers/{{prescriber.npi}}"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ prescriber.npi }}
        </a>
      </span>
      <span *ngIf="!prescriber.providerId || !canViewProvider">
        {{ prescriber.npi }}
      </span>
      <a target="_blank" href="/capture-admin/providers/{{ prescriber.npi }}" *ngIf="canViewProvider && linkToProvider && prescriber.providerId">
        <fa-icon [icon]="['fas', 'external-link-alt']" size="sm" />
      </a>
      <app-copy-to-clipboard [text]="prescriber.npi" />
      <div class="small" *ngIf="linkToProvider">
        <strong>NPI Review Status</strong>:
        <span *ngIf="prescriber && prescriber.providerStatus">
          {{ keyedStatusOptions[prescriber.providerStatus]?.display }}
        </span>
        <span *ngIf="!prescriber || !prescriber.providerStatus">
          <app-blank-value-dash />
        </span>
      </div>
    </div>
    <div class="small" *ngIf="hasSpecialtiesInfo">
      <p>{{ prescriber.displaySpecialty }}</p>
    </div>
  </ng-container>
</ng-container>
<ng-template #noName>
  <div>
    <span class="label">NPI:</span>
    {{ prescriber.npi }}
    <a target="_blank" href="/capture-admin/providers/{{ prescriber.npi }}" *ngIf="linkToProvider && prescriber.providerId">
      <fa-icon [icon]="['fas', 'external-link-alt']" size="sm" />
    </a>
    <app-copy-to-clipboard [text]="prescriber.npi" />
  </div>
</ng-template>
<ng-template #noPrescriber> <div><app-blank-value-dash /></div> </ng-template>
