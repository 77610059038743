<div class="modal-header px-3 py-1">
  <h5 id="modal-basic-title" class="modal-title text-white">Edit Referral Match Verifications</h5>
</div>
<div class="modal-body">
  <div class="mb-2">Create or update RMVs for this patient and prescriber</div>
  <form>
    <div class="mt-3">
      <h6>Patient Referrals From EHR Feeds</h6>
      <app-edit-referral-match-verifications-standard
        [patientAttachment]="patientAttachment"
        [patientPrescriber]="patientPrescriber"
        (verificationChange)="onVerificationChange($event)" />
    </div>

    <div class="mt-3">
      <h6>Patient Referrals From Client Tasks</h6>
      <app-edit-referral-match-verifications-client
        [patientAttachment]="patientAttachment"
        [patientPrescriber]="patientPrescriber"
        (verificationChange)="onVerificationChange($event)" />
    </div>

    <div class="mt-3">
      <h6>Referral Evidence Found in EHR</h6>
      <app-edit-referral-match-verifications-ehr
        [patientAttachment]="patientAttachment"
        [patientPrescriber]="patientPrescriber"
        (verificationChange)="onVerificationChange($event)" />
    </div>

    <div class="mt-3" *ngIf="showClientReferralDatesSection">
      <h6>Client Referral Dates</h6>
      <div class="bg-light border">
        <app-edit-referral-match-verifications-client-dates
          [patientAttachment]="patientAttachment"
          [patientPrescriber]="patientPrescriber" />
      </div>
    </div>

    <div class="mt-3 my-3">
      <app-additional-details-input
        id="notes"
        [frozen]="notesFrozen"
        [value]="patientPrescriber.referralMatchVerificationNotes"
        (valueChange)="onNotesChange($event)" />
    </div>
  </form>
  <div class="text-end"><button type="button" class="btn btn-secondary" (click)="onCloseClick()">Close</button></div>
</div>
