<ng-container *ngIf="patientReferralMatchVerifications.length > 0">
  <table class="table table-sm table-bordered mb-1">
    <thead class="table-dark">
      <tr>
        <th>Date Found</th>
        <th>Evidence Type</th>
        <th>Referral Date</th>
        <th>Prescriber</th>
        <th>Referral Specialty</th>
        <th>Found By</th>
        <th>Note To Client</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rmv of patientReferralMatchVerifications; let i = index">
        <td>
          <div class="d-flex">
            <div class="selection me-1">
              <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
            </div>
            <div>
              {{ rmv.ehrReferralVerification.createdAt | date: 'MM/dd/yyyy' }}
            </div>
          </div>
        </td>
        <td>
          {{ rmv.ehrReferralVerification.reasons | referralVerificationReasonShortNames | joinList }}
        </td>
        <td>
          {{ rmv.ehrReferralVerification.referralDate | date: 'MM/dd/yyyy' }}
        </td>
        <td>
          <div>{{ rmv.ehrReferralVerification.prescriber.fullName }}</div>
          <div>NPI: {{ rmv.ehrReferralVerification.prescriber.npi }}</div>
        </td>
        <td>
          {{ rmv.ehrReferralVerification.referralSpecialty | blankValueDash }}
        </td>
        <td>
          {{ rmv.ehrReferralVerification.user?.fullName | blankValueDash }}
        </td>
        <td>
          {{ rmv.ehrReferralVerification.notes | blankValueDash }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
