<div class="container-fluid p-2 border rounded bg-light">
  <form>
    <div class="row">
      <div class="col-2">
        <div>
          <label><strong>Status:</strong></label>
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="statuses"
            placeholder="Filter by Status"
            [multiple]="true"
            [items]="statusFilterOptions"
            (remove)="onStatusFilterChange()"
            (change)="onStatusFilterChange()"
            [(ngModel)]="filters.statuses"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <label><strong>Clients:</strong></label>
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="humanizedName"
            name="clientIds"
            placeholder="Filter by Client"
            [multiple]="true"
            [items]="clientFilterOptions"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.clientIds"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <label><strong>Assigned Client Lead:</strong></label>
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="username"
            name="assignedClientLeadIds"
            placeholder="Filter by Assigned Client Lead"
            [multiple]="true"
            [items]="assignedClientLeadsFilterOptions"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.assignedClientLeadIds"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <label><strong>Assigned User:</strong></label>
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="fullName"
            name="assignedToIds"
            placeholder="Filter by Assigned User"
            [multiple]="true"
            [items]="assignedToFilterOptions"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.assignedToIds"
          />
        </div>
      </div>
      <div class="col-2">
        <div>
          <label><strong>State:</strong></label>
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="clientStates"
            placeholder="Filter by Client State"
            [multiple]="true"
            [items]="stateOptions"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.clientStates"
          />
        </div>
      </div>
      <div class="col-2">
        <div>
          <label><strong>Sort By:</strong></label>
          <ng-select
            class="w-100"
            name="cursorType"
            bindValue="value"
            bindLabel="display"
            [multiple]="false"
            [items]="cursorTypeOptions"
            [clearable]="false"
            [searchable]="false"
            (change)="onCursorTypeChange()"
            [(ngModel)]="cursorType"
          />
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-8">
        <div class="d-flex flex-row align-items-center">
          <div class="px-1 col-auto">
            <strong>High Value:</strong>
            <mat-slide-toggle
              name="highValue"
              [color]="'primary'"
              [(ngModel)]="filters.highValue"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
          <div class="px-1 col-auto">
            <strong>Specialty:</strong>
            <mat-slide-toggle
              name="specialtyStore"
              [color]="'primary'"
              [(ngModel)]="filters.specialtyStore"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
          <div class="px-1 col-auto">
            <label><strong>Days To Expiration:</strong></label>
          </div>
          <div class="px-1 col-1">
            <input
              type="text"
              class="form-control form-control-sm"
              name="expiringWithinDays"
              appExcludeInputPattern
              excludedPattern="\D"
              maxlength="3"
              [(ngModel)]="filters.expiringWithinDays"
              (keyup)="onFilterChange()" />
          </div>
          <div class="px-1 col-auto">
            <label><strong>Fax Number:</strong></label>
          </div>
          <div class="px-1 col-2">
            <input
              type="text"
              class="form-control form-control-sm"
              name="outboundFaxNumber"
              [(ngModel)]="filters.outboundFaxNumber"
              (keyup)="onFilterChange()" />
          </div>
        </div>
      </div>
      <div class="col-4 text-end">
        <div>
          <span [hidden]="!updatingResultCount">
            <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
          </span>
          <span [hidden]="updatingResultCount"
            ><strong>{{ resultCount | number }}</strong> {{ resultCount === 1 ? 'consult note' : 'consult notes' }}
          </span>
          | <a href="#resetFilters" class="text-danger reset-filters" (click)="onResetClick($event)">Reset Filters</a>
        </div>
      </div>
    </div>
  </form>
</div>
