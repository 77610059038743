import { Component, Input, OnInit } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';
import { ClientPrescriberTasksService } from '../../../core/services/client-prescriber-tasks.service';
import { ClientPrescriberTaskCapture } from '../../../core/models/capture-lists/client-prescriber-task-capture.model';

@Component({
  selector: 'app-client-prescriber-task-captures',
  templateUrl: './client-prescriber-task-captures.component.html',
  styleUrls: ['./client-prescriber-task-captures.component.scss'],
})
export class ClientPrescriberTaskCapturesComponent implements OnInit {
  @Input() task: ClientPrescriberTask;

  loading = true;
  captures: ClientPrescriberTaskCapture[] = [];

  constructor(private clientPrescriberTasksService: ClientPrescriberTasksService) {}

  ngOnInit() {
    this.loadCaptures();
  }

  private loadCaptures() {
    this.clientPrescriberTasksService.getCaptures(this.task).subscribe((captures: ClientPrescriberTaskCapture[]) => {
      this.loading = false;
      this.captures = captures;
    });
  }
}
