<div class="col-auto m-1">
  <ng-select
    bindValue="value"
    bindLabel="display"
    name="statuses"
    [placeholder]="label"
    [multiple]="multiple"
    [disabled]="disabled"
    (change)="onChange()"
    [items]="captureStatuses"
    [(ngModel)]="selectedStatuses" />
</div>
