<h5>Current Locations</h5>

<p class="fst-italic">
  Are 340B locations missing? If yes, please reach out to your R1 contact to revisit your organization's data feeds.
</p>

<form class="filters">
  <div class="row">
    <div class="col-2">
      <label><strong>Eligibility Status:</strong></label>
    </div>

    <div class="col-2">
      <label><strong>Location:</strong></label>
    </div>
  </div>

  <div class="row">

    <div class="col-2">
      <div class="mb-3">
        <ng-select
          class="w-100"
          bindValue="value"
          bindLabel="display"
          name="eligibilityStatus"
          placeholder="Filter by Status"
          [multiple]="true"
          [items]="eligibilityStatusFilterOptions"
          (remove)="onFilterChange()"
          (change)="onFilterChange()"
          [(ngModel)]="filters.eligibilityStatuses" />
      </div>
    </div>

    <div class="col-2">
      <div class="mb-3">
        <input
          type="text"
          class="form-control"
          name="name"
          placeholder="Filter by Location"
          (keyup)="onLocationKeyUp()"
          [(ngModel)]="filters.name">
      </div>
    </div>

    <div class="col-2">
      <div class="mb-3">
        <button type="button" class="btn btn-info" (click)="onResetFiltersClick()">
          Clear
        </button>
      </div>
    </div>

  </div>
</form>

<app-pagination-header
  [label]="'locations'"
  [paging]="paging"
  (pageSizeChange)="onPageSizeChange($event)" />

<app-patient-interaction-location-list
  *ngIf="!loading; else: spinner"
  [includeClientColumn]="false"
  [patientInteractionLocations]="patientInteractionLocations" />

<app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />

<ng-template #spinner>
  <mat-spinner diameter="30" />
</ng-template>
