import parsePhoneNumberWithError_ from './parsePhoneNumberWithError_.js';
import normalizeArguments from './normalizeArguments.js';
export default function parsePhoneNumberWithError() {
  var _normalizeArguments = normalizeArguments(arguments),
      text = _normalizeArguments.text,
      options = _normalizeArguments.options,
      metadata = _normalizeArguments.metadata;

  return parsePhoneNumberWithError_(text, options, metadata);
}
