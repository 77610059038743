<ng-container *ngIf="!refreshing; else: spinner">
  <h6 class="card-title m-0 px-2 py-1 d-flex">
    <mat-icon>local_pharmacy</mat-icon>
    <div class="ms-1 pt-1">Prescriptions</div>
  </h6>
  <ng-container *ngIf="!refreshing && prescriptions.length > 0; else: noPrescriptions">
    <div class="m{{ eligible ? 'eligible' : 'inelibible' }} t-2 ms-2 mt-2">
      <app-pagination-header [paging]="paging" [label]="'prescriptions'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <app-prescription-list [prescriptions]="prescriptions" [small]="true" [dark]="true" [fixedHeader]="true" [forProvider]="true" />
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    </div>
  </ng-container>
  <ng-template #noPrescriptions> <div class="m-2">No prescriptions found.</div> </ng-template>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>

