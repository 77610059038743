import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, ControlContainer, FormArrayName } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map } from 'rxjs/operators';
import { ProviderFilters } from 'app/core/models/user-settings/provider-search-settings.model';
import { ProviderService, PagedProviders } from 'app/core/services/provider.service';
import { ProviderOfficeStatus } from 'app/core/enums/provider-office-status.enum';
import { Provider } from 'app/core/models/provider.model';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { filterProviderStatusOptions } from 'app/core/options/provider-office-status.opts';

@Component({
  selector: 'app-office-provider-new-row',
  templateUrl: './office-provider-new-row.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
    { provide: ControlContainer, useExisting: FormArrayName }
  ],
})
export class OfficeProviderNewRowComponent implements OnInit {
  @Input() formArrayIndex: number;
  @Input() rowForm: FormGroup;
  @Input() existingProviderOffices: ProviderOffice[];

  providerOfficeStatusOptions;

  providers$: Observable<Provider[]>;
  providersSearchInput$ = new Subject<string>();
  providersSearchMinNpiLength = 3;
  providersLoading = false;

  selectedProviderAlreadyAssociated = false;

  constructor(
    private providerService: ProviderService,
  ) { }

  ngOnInit() {
    this.providerOfficeStatusOptions = filterProviderStatusOptions(null);
    this.setupFormSubscriber();
    this.setupProvidersSource();
  }

  private setupFormSubscriber() {
    this.rowForm.controls.providerId.valueChanges.subscribe(value => {
      const statusControl = this.rowForm.controls.status;
      const noteControl = this.rowForm.controls.note;

      if (value) {
        statusControl.setValue(ProviderOfficeStatus.testing);
        statusControl.enable();
        noteControl.enable();
        this.selectedProviderAlreadyAssociated = this.isAlreadyAssociated(value);
      } else {
        statusControl.setValue(null);
        statusControl.disable();
        noteControl.disable();
        this.selectedProviderAlreadyAssociated = false;
      }
    });
  }

  private setupProvidersSource() {
    this.providers$ =
      this.providersSearchInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.providersLoading = true),
        debounceTime(500),
        switchMap(term =>
          this.providerSourceForTerm(term).
            pipe(
              catchError(() => of(new PagedProviders())),
              tap(() => this.providersLoading = false),
              map(result => result.records)
            ))
      );
  }

  private providerSourceForTerm(term: string): Observable<PagedProviders> {
    if (term && term.length >= this.providersSearchMinNpiLength) {
      const paging = { page: 1, pageSize: 20 };
      const filters = new ProviderFilters();
      filters.npi = term;

      return this.providerService.getList(paging, filters);
    } else {
      return of(new PagedProviders());
    }
  }

  private isAlreadyAssociated(value) {
    return this.existingProviderOffices.some(po => po.provider.id === value);
  }
}
