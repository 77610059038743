<table class="table table-sm border mb-0" *ngIf="prescriptions.length > 0; else: noPrescriptions">
  <thead>
    <tr>
      <th class="text-dark drug-col">DRUG (AKA)</th>
      <th class="text-dark text-end text-nowrap">WRITTEN DATE &#x25BC;</th>
      <th class="text-dark text-center">VALID REFERRAL?</th>
      <th class="text-dark text-center">SUBMITTED TO R1</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let prescriptionOption of prescriptionOptions">
      <td>
        <ng-container *ngIf="prescriptionOption.drugs && prescriptionOption.drugs.length > 0">
          <div *ngFor="let drug of prescriptionOption.drugs" class="mb-2">
            <div>{{ drug.name }}</div>
            <div class="small"><strong>NDC:</strong> {{ drug.ndc }} <span class="flagged-for-esp p-1" *ngIf="drug.flaggedForEsp">ESP</span></div>
            <div class="small">{{ drug.manufacturer }}</div>
          </div>
        </ng-container>
        <div class="small">
          {{ prescriptionOption.prescriptionFamily.akas && prescriptionOption.prescriptionFamily.akas | slice:0:4 | joinList }}
        </div>
      </td>
      <td class="text-end">{{ prescriptionOption.writtenDate | date: 'MM/dd/yyyy' }}</td>
      <td class="text-center">
        <ng-container *ngFor="let verification of prescriptionOption.verifications">
          <div *ngIf="verification.valid">
            <fa-icon [icon]="['fas', 'check']" class="check" />
            {{ verification.referralDate | date: 'MM/dd/yyyy' }}
          </div>
        </ng-container>
        <ng-container *ngIf="prescriptionOption.showWarning">
          <div class="referral-date-warning d-flex justify-content-around">
            <div><fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning" /></div>
            <div class="warning-message">
              <p>A documentation dated before the prescription written date is needed.</p>
              <a *ngIf="canAddVerifications && !formDisabled" href="#addReferral" (click)="onAddReferralClick($event)">
                Add Referral
              </a>
            </div>
          </div>
        </ng-container>
      </td>
      <td class="text-center">
        <app-client-task-submitted-prescription
          *ngIf="prescriptionOption.submitted"
          [verified]="prescriptionOption.verified"
          [rejected]="prescriptionOption.rejected"
          [submission]="prescriptionOption.submission"
        />
      </td>
    </tr>
  </tbody>
</table>
<ng-template #noPrescriptions>
  <section><p>There are no prescriptions.</p></section>
</ng-template>
