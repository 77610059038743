import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppToastService } from 'app/core/services/app-toast.service';
import { Capture } from '../../../core/models/capture.model';
import { CaptureService } from '../../../core/services/capture.service';
import { CaptureValidationService } from '../../../core/services/capture-validation.service';
import { CaptureStatus } from '../../../core/enums/capture-status.enum';
import { VerificationOutcome } from '../../../core/models/verification-outcome.model';
import { CaptureTransition } from '../../../core/enums/capture-transition.enum';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ServerError } from '../../../core/models/server-error.model';
import { PatientPrescriber } from '../../../core/models/patient-prescriber.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientPrescriberService } from 'app/core/services/patient-prescriber.service';
import { showCaptureTransitionedToast } from 'app/core/lib/verification-utils';

@Component({
  selector: 'app-capture-verification',
  templateUrl: './capture-verification.component.html',
  styleUrls: ['./capture-verification.component.scss'],
})
export class CaptureVerificationComponent implements OnInit, OnDestroy {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() selectedAttachment: PatientAttachment;

  processing = false;
  serverError = false;
  serverErrorResponse: ServerError;
  clientError = null;
  showClientPrescriber = false;

  constructor(
    private captureService: CaptureService,
    private authService: AuthenticationService,
    private captureValidationService: CaptureValidationService,
    private toastService: AppToastService,
    private router: Router,
    private patientPrescriberService: PatientPrescriberService,
  ) {}

  get showValidationSummary(): boolean {
    return this.captureValidationService.hasErrors;
  }

  get canEdit(): boolean {
    return this.authService.isCaptureAdminUser;
  }

  ngOnInit() {
    this.showClientPrescriber = this.capture.status === 'verified_client_prescriber_list';
  }

  ngOnDestroy() {
    return this.captureValidationService.clearErrors();
  }

  onTransitionChange() {
    return this.captureValidationService.clearErrors();
  }

  onProcessOutcome({
    transition,
    verificationOutcome,
  }: {
    transition: CaptureTransition;
    verificationOutcome: VerificationOutcome;
  }) {
    if (transition) {
      this.transition(transition, verificationOutcome);
    } else {
      if (this.capture.verificationOutcome.additionalDetails !== verificationOutcome.additionalDetails) {
        this.saveAdditionalDetails(verificationOutcome.additionalDetails);
      } else {
        this.clientError = "These details were previously saved to this capture (see above). Please enter new additional details.";
      }
    }
  }

  private transition(transition: CaptureTransition, verificationOutcome: VerificationOutcome) {
    if (
      this.captureValidationService.validate(
        transition,
        this.capture,
        this.patientPrescriber,
        this.selectedAttachment,
        verificationOutcome
      )
    ) {
      this.serverError = false;
      this.clientError = null;
      this.processing = true;

      this.captureService.transition(this.capture, transition, verificationOutcome).subscribe(
        capture => {
          this.processing = false;
          this.serverError = false;
          this.reloadPatientPrescriber();
          this.captureService.notifyCaptureChanged(capture);
          this.displayToast(capture);
          this.scrollToPatientPrescriptionsSection();
        },
        (err: HttpErrorResponse) => {
          this.processing = false;
          this.serverError = true;
          this.serverErrorResponse = err.error;
          console.log('this.serverErrorResponse:');
          console.log(this.serverErrorResponse);
          console.error(err);
        }
      );
    }
  }

  private saveAdditionalDetails(additionalDetails: string) {
    this.serverError = false;
    this.processing = true;
    this.clientError = null;

    this.captureService.saveAdditionalDetails(this.capture, additionalDetails).subscribe(
      capture => {
        this.processing = false;
        this.serverError = false;
        this.captureService.notifyCaptureChanged(capture);
      },
      (err: HttpErrorResponse) => {
        this.processing = false;
        this.serverError = true;
        this.serverErrorResponse = err.error;
        console.log('this.serverErrorResponse:');
        console.log(this.serverErrorResponse);
        console.error(err);
      }
    )
  }

  private displayToast(capture: Capture) {
    if (capture.status !== CaptureStatus.discarded) {
      showCaptureTransitionedToast(this.toastService, capture.status);
    } else {
      this.redirectDiscard(capture);
    }
  }

  private redirectDiscard(capture: Capture) {
    this.router.navigate(['/capture-admin']);
    this.toastService.show(`Capture has been discarded.`, {
      cssClass: 'bg-success-subtle',
    });
  }

  private scrollToPatientPrescriptionsSection() {
    const patientPrescriptions = document.querySelector('#patientPrescriptions');
    if (patientPrescriptions) {
      patientPrescriptions.scrollIntoView();
    }
  }

  private reloadPatientPrescriber() {
    this.patientPrescriberService.get(this.capture.patientPrescriberId).subscribe(patientPrescriber => {
      this.patientPrescriber = patientPrescriber;
    });
  }
}
