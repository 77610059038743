<app-import-nav />

<h5 class="mt-3">Import Drug Catalog</h5>

<div class="alert alert-danger" *ngIf="hasError">
  There was a problem uploading the file.
  <ul class="mt-2">
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
</div>

<app-icc-import-upload
  (uploadComplete)="onUploadComplete($event)"
  (uploadStart)="onUploadStart()"
/>

<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 ps-2">
    <li class="py-2">
      Possible fields
      <ul class="ps-2">
        <li><code>ndc</code> <span>(required, 11 digit)</span></li>
        <li><code>drug_description</code></li>
        <li><code>drug_class</code></li>
        <li><code>340b_price</code> <span>(currency, e.g. 12.99, or -12.99, with no dollar sign)</span></li>
        <li><code>retail_price</code> <span>(currency)</span></li>
        <li><code>package_quantity</code> <span>(decimal)</span></li>
      </ul>
    </li>
    <li class="py-2">
      Example file
      <div>
        <small><strong>NOTE:</strong> Extra columns will be ignored and column headers are case insensitive.</small>
      </div>
      <div class="bg-light p-2 border w-100">
        <small>
          <strong>
            <code class="csv-header">
              ndc,drug_description,drug_class,340b_price,retail_price,package_quantity
            </code>
          </strong>
          <br />
          <code>51645-703-01,Acetaminophen,Human Otc Drug,0.5,2,1000</code><br />
          <code>51645070305,,,15,200</code><br />
        </small>
      </div>
    </li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
