import { Component, Input, OnInit } from "@angular/core";
import { Capture } from "app/core/models/capture.model";
import { PatientPrescriber } from "app/core/models/patient-prescriber.model";
import { ReferralMatchVerification } from "app/core/models/referral-match-verification.model";
import { isClientDateReferralMatchVerification } from 'app/core/lib/verification-utils';

@Component({
  selector: 'app-referral-match-verifications-client-dates-edit',
  templateUrl: './referral-match-verifications-client-dates-edit.component.html',
})
export class ReferralMatchVerificationsClientDatesEditComponent implements OnInit {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;

  clientReferralMatchVerifications: ReferralMatchVerification[] = [];

  ngOnInit() {
    this.loadClientReferralMatchVerifications();
  }

  private loadClientReferralMatchVerifications() {
    this.clientReferralMatchVerifications =
      this.patientPrescriber.referralMatchVerifications.filter(isClientDateReferralMatchVerification);
  }
}
