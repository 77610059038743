<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col col-4">
        <strong>OFFICE</strong>
        <app-office [office]="office" [displayPhoneAndFax]="false" [allowCopyOfficeName]="true" />
      </div>
      <div class="col">
        <strong>OFFICE STATUS</strong>
        <div *ngIf="office.status">{{ keyedStatusOptions[office.status]?.display }}</div>
        <div *ngIf="!office.status">--</div>
      </div>
      <div class="col">
        <strong>PHONE</strong>
        <div>
          <app-phone-number [number]="office.phone" [dialpad]="true" />
        </div>
      </div>
      <div class="col">
        <strong>FAX</strong>
        <div>
          <app-office-fax [office]="office" [allowLinkToOffice]="false" />
        </div>
      </div>
      <div class="col">
        <strong>COMM METHOD</strong>
        <div *ngIf="office.communicationMethod">{{ office.communicationMethod }}</div>
        <div *ngIf="!office.communicationMethod">--</div>
      </div>
      <div class="col text-end">
        <a href="javascript:;" (click)="onEdit($event)"><fa-icon [icon]="['fas', 'pen']" /></a>
      </div>
    </div>
    <div class="row">
      <div class="col col-4">
        <strong>WEBSITE</strong>
        <div *ngIf="office.website"><a [href]="office.website" target="_blank">{{ office.website }}</a></div>
        <div *ngIf="!office.website">--</div>
      </div>
      <div class="col">
        <strong>EMPLOYED STATUS</strong>
        <div *ngIf="office.employedBy && office.employedBy.length > 0">{{ office.employedBy | joinList }}</div>
        <div *ngIf="!office.employedBy || office.employedBy.length === 0">--</div>
      </div>
    </div>
  </div>
</div>
