<form (ngSubmit)="onUploadSubmit()" class="form-inline" [ngClass]="{ disabled: disabled }">
  <div class="mb-3 w-100">
    <label *ngIf="!hideLabel"> <strong>File</strong> </label>
    <input
      id="fileInput"
      [accept]="fileType"
      type="file"
      class="form-control"
      (change)="onFileChange($event)"
      #fileInput
      [disabled]="disabled"
    />
  </div>
  <div [ngClass]="{'pt-4': !hideLabel}" class="mb-3 ms-sm-2" *ngIf="file">
    <button type="submit" [disabled]="disabled || uploadButtonDisabled" class="btn btn-primary">
      {{ uploadButtonLabel }}
    </button>
    <button type="reset" [disabled]="disabled" class="btn btn-secondary ms-sm-1" (click)="onCancelClick()">
      Cancel
    </button>
  </div>
</form>
