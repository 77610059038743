export enum ProviderStatus {
  new = 'new',
  verified = 'verified',
  reviewed = 'reviewed',
  dataCollection = 'data_collection',
  mechanicalTurkReview = 'mechanical_turk_review',
  providerNotValid = 'provider_not_valid',
  notAReferralSpecialty = 'not_a_referral_specialty',

  // deprecated
  // these can be removed after the remap status rake task is ran for providers
  // story:  https://www.pivotaltracker.com/n/projects/2192522/stories/187546413
  assigned = 'assigned',
  inProgress = 'in_progress',
  complete = 'complete',
  confirmed = 'confirmed',
  noInformationFound = 'no_information_found',
  deceasedOrRetired = 'deceased_or_retired',
}
