<div class="row mb-3">

  <div class="col-3">
    <div class="value-name">
      HIT Created
    </div>
    <div>
      {{ mostRecentTask?.createdAt | date: 'MM/dd/yyyy' | blankValueDash }}
    </div>
  </div>

  <div class="col-3">
    <div class="value-name">
      MT HIT ID
    </div>
    <div>
      {{ mostRecentTask?.externalIdentifier | blankValueDash }}
    </div>
  </div>

  <div class="col-3">
    <div class="value-name">
      CMS Specialties
    </div>
    <div>
      {{ informationCollectionDetails?.cmsSpecialties?.join(', ') | blankValueDash }}
    </div>
  </div>

</div>

<div class="row">

  <div class="col-3">
    <div class="value-name">
      HIT Completed
    </div>
    <div>
      {{ mostRecentAnswer?.createdAt | date: 'MM/dd/yyyy' | blankValueDash }}
    </div>
  </div>

  <div class="col-3">
    <div class="value-name">
      MT Worker ID
    </div>
    <div>
      {{ mostRecentAnswer?.workerIdentifier | blankValueDash }}
    </div>
  </div>

  <div class="col-3">
    <div class="value-name">
      HIT Source Websites
    </div>
    <div>
      <div *ngFor="let website of taskResultSourceWebsites">
        <span *ngIf="website.shouldLink">
          <a href="{{ website.url }}"
            target="_blank"
             (click)="confirmExternalLink(website.url)">
            {{ website.url }}
          </a>
        </span>
        <span *ngIf="!website.shouldLink">
          {{ website.url }}
        </span>
      </div>

      <app-blank-value-dash *ngIf="taskResultSourceWebsites.length === 0" />
    </div>
  </div>

  <div class="col-3">
    <div class="value-name">
      HIT Details
    </div>
    <div>
      {{ taskResultIssues | blankValueDash }}
    </div>
  </div>

</div>
