import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Provider } from "app/core/models/provider.model";
import { ProviderService } from 'app/core/services/provider.service';
import { UserTimingService } from 'app/core/services/user-timing.service';

@Component({
  selector: 'app-capture-admin-provider',
  templateUrl: './capture-admin-provider.component.html',
  styleUrls: ['./capture-admin-provider.component.scss'],
})
export class CaptureAdminProviderComponent implements OnInit, OnDestroy {
  provider: Provider;
  loading: boolean;
  enableDeactivateDate: boolean;
  showDeactivateDateIsRequiredMessage: boolean;
  deactivateDate: Date;

  constructor(
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private userTimingService: UserTimingService,
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.userTimingService.stop();
  }

  onProviderChange() {
    this.loadProvider();
  }

  private init() {
    this.startUserTimingService();
    this.loadProvider();
  }

  private startUserTimingService() {
    this.userTimingService.track({
      page: 'capture_admin_provider',
      recordId: this.npi,
    });
  }

  private get npi() {
    return this.route.snapshot.paramMap.get('npi');
  }

  private loadProvider() {
    this.loading = true;

    this.providerService.getByNpi(this.npi).subscribe(provider => {
      this.provider = provider;
      this.loading = false;
    });
  }
}
