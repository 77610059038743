<h3>Users</h3>
<div class="row">
  <div class="col-10">
    <app-user-filters [filters]="filters" (filtersChange)="onFiltersChange($event)" />
  </div>
  <div class="col-2 d-flex align-items-end justify-content-end">
    <div><a routerLink="/capture-admin/users/new" class="btn btn-primary">New User</a></div>
  </div>
</div>
<div class="mt-2">
  <app-pagination-header
    [paging]="paging"
    [label]="'users'"
    (pageSizeChange)="onPageSizeChange($event)"
  />
</div>
<div class="mt-2" *ngIf="!refreshing; else: spinner">
  <div *ngIf="users.length > 0; else: noUsers">
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th app-sortable-column="fullName" class="text-nowrap" [sort]="sort" (sortChange)="onSortChange($event)">
            NAME
          </th>
          <th>TYPE</th>
          <th>STATUS</th>
          <th
            app-sortable-column="lastSeenAt"
            class="text-end text-nowrap"
            [sort]="sort"
            (sortChange)="onSortChange($event)"
          >
            LAST SEEN
          </th>
          <th>EULA ACCEPTANCE</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users">
          <td>
            <strong
              ><a routerLink="/capture-admin/users/{{user.id}}">{{
                !!user.fullName ? user.fullName : user.username
              }}</a></strong
            >
            <div>
              <small>{{ user.email }}</small>
            </div>
          </td>
          <td><app-user-type [user]="user" /></td>
          <td><app-user-status [user]="user" /></td>
          <td class="text-end">
            {{ user.lastSeenAt | date: 'M/d/yyyy' }}
            <div>
              <small>{{ user.lastSeenAt | date: 'h:mm a' }}</small>
            </div>
          </td>
          <td class="text-end">
            {{ user.eulaDate | date: 'M/d/yyyy' }}
          </td>
          <td class="text-center">
            <app-user-actions (userChange)="onUserChange($event)" [user]="user" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    </div>
  </div>
</div>
<ng-template #spinner>
  <div *ngIf="refreshing" class="ms-1"><mat-spinner diameter="30" /></div>
</ng-template>
<ng-template #noUsers> <p class="ms-1">No users.</p></ng-template>
