<table class="table table-bordered table-hover">
  <thead class="table-dark">
    <tr>
      <th class="text-nowrap">Name</th>
      <th class="text-nowrap">Model</th>
      <th class="text-nowrap">Created At</th>
      <th class="text-nowrap">Created By</th>
      <th class="text-nowrap">Status</th>
      <th class="text-nowrap">Prompt Setup</th>
      <th class="text-nowrap" colspan="3">Results</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let batch of batches">
        <td class="text-nowrap">
          {{ batch.name }}
        </td>
        <td class="text-nowrap">
          {{ batch.modelName }}
        </td>
        <td>
          {{ batch.createdAt | date: 'M/d/yyyy h:mm a' }}
        </td>
        <td>
          {{ batch.createdByName }}
        </td>
        <td>
          {{ batch.status }}
        </td>
        <td>
          <a href="#" (click)="onViewBatchPrompt($event, batch.id)">View</a>
        </td>

      <ng-container [ngSwitch]="resultDisplay(batch.id)">
        <ng-container *ngSwitchCase="'loading'">
          <td colspan="3">
            <div class="spinner-container d-flex justify-content-center align-items-center">
              <mat-spinner class="inline-spinner" [diameter]="14" />
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
          <td colspan="3">
            <div class="text-danger">
              {{ loadingError[batch.id] }}
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'complete'">
          <td>
            <a routerLinkActive="active" [routerLink]="['../../batches', batch.id]">
              View
            </a>
          </td>
          <td>
            <a href="#" (click)="onDownloadBatchResults($event, batch.id)">
              Download
            </a>
          </td>
          <td>
            <a href="#" *ngIf="isOwner(batch.createdByEmail); else notOwner" (click)="onDeleteBatchRun($event, batch.id)">
              Delete
            </a>
            <ng-template #notOwner>
              <span class="text-muted">Delete</span>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>
    </tr>
  </tbody>
</table>
