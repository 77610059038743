import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Prescription } from '../../../core/models/prescription.model';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';
import { ClientReferralTaskChoice } from '../../../core/enums/client-referral-task-choice.enum';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { NavigationService } from '../../../core/services/navigation.service';
import { MeService } from '../../../core/services/me.service';
import { AppToastService } from 'app/core/services/app-toast.service';
import { ClientReferralTasksService } from '../../../core/services/client-referral-tasks.service';
import { ClientReferralTaskStatus } from '../../../core/enums/client-referral-task-status.enum';
import { ClientReferralTaskChoiceOption, choiceOptions } from 'app/core/options/client-referral-task-choice-opts';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'app/core/services/authentication.service';

const isConsultNoteCreationAllowed = (task: ClientReferralTask) => (
  task.allowConsultNoteTaskCreation &&
  task.status === ClientReferralTaskStatus.open &&
  task.choice === ClientReferralTaskChoice.yes
);

const isSubmitAllowed = (task: ClientReferralTask) => {
  if (task.status === ClientReferralTaskStatus.closed) {
    return false;
  }

  if (task.status === ClientReferralTaskStatus.sleeping) {
    return task.choice !== ClientReferralTaskChoice.notYet;
  }

  return task.status === ClientReferralTaskStatus.open;
};

const shouldHaveChoiceOtherReasonNote = (task) =>
  task.choiceReasons.includes(ReferralVerificationReason.other);

const shouldHaveChoiceSpecialityReferralSpeciality = (task) =>
  task.choiceReasons.includes(ReferralVerificationReason.specialityReferral);

const isSubmitEnabled = (task: ClientReferralTask, prescriptions: Prescription[]) => {
  if (task.choice === ClientReferralTaskChoice.yes) {
    return (
      task.choiceReasons &&
      task.choiceReasons.length > 0 &&
      (!shouldHaveChoiceOtherReasonNote(task) || task.choiceOtherReasonNote) &&
      (!shouldHaveChoiceSpecialityReferralSpeciality(task) || task.choiceSpecialityReferralSpeciality) &&
      prescriptions.some(p => p.anyClientReferralVerificationsValid && !p.submittedClientReferralVerification)
    );
  } else {
    return (
      task.choice === ClientReferralTaskChoice.no ||
      task.choice === ClientReferralTaskChoice.notYet ||
      task.choice === ClientReferralTaskChoice.prescriberIsCeProvider
    );
  }
};

const closeConsultNoteTaskAlert = () => {
  const alertDiv = document.querySelector('.client-consult-note-task-alert') as HTMLDivElement;

  if (alertDiv) {
    alertDiv.click();
  }
};

const TaskMessageTimeout = 2147483647;

@Component({
  selector: 'app-client-referral-task-actions',
  templateUrl: './client-referral-task-actions.component.html',
  styleUrls: ['./client-referral-task-actions.component.scss'],
})
export class ClientReferralTaskActionsComponent implements OnInit, OnChanges, OnDestroy {
  consultNoteTaskCreationAllowed = false;
  submitAllowed = false;
  submitEnabled = false;
  formModel = { notes: null, collectConsultNote: false };
  pageChangedSubscription: Subscription = null;
  choiceOptions: ClientReferralTaskChoiceOption[] = choiceOptions;
  nextButtonEnabled: boolean;
  serverError = false;
  serverErrorMsg = null;

  @Input() task: ClientReferralTask;
  @Input() prescriptions: Prescription[];
  @Input() formDisabled: boolean;
  @Output() taskChange = new EventEmitter<ClientReferralTask>();

  constructor(
    private navigationService: NavigationService,
    private clientReferralTasksService: ClientReferralTasksService,
    private authService: AuthenticationService,
    private meService: MeService,
    private toastService: AppToastService
  ) {
    this.nextButtonEnabled = !this.authService.isCaptureAdminUser;
  }

  ngOnInit() {
    this.formModel = { notes: null, collectConsultNote: false };

    this.init();

    this.pageChangedSubscription = this.navigationService.pageChanged.subscribe(() => {
      closeConsultNoteTaskAlert();
    });
  }

  ngOnChanges() {
    this.init();
  }

  ngOnDestroy() {
    if (this.pageChangedSubscription) {
      this.pageChangedSubscription.unsubscribe();
    }
  }

  onSubmitClick($event) {
    $event.preventDefault();
    const collectConsultNote = this.consultNoteTaskCreationAllowed && this.formModel.collectConsultNote;

    this.serverError = false;

    this.clientReferralTasksService.submit(this.task.id, collectConsultNote).subscribe(
      result => this.handleSuccessfulSubmission(result),
      error => this.handleFailedSubmission(error)
    );
  }

  private handleSuccessfulSubmission(result) {
    this.meService.notifyUserTodosChanged();
    this.taskChange.emit(result.clientReferralTask);

    if (result.clientConsultNoteTaskId) {
      const routePath = `/ce-portal/tasks/client-consult-note-tasks/${result.clientConsultNoteTaskId}`;

      // unfortunately the library we're using doesn't seem to support a null timeout ATM
      // so we instead pass the max delay for setTimeout which should do the job for now
      this.toastService.show(
        `Task was successfully submitted to R1. A Consult Note task has been added for this patient and prescriber.
        <div><a href="${routePath}">Click here to complete the consult note task now or complete it later in your task queue</a></div>`,
        {
          cssClass: 'bg-success-subtle client-consult-note-task-alert',
          showCloseButton: true,
          delay: TaskMessageTimeout,
        }
      );
    } else {
      this.toastService.show('Task was successfully submitted to R1', { cssClass: 'bg-success-subtle' });
    }

    window.scroll(0, 0);
  }

  private handleFailedSubmission(error) {
    this.serverError = true;
    this.serverErrorMsg = error.error['errors'];
  }

  onNextClick($event) {
    closeConsultNoteTaskAlert();

    $event.preventDefault();

    this.clientReferralTasksService.skipToNextTask(this.task).subscribe(({ item }) => {
      this.goToNextTask(item);
    });
  }

  private init() {
    this.consultNoteTaskCreationAllowed = isConsultNoteCreationAllowed(this.task);
    this.submitAllowed = !this.formDisabled && isSubmitAllowed(this.task);
    this.submitEnabled = isSubmitEnabled(this.task, this.prescriptions);
    this.formModel = { ...this.formModel, ...{ notes: this.task.notes } };
  }

  onNotesChange() {
    this.updateNotes(this.formModel.notes);
  }

  private updateNotes(notes: string) {
    const { id, choice, choiceReasons, rejectedPrescriptionIds } = this.task;

    this.clientReferralTasksService.updateNotes(id, notes).subscribe(result => {
      this.taskChange.emit(result);
    });
  }

  private goToNextTask(item) {
    const baseTaskPath = "/ce-portal/tasks/client-referral-tasks";

    if (item) {
      this.navigationService.navigateTo(`${baseTaskPath}/${item.id}`);
    } else {
      this.navigationService.navigateTo(baseTaskPath);
    }
  }

  public get additionalDetailsRequired() {
    return this.task.choice === ClientReferralTaskChoice.no;
  }
}
