import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';
import { NavigationService } from '../../../core/services/navigation.service';
import { ClientConsultNoteTasksService } from '../../../core/services/client-consult-note-tasks.service';
import { ClientConsultNoteTaskChoice } from '../../../core/enums/client-consult-note-task-choice.enum';
import { AppToastService } from 'app/core/services/app-toast.service';
import { Prescription } from '../../../core/models/prescription.model';
import { MeService } from '../../../core/services/me.service';
import { ClientConsultNoteTaskStatus } from '../../../core/enums/client-consult-note-task-status.enum';
import { AuthenticationService } from 'app/core/services/authentication.service';

const isSubmitAllowed = (task: ClientConsultNoteTask) => (
    task.status === ClientConsultNoteTaskStatus.open &&
    (task.choice === ClientConsultNoteTaskChoice.yes ||
      task.choice === ClientConsultNoteTaskChoice.no ||
      task.choice === ClientConsultNoteTaskChoice.assignBackToPar8o ||
      task.choice === ClientConsultNoteTaskChoice.prescriberIsCeProvider)
  );

const isSubmitEnabled = (task: ClientConsultNoteTask, prescriptions: Prescription[]) => {
  if (task.status === ClientConsultNoteTaskStatus.open) {
    if (task.choice === ClientConsultNoteTaskChoice.yes) {
      return prescriptions.some(p => p.nearestClientConsultNoteVerification && !p.clientVerifiedConsultNote);
    } else if (
      task.choice === ClientConsultNoteTaskChoice.no ||
      task.choice === ClientConsultNoteTaskChoice.assignBackToPar8o ||
      task.choice === ClientConsultNoteTaskChoice.prescriberIsCeProvider
    ) {
      return true;
    }
  }
  return false;
};

@Component({
  selector: 'app-client-consult-note-task-actions',
  templateUrl: './client-consult-note-task-actions.component.html',
  styleUrls: ['./client-consult-note-task-actions.component.scss'],
})
export class ClientConsultNoteTaskActionsComponent implements OnInit, OnChanges {
  submitAllowed = false;
  submitEnabled = false;
  formModel = { notes: null };
  nextButtonEnabled: boolean;
  serverError = false;
  serverErrorMsg = null;

  @Input() task: ClientConsultNoteTask;
  @Input() prescriptions: Prescription[];
  @Input() formDisabled : boolean;
  @Output() taskChange = new EventEmitter<ClientConsultNoteTask>();

  constructor(
    private navigationService: NavigationService,
    private clientConsultNoteTasksService: ClientConsultNoteTasksService,
    private authService: AuthenticationService,
    private meService: MeService,
    private toastService: AppToastService
  ) {
    this.nextButtonEnabled = !this.authService.isCaptureAdminUser;
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  onSubmitClick($event) {
    $event.preventDefault();
    this.serverError = false;
    this.clientConsultNoteTasksService.submit(this.task.id).subscribe(
      task => this.handleSuccessfulSubmission(task),
      error => this.handleFailedSubmission(error)
    );
  }

  private handleSuccessfulSubmission(task: ClientConsultNoteTask) {
    this.meService.notifyUserTodosChanged();
    this.taskChange.emit(task);
    this.toastService.show('Task was successfully submitted to R1', { cssClass: 'bg-success-subtle' });
  }

  private handleFailedSubmission(error) {
    this.serverError = true;
    this.serverErrorMsg = error.error['errors'];
  }

  onNextClick($event) {
    $event.preventDefault();

    this.clientConsultNoteTasksService.skipToNextTask(this.task).subscribe(({ item }) => {
      this.goToNextTask(item);
    });
  }

  private init() {
    this.submitAllowed = !this.formDisabled && isSubmitAllowed(this.task);
    this.submitEnabled = isSubmitEnabled(this.task, this.prescriptions);
    this.formModel = { notes: this.task.notes };
  }

  onNotesChange() {
    this.updateNotes(this.formModel.notes);
  }

  private updateNotes(notes: string) {
    this.clientConsultNoteTasksService.updateNotes(this.task.id, notes).subscribe(result => {
      this.taskChange.emit(result);
    });
  }

  private goToNextTask(item) {
    const baseTaskPath = "/ce-portal/tasks/client-consult-note-tasks";
    if (item) {
      this.navigationService.navigateTo(`${baseTaskPath}/${item.id}`);
    } else {
      this.navigationService.navigateTo(baseTaskPath);
    }
  }

  public get additionalDetailsRequired() {
    return this.task.choice === ClientConsultNoteTaskChoice.no;
  }
}
