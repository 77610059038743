<div class="d-flex flex-row flex-wrap align-items-center col-auto m-1">
  <div class="col-auto">
    <ng-select
      class="month-select"
      [(ngModel)]="month"
      (ngModelChange)="monthChange.emit(month)"
      placeholder="Month"
      [items]="availableMonths"
      [clearable]="false" />
  </div>
  <div class="col-auto mx-1">/</div>
  <div class="col-auto">
    <ng-select
      class="year-select"
      [(ngModel)]="year"
      (ngModelChange)="yearChange.emit(year)"
      placeholder="Year"
      [items]="availableYears"
      [clearable]="false" />
  </div>
</div>
