<h3 class="my-4 heading text-center">Please Verify It's You</h3>
<div class="row mb-5">
  <div class="col-lg-4 offset-lg-4">
    <p>A text message with a verification code was
      just sent to: {{ destination }}</p>
    <form (ngSubmit)="onSubmit(confirmMfaForm)" #confirmMfaForm="ngForm">
      <div class="mb-3">
        <label for="securityCode">Enter the code below:</label>
        <input
          id="securityCode"
          name="securityCode"
          class="form-control"
          type="text"
          [(ngModel)]="securityCode"
          required
          placeholder="Enter Security Code"
        />
        <div class="invalid-feedback">Security code is required.</div>
      </div>
      <button type="submit" class="btn btn-primary w-100">Submit</button>
      <p *ngIf="error" class="text-danger my-2">
        Sorry, the verification code you entered is not correct. Please try again.
      </p>
    </form>
  </div>
</div>
