import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { ProviderSpecialty } from 'app/core/models/provider-specialty.model';

@Injectable()
export class ProviderSpecialtyService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(): Observable<{ providerSpecialties: ProviderSpecialty[] }> {
    return this.http.get<{ providerSpecialties: ProviderSpecialty[] }>(
      `${environment.captureApi.url}/provider_specialties`
    );
  }
}
