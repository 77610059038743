<div *ngIf="!refreshing; else: spinner" id="consultNoteDetailPage" (resized)="onResized()">
  <div id="consultNoteTop" class="position-sticky top-0 z-1">
    <app-consult-note-title [patientAttachment]="patientAttachment" />
    <app-consult-note-header [patientAttachment]="patientAttachment" />
    <app-consult-note-nav
      [patientAttachment]="patientAttachment"
      [currentSection]="currentSection"
      [canAdmin]="canAdmin"
      (sectionClick)="onSectionClick($event)"
    />
  </div>
  <section id="validateConsultNote" class="consult-note-section">
    <div id="consultNoteDocumentWrapper" class="auto-resize-height">
      <app-consult-note-document
        [patientAttachment]="patientAttachment"
        (markedAsInvalidChanged)="onMarkedAsInvalidChanged($event)"
      />
    </div>
  </section>
  <section id="reviewPatientEncounters" class="mt-1 consult-note-section">
    <app-validate-patient-encounters
      [patient]="patientAttachment.patient" />
  </section>
  <section id="reviewProviderRMVs" class="mt-1 consult-note-section">
    <app-validate-referral-match-verifications
      [patientAttachment]="patientAttachment"
    />
  </section>
  <section id="submitConsultNote" class="mt-2 consult-note-section">
    <button class="btn btn-primary" [disabled]="!submitEnabled" (click)="onSubmitClick($event)">
      <fa-icon [icon]="['fas', 'cloud-upload-alt']" /> Submit
    </button>
    <button class="btn btn-secondary" (click)="onCancelClick($event)">
      <fa-icon [icon]="['fas', 'undo']" /> Cancel
    </button>
    <button class="btn btn-secondary" (click)="onNextClick($event)">
      Next <fa-icon [icon]="['fas', 'angle-double-right']" />
    </button>
  </section>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
<ng-template #consultNoteProcessing>
  <div class="d-flex align-items-center">
    <mat-spinner diameter="20" />
    <div class="toast-body">Processing... Consult note status will update shortly.</div>
  </div>
<ng-template #consultNoteSubmitResult>
    <div class="d-flex">
      <div class="toast-body flex-grow-1">
        <div class="text-center">
          <div *ngIf="verifiedCount > 0" class="mb-4">
            <p><strong>Nice job! You just verified {{ humanizedVerifiedCount }}!</strong></p>
          </div>

          <div *ngIf="rolledUpTransitionedCaptureStatusCounts.length > 0" class="mb-4">
            <p><strong>Additional transitions made:</strong></p>
            <div *ngFor="let entry of rolledUpTransitionedCaptureStatusCounts">
              {{ displayTransitionedCounts(entry) }}
            </div>
          </div>

          <div *ngIf="rolledUpFailedTransitionedCaptureStatusCounts.length > 0" class="mb-4">
            <p><strong>Captures not transitioned:</strong></p>
            <div *ngFor="let entry of rolledUpFailedTransitionedCaptureStatusCounts">
              {{ displayTransitionedCounts(entry) }}
            </div>
          </div>

          <div *ngIf="noCapturesVerifiedOrTransitioned">
            <p><strong>Consult note submitted and no captures were verified or transitioned.</strong></p>
          </div>
        </div>
      </div>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-footer text-center">
      <a class="btn btn-secondary text-decoration-none" routerLink="{{ prescriptionsLink }}">Prescriptions</a>
      <a class="btn btn-secondary text-decoration-none" routerLink="{{ consultNoteHistoryLink }}">Consult Note History</a>
      <a class="btn btn-secondary text-decoration-none" routerLink="{{ consultNoteQueueLink }}">Consult Note Queue</a>
    </div>
</ng-template>
