<div *ngIf="!loading; else: spinner">
  <table class="table m-0" *ngIf="captures.length > 0; else: noCaptures">
    <thead>
      <tr>
        <th>CLAIM ID</th>
        <th>STATUS</th>
        <th>RX DRUG</th>
        <th class="text-end text-nowrap">WRITTEN DATE</th>
        <th class="text-end text-nowrap">SERVICE DATE &#x25BC;</th>
        <th class="text-end text-nowrap">EST. VALUE</th>
        <th class="text-end text-nowrap">SOURCE</th>
        <th class="text-end text-nowrap">RX #</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let capture of captures">
        <td class="text-nowrap">
          <a routerLink="/capture-admin/captures/{{capture.id}}" target="_blank">{{
            capture.candidate.claimIdentifier
          }}</a>
        </td>
        <td class="text-nowrap">{{ capture.status | captureStatus }}</td>
        <td><app-candidate-drug [candidate]="capture.candidate" /></td>
        <td class="text-end">{{ capture.candidate.writtenDate | date: 'MM/dd/yyyy' }}</td>
        <td class="text-end">{{ capture.candidate.serviceDate | date: 'MM/dd/yyyy' }}</td>
        <td class="text-nowrap text-end">{{ capture.candidate.estimatedValueForDisplay }}</td>
        <td class="text-end "><app-candidate-source [candidate]="capture.candidate" /></td>
        <td class="text-end "><app-candidate-reference-number [candidate]="capture.candidate" /></td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #spinner>
  <div><mat-spinner diameter="30" /></div>
</ng-template>
<ng-template #noCaptures> <p>There are no captures for this task.</p></ng-template>
