<div class="row g-0">
  <div class="col-4">
    <h5 class="my-1">
      <div class="d-flex">
        <div><mat-icon class="material-icons-round">file_present</mat-icon></div>
        <div>{{ patientAttachment.fileName }}</div>
      </div>
    </h5>
  </div>
  <div class="col-8">
    <nav class="nav nav-pills float-end">
      <app-user-presence category="consultNote" [targetId]="patientAttachment.id" class="me-2" />
      <a
        class="nav-item nav-link"
        routerLink="/capture-admin/consult-notes/{{patientAttachment.id}}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="d-flex">
          <mat-icon class="material-icons-round">fact_check</mat-icon>
          <div class="nav-text">Consult Note Details</div>
        </div>
      </a>
      <a
        class="nav-item nav-link"
        routerLink="/capture-admin/consult-notes/{{patientAttachment.id}}/history"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="d-flex">
          <mat-icon class="material-icons-round">fact_check</mat-icon>
          <div class="nav-text">Consult Note History</div>
        </div>
      </a>
      <a
        class="nav-item nav-link"
        routerLink="/capture-admin/consult-notes/{{patientAttachment.id}}/prescriptions"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="d-flex">
          <mat-icon class="material-icons-round">local_pharmacy</mat-icon>
          <div class="nav-text">Prescriptions</div>
        </div>
      </a>
      <a
        class="nav-item nav-link"
        routerLink="/capture-admin/consult-notes/{{patientAttachment.id}}/others/{{ patientAttachment.patient.id }}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="d-flex">
          <mat-icon class="material-icons-round">folder_shared</mat-icon>
          <div class="nav-text">Other Patient Documents</div>
        </div>
      </a>
    </nav>
  </div>
</div>

